import * as types from "../actionType";

const initialState = [];

const userAddressList = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_GET_ADDRESS_LIST:
      return action.payload;
    default:
      return state;
  }
};

export { userAddressList };

import * as types from '../actionType'

const initialState = []

const sellerSubcategoryById = (state = initialState, action) => {
   
    switch (action.type) {
        case types.SUB_CATEGORY_LIST:
            return action.payload
        default:
            return state
    }
}


export { sellerSubcategoryById }
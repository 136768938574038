import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    reason: yup
        .string()
        .required("Please enter reason")
        .min(2, "Reason must be at least 2 characters")
        .max(20, "Reason must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid reason"),
	comment: yup
        .string()
        .required("Please enter comment")
        .min(2, "Comment must be at least 2 characters")
        .max(200, "Comment must be at most 200 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid comment"),
});

const CancelOrder = () => {
	const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

	return <div className = 'modal-backdrop' >
		<div className="cancel-order">
			<h3>Cancel Order</h3>
			<form>
				<div className="input-wrapper">
					<label className="input-label">Reason to cancel</label>
					<div className={`input-wrap ${errors.reason ? 'has-error' : ''}`}>
						<input
							type="text"
							className="form-control"
							{...register("reason", { required: true })}
						/>
					</div>
					{errors.reason && (
						<span className="error">
							Please enter reason
						</span>
					)}
				</div>
				<div className="input-wrapper">
					<label className="input-label">Comment</label>
					<div className={`input-wrap ${errors.comment ? 'has-error' : ''}`}>
						<textarea
							placeholder='Enter comment'
							type="text"
							className="form-control"
							{...register("comment", { required: true })}
						/>
					</div>
					{errors.comment && (
						<span className="error">
							Please enter comment
						</span>
					)}
				</div>
				<div className="input-wrapper">
					<div className="input-wrapper img-input-fix has-input-file">
						<label className="">Attachment</label>
						<div className="input-wrapper">
							<div className="img-input-wrapper">
								<div className="img-input">
									<i className="icon-plus"></i>
									Upload
									<input
										type="file"
										accept="image/*,video/*"
									/>
								</div>
							</div>
						</div>
					</div>
					{!true && <div className='img-thumbnail'>
						<div className="img-wrap">
							<ImageThumb image={''} type='file' />
						</div>
						<div className="remove-btn">
							<button type='button'>
								<i className="icon-remove"></i>
							</button>
						</div>
					</div>}
				</div>
				<div className="wrap-btn">
					<button type="submit" className='btn'>Submit</button>
				</div>
			</form>
		</div>
	</div>
};

const ImageThumb = () => {
	return <div className='thumbnail-wrap'>
		{
			<img src='' alt={`img`} />
		}
	</div>
};

export default CancelOrder;
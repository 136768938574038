import * as types from '../actionType'

const initialState = []

export const userAllMessage = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_TOTAL_MESSAGE:
            return action.payload
        default:
            return state
    }
}

export const sellerAllMessage = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_TOTAL_MESSAGE:
            return action.payload
        default:
            return state
    }
}

export const userAllName = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_MESSAGE_NAME_LIST:
            return action.payload
        default:
            return state
    }
}

export const sellerAllName = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_MESSAGE_NAME_LIST:
            return action.payload
        default:
            return state
    }
}
import axios from 'axios';



let url = process.env.REACT_APP_URL
// if (window.location.hostname !== "css7941-prod.thesst.com" && window.location.hostname !== "css7941.thesst.com" && window.location.hostname !== "localhost") {
//     url = process.env.REACT_APP_PROD_URL;
// }

if (window.location.hostname !== "sis1166.devsparxit.com" && window.location.hostname !== "localhost") {
    url = process.env.REACT_APP_PROD_URL; 
}
if(window.location.hostname === "seller.aladyyn.com"){
    url = process.env.REACT_APP_LIVE_URL;
}

export const axiosInstance = axios.create({
    baseURL: url + process.env.REACT_APP_API_PREFIX,
    headers: {
        Authorization: process.env.REACT_APP_API_TOKEN
    }
});

export const axiosInstanceWithUserToken = axios.create({
    baseURL: url + process.env.REACT_APP_API_PREFIX,
});

import React, { useRef } from 'react';
import { createPortal } from "react-dom";
import OutsideClickHandler from 'components/OutsideClickHandler';

const modalRoot = document.getElementById("modal-root");

const ConfirmBox = ({ confirmMsg, onConfirm, onHidePopup }) => {
	const modalRef = useRef();

	OutsideClickHandler(modalRef, () => {
		onHidePopup(false);
	});

	const confirmClickHandler = () => {
		onConfirm();
		onHidePopup(false);
	}
	
	const cancelClickHandler = () => {
		onHidePopup(false);
	}
	return (<>
		{
			createPortal(
				<div className="modal-backdrop" style={{alignItems: 'center'}}>
					<div className='confirm-box card' ref={modalRef}>
						<h3>{confirmMsg}</h3>
						<div className="btn-wrap">
							<button type='button' className='btn' onClick={confirmClickHandler}>Confirm</button>
							<button type='button' className='btn' onClick={cancelClickHandler}>Cancel</button>
						</div>
					</div>
				</div>,
				modalRoot
			)
		}
	</>
	)
}

export default ConfirmBox
import * as actionTypes from 'store/actionType';

const initialState= []

const cityBankDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CITY_BANK_DATA_2:
            return action.payload
        default:
            return state
    }
}

export {cityBankDataReducer};
import * as types from '../actionType'

const initialState = []

function setWishList(state, payload) {
    let newState = [...state];
    newState = newState.map(item => {
        if (item._id === payload.data.serviceId) {
            return { ...item, wishlist: payload.type }
        } else {
            return { ...item }
        }
    });
    return newState;
}

const categoryData = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CATEGORY:
            return action.payload
        default:
            return state
    }
}

const subCategoryData = (state = [], action) => {
    switch (action.type) {
        case types.SET_SUB_CATEGORY:
            return action.payload
        default:
            return state
    }
}

const finalCategoryData = (state = [], action) => {
    switch (action.type) {
        case types.CATEGORY_NEW_LIST:
            return action.payload
        default:
            return state
    }
}

const homeSubCat = (state = [], action) => {
    switch (action.type) {
        case types.HOME_SUBS:
            return action.payload
        default:
            return state
    }
}

const homeService = (state = [], action) => {
    switch (action.type) {
        case types.SERVICE_LIST:
            return action.payload
        case types.SET_WISHLIST:
            return setWishList(state, action.payload)
        default:
            return state
    }
}

export { categoryData, subCategoryData, finalCategoryData, homeSubCat, homeService };
import * as types from '../actionType'

const initialState = {}

const getOtherServiceList = (state, payload) => {
    let newState = { ...state };
    return { ...newState, otherServiceList: payload }
}

const getRecommendedList = (state, payload) => {
    let newState = { ...state };
    return { ...newState, recommendedList: payload }
}

const setReviewList = (state, payload) => {
    let newState = { ...state };
    return { ...newState, reviewList: payload }
}

const addReviewList = (state, payload) => {
    let newState = [...state.reviewList];
    newState.push(payload)
    return { ...state, reviewList: newState }
}

const wishlistServiceDetail = (state, payload) => {
    let newState = { ...state.data };
    newState.wishlist = payload
    return { ...state, data: newState }
}

const wishlistOtherServices = (state, payload) => {
    let newState = [...state.otherServiceList];
    newState = newState.map(item => {
        if (item._id === payload.data.serviceId) {
            return { ...item, wishlist: payload.type }
        } else {
            return item
        }
    })
    return { ...state, otherServiceList: newState }
}


const wishlistRecommendedService = (state, payload) => {
    let newState = [...state.recommendedList];
    newState = newState.map(item => {
        if (item._id === payload.data.serviceId) {
            return { ...item, wishlist: payload.type }
        } else {
            return item
        }
    })
    return { ...state, recommendedList: newState }
}


const setAddToCart = (state, payload) => {
    let newState = { ...state };
    return { ...newState, addtocart: payload }
}

const sellerServiceDetail = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SERVICE_DETAILS:
            return action.payload;
        case types.GET_OTHER_SERVICE_LIST:
            return getOtherServiceList(state, action.payload)
        case types.GET_RECOMMENDED_LIST:
            return getRecommendedList(state, action.payload)
        case types.GET_REVIEW_LIST:
            return setReviewList(state, action.payload)
        case types.ADD_REVIEW_LIST:
            return addReviewList(state, action.payload)
        case types.ADD_WISHLIST_SERVICE_DETAIL:
            return wishlistServiceDetail(state, action.payload)
        case types.WISHLIST_OTHER_SERVICE:
            return wishlistOtherServices(state, action.payload)
        case "RECOMMENDED":
            return wishlistRecommendedService(state, action.payload)
        case types.ADD_TO_CART:
            return setAddToCart(state, action.payload)
        case types.CLEAN_ADD_TO_CART:
            return { ...state, addtocart: {} }
        default:
            return state
    }
}

export { sellerServiceDetail };
import * as types from "../actionType";

const initialState = {
  islogin: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return action.payload;
    // case types.ADVERTISER_LOGIN:
    //   return action.payload;
    case types.PROFILE_VIEW:
      return { islogin: true };
    case types.ADVERTISER_PROFILE_VIEW:
      return { islogin: true };
    case types.USER_PROFILE:
      return { islogin: true };
    case types.EDIT_PROFILE:
      return { islogin: true };
    default:
      return state;
  }
};

export default login;

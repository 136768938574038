import * as actionType from "store/actionType";
const initialState = {};

const sellerReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SELLER_REVIEWS:
      return { sellerReviews: action.payload };
    default:
      return state;
  }
};

export default sellerReviewReducer;

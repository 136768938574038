import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import advertiserReg from 'store/action/advertiserSignUpAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
	firstName: yup
		.string()
		.required("Please enter First Name")
		.min(2, "First name must be at least 2 characters")
		.max(20, "First name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
	lastName: yup
		.string()
		.required("Please enter Last Name")
		.min(2, "Last name must be at least 2 characters")
		.max(20, "Last name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
	phone: yup
		.string()
		.required("Please enter mobile number")
		// .matches(/^[0-9]*$/, "Phone number is not valid")
		.min(7)
		.max(16),
	email: yup
		.string()
		.required("Please enter your email address")
		.matches(
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			"Please use the correct email"
		),
	password: yup
		.string()
		.required("Please enter the password")
		.min(8, "Password must be 8 characters long"),
	re_password: yup
		.string()
		.required("Please enter the password")
		.min(8, "Password must be 8 characters long")
		.oneOf([yup.ref('password'), null], 'Confirm Password must be same as New Password'),
});

const SignIn = () => {
	const dispatch = useDispatch()
	const [isShowPassword, setIsShowPassword] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [con_passwordType, setCon_passwordType] = useState("password");
	const { register, formState: { errors }, handleSubmit, reset } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	const togglePasswordHandler = () => {
		setIsShowPassword(!isShowPassword);
	}

	const inputType = isShowPassword ?
		'text' : 'password';
	const togglePasswordBtnClasses = isShowPassword ? 'toggle-btn show' : 'toggle-btn hide';


	const onSubmit = (data, e) => {
		data.type = "Advertiser"
		advertiserReg(data)(dispatch)
	};

	// useEffect(() => {
	// 	advertiserReg
	// },)

	return (
		<section className='create-account'>
			<ToastContainer
				className={'modal-backdrop'}
				position="top-center"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>
			<div className="container">
				<div className="card">
					<h2>Create Account</h2>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row inputs-wrapper">
							<div className="col-md-6 pr-fix input-block">
								<div className="input-wrapper required">
									<label>First Name</label>
									<div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("firstName")}
											className='form-control' />
									</div>
									{errors.firstName && (
										<span className="error">
											{errors.firstName.message}
										</span>
									)}
								</div>
							</div>
							<div className="col-md-6 pl-fix input-block">
								<div className="input-wrapper required">
									<label>Last Name</label>
									<div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("lastName")}
											className='form-control' />
									</div>
									{errors.lastName && (
										<span className="error">
											{errors.lastName.message}
										</span>
									)}
								</div>
							</div>
						</div>
						<div className="row inputs-wrapper">
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="user-id">Mobile Number</label>
									<div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
										<input
											type="number"
											{...register("phone")}
											className='form-control' />
									</div>
									{errors.phone && (
										<span className="error">
											{errors.phone.message}
										</span>
									)}
								</div>
							</div>
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="user-id">Email Address</label>
									<div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("email")}
											className='form-control' />
									</div>
									{errors.email && (
										<span className="error">
											{errors.email.message}
										</span>
									)}
								</div>
							</div>
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="password">Password</label>
									<div className={`input-wrap ${errors.password ? 'has-error' : ''}`}>
										<input type={passwordType}
											className='form-control'
											{...register("password")} />
										<div className="toggle-password">
											<i onClick={() => { setPasswordType(passwordType === "password" ? "text" : "password") }} className={`icon-eye ${passwordType === "password" ? "" : "open"}`}></i>
										</div>
									</div>
									{errors.password && (
										<span className="error">
											{errors.password.message}
										</span>
									)}
								</div>
							</div>
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="password">Confirm Password</label>
									<div className={`input-wrap ${errors.re_password ? 'has-error' : ''}`}>
										<input type={con_passwordType}
											className='form-control'
											{...register("re_password")} />
										<div className="toggle-password">
											<i onClick={() => { setCon_passwordType(con_passwordType === "password" ? "text" : "password") }} className={`icon-eye ${con_passwordType === "password" ? "" : "open"}`}></i>
										</div>
									</div>
									{errors.re_password && (
										<span className="error">
											{errors.re_password.message}
										</span>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 mb-2 mb-md-0">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >By signing in you agree to the <Link to='/'>Terms and Conditions</Link> of Aladyyn</label>
								</div>
							</div>
						</div>
						<div className="btn-wrap">
							<button type='submit' className='btn w-100'>Create Account</button>
						</div>
					</form>
				</div>
				<div className="card text-center">
					<div className="signin-wrap">
						Already have an account?
						<Link to='/sign-in' className='signin-link'>Sign In</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SignIn

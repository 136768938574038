import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import Time from "./Time";
import {
  addNewAddress,
  getAddressDetail,
  editAddress,
} from "store/action/addAddressAction";
import { categoryFinalList } from "store/action/categoryListAction";
import { finalCategoryData } from "store/reducer/category.reducer";
import MapComponent from "pages/User/Category/Map";
import OutsideClickHandler from "components/OutsideClickHandler";
import { REACT_APP_GOOGLE_MAPS_KEY } from "./constants/constants";

const animatedComponents = makeAnimated();

const schema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(60, "Address must be at most 60 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup.string(),
  cityId: yup.string().required("Please enter city"),
  stateId: yup.string().required("Please enter State"),
  postcode: yup
    .string()
    .required("Please enter Zip")
    .matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid")
    .min(3),
});

let dayOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AddCategory = (props) => {
  const searchBoxRef = useRef();

  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const finalCategory = useSelector((state) => state.finalCategoryData);
  const getCity = useSelector((state) => state.cityData);
  const [countryname, setCountryname] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(true); // Initially disable the button
  const [locationError, setLocationError] = useState(false);
  const dispatch = useDispatch();

  const [selectedTimes, setSelectedTimes] = useState({});
  const [editTimeAddress, setEditTimeAddress] = useState([]);
  const [isFormValid, setIsFormValid] = useState(true);
  const [toTimeGivenError, setToTimeGivenError] = useState();
  const [fromTimeGivenError, setFromTimeGivenError] = useState({});
  const [fromToErrMessage, setFromToErrMessage] = useState();
  const [oneDayTimeSelect, setOneDayTimeSlect] = useState();

  // New state for "All days"
  const [allDaysTime, setAllDaysTime] = useState({ from: "", to: "" });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const [state, setState] = useState([
    {
      day: "",
      from: "",
      to: "",
      isError: false,
      errorMsg: "",
    },
    {
      day: "",
      from: "",
      to: "",
      isError: false,
      errorMsg: "",
    },
  ]);

  function disabledMinutes() {
    return [];
  }

  function disabledHours() {
    return [];
  }

  const format = "h:mm a";

  function handleChangeFrom(evt, i) {
    let fromTime = document.getElementById(`from_0`);
    let fromError = document.getElementById(`from_error0`);
    if (fromTime.value === "") {
      fromTime.focus();
      fromError.style.display = "block";
    } else {
      fromError.style.display = "none";
    }
    let timeval = evt;
    let newState = [...state];
    newState[i].from = timeval;
    setState(newState);
  }

  function handleChangeTo(evt, i) {
    let toTime = document.getElementById(`to_0`);
    let toError = document.getElementById(`to_error0`);
    if (toTime.value === "") {
      toTime.focus();
      toError.style.display = "block";
    } else {
      toError.style.display = "none";
    }
    let timeval = evt;
    let newState = [...state];
    newState[i].to = timeval;
    setState(newState);
  }

  const addMoreDay = (e) => {
    e.preventDefault();
    let newState = [...state];
    newState.push({ day: "", from: "", to: "", isError: false, errorMsg: "" });
    setState(newState);
  };

  const now = moment().hour(14).minute(30);

  useEffect(() => {
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
  }, []);

  console.log("seleeeeect",props.selectedLocation)

  useEffect(() => {
    categoryFinalList((callback) => {})(dispatch);
    countryList()(dispatch);

    if (props.addressId) {
      getAddressDetail({ addressId: props.addressId }, (callback) => {
        stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
        cityList({
          countryId: "61c2fb83dc7c0d455ba5e68d",
          stateId: callback.stateId,
        })(dispatch);

        setTimeout(() => {
          setValue("addressLine1", callback?.addressLine1);
          setValue("addressLine2", callback.addressLine2);
          setValue("countryId", "61c2fb83dc7c0d455ba5e68d");
          setValue("stateId", callback.stateId);
          setValue("cityId", callback.cityId);
          setValue("postcode", callback.postcode);
          setValue("locationAddress", callback?.locationAddress);
          setValue("googleLOcation", callback?.googleLocation);

          props?.setSelectedLocation({
            lat: callback?.coordinates?.coordinates?.[1],
            lng: callback?.coordinates?.coordinates?.[0],
          });

            // if (typeof props.setSelectedLocation === "function") {
            //   props.setSelectedLocation({
            //     lat: callback?.coordinates?.coordinates?.[1],
            //     lng: callback?.coordinates?.coordinates?.[0],
            //   });
            // } else {
            //   console.error("setSelectedLocation is not a function");
            // }

          props.setSearchLocation(callback?.googleLocation);

          if (callback.allDays && callback.allDays.length > 0) {
            setAllDaysTime({
              from: moment(callback.allDays[0].from, "HH:mm"),
              to: moment(callback.allDays[0].to, "HH:mm"),
            });
          } else {
            let temp = {};
            callback.daysOpened.forEach((item) => {
              temp[item.day] = {
                from: item.from ? moment(item.from, "hh:mm a") : "",
                to: item.to ? moment(item.to, "hh:mm a") : "",
              };
            });
            setSelectedTimes(temp);
            setState(temp);
          }
          setIsBtnDisable(false);
        }, 1000);
      })(dispatch);
    }
  }, []);

  const getStateHandler = (e) => {
    let val = e.target.value;
    if (val === "") {
      setError("countryId");
    } else {
      clearErrors("countryId");
    }
    setCountryname(val);
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" })(dispatch);
  };

  const getCityHandler = (e) => {
    if (e.target.value === "") {
      setError("stateId");
    } else {
      clearErrors("stateId");
    }
    cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
  };

  const closeModalHandler = () => {
    props.onCloseModal(false);
  };

  const handlerChangeMulti = (e, i) => {
    let isDisable = false;
    let newState = [...state];
    newState = newState.map((item) => ({
      ...item,
      isError: false,
      errorMsg: "",
    }));
    newState.forEach((item, index) => {
      if (item.day !== "" && item.day === e.target.value) {
        isDisable = true;
        newState[i].isError = true;
        newState[i].errorMsg = "You can't select same day multi time.";
      }
    });
    newState[i].day = e.target.value;
    setState(newState);
    setIsBtnDisable(isDisable);
  };

  const removeField = (index) => {
    let newState = [...state];
    newState = newState.filter((item, i) => {
      if (i !== index) {
        return item;
      }
    });

    newState = newState.map((item, i) => {
      return { ...item, isDisable: false, isError: false };
    });

    setState(newState);
  };

  const onSubmit = (data) => {
    if (!props.searchLocation) {
      setLocationError(true);
      return;
    }
    setLocationError(false);

    const allDaysFrom = allDaysTime.from;
    const allDaysTo = allDaysTime.to;

    // Check if both "All days" times are set
    if (allDaysFrom && allDaysTo) {
      data.daysOpened = [];
      data.allDays = [
        {
          from: allDaysFrom.format("HH:mm"),
          to: allDaysTo.format("HH:mm"),
        },
      ];
    } else {
      data.allDays = [];

      const dataArray = Object.keys(selectedTimes).map((day) => {
        return {
          day: day,
          from: selectedTimes[day].from,
          to: selectedTimes[day].to,
        };
      });

      let daysOpenedData = [];

      if (dataArray.length > 0) {
        for (let day in dataArray) {
          let fromTime = dataArray[day].from?.format("HH:mm");
          let toTime = dataArray[day].to?.format("HH:mm");

          daysOpenedData.push({
            day: dataArray[day]?.day,
            from: fromTime,
            to: toTime,
          });
        }
      }

      data.daysOpened = daysOpenedData;
    }

    data.sellerInformationId = props.sellerInformationId;
    data.lat = props?.selectedLocation?.lat;
    data.long = props?.selectedLocation?.lng;
    data.locationAddress = props.searchLocation;
    data.googleLocation = props.searchLocation;

    if (props.addressId) {
      data.addressId = props.addressId;
      editAddress(data)(dispatch);
    } else {
      let hasError = false;
      if (data?.daysOpened?.length === 0 && !allDaysFrom && !allDaysTo) {
        setOneDayTimeSlect("Please select one time");
        hasError = true;
        setIsBtnDisable(true);
        return;
      } else if (data?.daysOpened?.some((item) => item.from && !item.to)) {
        setToTimeGivenError("Please select 'To' time");
        setIsBtnDisable(true);
        return;
      } else if (data?.daysOpened?.some((item) => !item.from && item.to)) {
        setFromTimeGivenError("Please select 'From' time");
        setIsBtnDisable(true);
        return;
      } else {
        if (!fromToErrMessage || fromToErrMessage?.length === 0) {
          addNewAddress(data, closeModalHandler)(dispatch);
        }
      }
    }

    props.setSearchLocation("");

    setTimeout(() => closeModalHandler(), 1000);
  };

  const handleTimeChange = (day, type, time) => {
    setFromToErrMessage();
    setToTimeGivenError();
    setOneDayTimeSlect("");

    if (day === "All days") {
      const updatedAllDaysTime = { ...allDaysTime, [type]: time };
      setAllDaysTime(updatedAllDaysTime);
      if (updatedAllDaysTime.from && updatedAllDaysTime.to) {
        const hasError = validateTime(updatedAllDaysTime.from, updatedAllDaysTime.to);
        setIsBtnDisable(hasError);
      }
      return;
    }

    const editTime = [...editTimeAddress];
    editTime[day] = {
      ...editTime[day],
      [type]: time,
    };

    const updatedTimes = { ...selectedTimes };
    updatedTimes[day] = {
      ...updatedTimes[day],
      [type]: time,
    };

    let hasError = false;
    Object.keys(updatedTimes).forEach((dayKey) => {
      const { from, to } = updatedTimes[dayKey];
      if ((!from && to) || (from && !to)) {
        setFromToErrMessage(`Please select both 'From' and 'To' times for ${dayKey}`);
        hasError = true;
      } else if (from && to) {
        if (from.isSame(to)) {
          setFromToErrMessage(`'From' time cannot be the same as 'To' time for ${dayKey}`);
          hasError = true;
        } else if (to.isBefore(from)) {
          setFromToErrMessage(`'To' time cannot be before 'From' time for ${dayKey}`);
          hasError = true;
        }
      }
    });

    if (hasError) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
      setFromToErrMessage();
      setToTimeGivenError();
    }

    setSelectedTimes(updatedTimes);
    setEditTimeAddress(editTime);
  };

  const validateTime = (from, to) => {
    if ((!from && to) || (from && !to)) {
      setFromToErrMessage(`Please select both 'From' and 'To' times for all days`);
      return true;
    } else if (from && to) {
      if (from.isSame(to)) {
        setFromToErrMessage(`'From' time cannot be the same as 'To' time for all days`);
        return true;
      } else if (to.isBefore(from)) {
        setFromToErrMessage(`'To' time cannot be before 'From' time for all days`);
        return true;
      }
    }
    setFromToErrMessage();
    return false;
  };

  const handleReset = () => {
    setSelectedTimes({});
    setEditTimeAddress([]);
    setAllDaysTime({ from: "", to: "" });
    setIsBtnDisable(true); // Disable the submit button
    setFromToErrMessage();
    setToTimeGivenError();
    setOneDayTimeSlect("Please select one time"); // Ensure error message shows on reset if no times are selected
  };

  const searchChangeHandler = (event) => {
    props.setSearchLocation(event.target.value);
  };

  const isAnyDayTimeSelected = () => {
    return Object.keys(selectedTimes).some((day) => selectedTimes[day].from || selectedTimes[day].to);
  };

  OutsideClickHandler(searchBoxRef, () => {
    props.setShowSearchedItems(false);
  });

  return (
    <div className="modal-backdrop">
      <div className="add-address card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inputs-wrapper row">
            <div className="col-12 inputs-heading">Operating Address</div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Address Line 1</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("addressLine1", { required: true })}
                  className="form-control"
                  placeholder="Address line 1"
                />
              </div>
              {errors.addressLine1 && (
                <span className="error">{errors.addressLine1.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper">
              <label className="input-label">Address Line 2</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("addressLine2", { required: true })}
                  className="form-control"
                  placeholder="Address line 2"
                />
              </div>
              {errors.addressLine2 && (
                <span className="error">{errors.addressLine2.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Operating Country</label>
              <div
                className="input-wrap contains-select"
                style={{ background: "#e9ecef" }}
              >
                <select
                  className="form-control country-class"
                  id="countryId"
                  disabled
                >
                  <option value={""}>India</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">
                Operating State/Region
              </label>
              <div className="input-wrap contains-select">
                <select
                  className="form-control"
                  {...register("stateId", { required: true })}
                  name="stateId"
                  onChange={getCityHandler}
                >
                  <option value={``}>Please select state</option>
                  {getState?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {errors.stateId && (
                <span className="error">{errors.stateId.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label">Operating City</label>
              <div className="input-wrap contains-select">
                <select
                  className="form-control"
                  {...register("cityId", { required: true })}
                  name="cityId"
                >
                  <option value={``}>Please select city</option>
                  {getCity?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {errors.cityId && (
                <span className="error">{errors.cityId.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Zip / Postal Code</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("postcode", { required: true })}
                  className="form-control"
                  placeholder="Zip / Postal Code"
                />
              </div>
              {errors.postcode && (
                <span className="error">{errors.postcode.message}</span>
              )}
            </div>
            <div className="input-wrapper required">
              <label
                className="input-label required"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Location
              </label>
              <div
                ref={searchBoxRef}
                className="wrap-input  input-wrap contains-btn contains-search-input"
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="input search text"
                  onChange={searchChangeHandler}
                  ref={props.autoCompleteRef}
                  value={props.searchLocation}
                />
              {locationError && <span className="error">Location is required.</span>}
                
              </div>
            </div>
          </div>
          <div className="row inputs-wrapper mobile-fix">
            <div className="col-8 position-relative">
              <span className="inputs-heading heading-days-open">Days Opened</span>
            </div>
          </div>
          <div className="timing-wrap">
            <div className="row inputs-wrapper mobile-fix align-items-center">
              <div className="col-md-4 col-lg-6 col-lg-5-fix">
                <label className="input-label d-none d-md-block mt-2 mb-0">All Days</label>
              </div>
              <div className="col-md-3 col-lg-2-fix input-wrapper margin-bottom-none">
                <label className="input-label d-none d-md-block">From</label>
                <div className="select-wrap">
                  <TimePicker
                    placeholder={`From`}
                    name="fromTimeAllDays"
                    popupClassName={"test"}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    hideDisabledOptions={true}
                    showSecond={false}
                    inputReadOnly={true}
                    value={allDaysTime.from}
                    onChange={(time) => handleTimeChange("All days", "from", time)}
                    disabled={isAnyDayTimeSelected()}
                  />
                </div>
              </div>
              <div className="col-md-3 col-lg-2-fix input-wrapper margin-bottom-none">
                <label className="input-label d-none d-md-block">To</label>
                <div className="select-wrap">
                  <TimePicker
                    name="toTimeAllDays"
                    placeholder={`To`}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    hideDisabledOptions={true}
                    showSecond={false}
                    inputReadOnly={true}
                    value={allDaysTime.to}
                    onChange={(time) => handleTimeChange("All days", "to", time)}
                    disabled={isAnyDayTimeSelected()}
                  />
                </div>
              </div>
            </div>
            {dayOptions.map((item, index) => {
              return (
                <div className="row inputs-wrapper mobile-fix" key={item}>
                  <div className="col-md-4 col-lg-6 col-lg-5-fix">
                    <div className="select-wrap">{item}</div>
                  </div>
                  <div className="col-md-3 col-lg-2-fix input-wrapper margin-bottom-none">
                    <div className="select-wrap">
                      <TimePicker
                        placeholder={`From`}
                        name="fromTime"
                        popupClassName={"test"}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        hideDisabledOptions={true}
                        showSecond={false}
                        inputReadOnly={true}
                        value={selectedTimes[item]?.from}
                        onChange={(time) => handleTimeChange(item, "from", time)}
                        disabled={allDaysTime.from || allDaysTime.to}
                      />
                      {fromTimeGivenError?.errorOf === item && (
                        <p className="error">Please select From Time</p>
                      )}
                    </div>
                  </div>
                  <div className="position-relative col-md-3 col-lg-2-fix input-wrapper margin-bottom-none">
                    <div className="select-wrap">
                      <TimePicker
                        name="toTime"
                        placeholder={`To`}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        hideDisabledOptions={true}
                        showSecond={false}
                        inputReadOnly={true}
                        value={selectedTimes[item]?.to}
                        onChange={(time) => handleTimeChange(item, "to", time)}
                        disabled={allDaysTime.from || allDaysTime.to}
                      />
                      {toTimeGivenError?.errorOf === item && (
                        <p className="error">Please select To Time</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {fromToErrMessage && <p className="error">{fromToErrMessage}</p>}
            {toTimeGivenError && <p className="error">{toTimeGivenError}</p>}
            {oneDayTimeSelect && <p className="error">{oneDayTimeSelect}</p>}
            <div className="row inputs-wrapper mobile-fix">
              <div className="col-12 d-flex justify-content-end">
                <button type="button" className="btn btn-reset" onClick={handleReset}>
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="btn-wrap">
            <input
              className="secondary-btn"
              type="reset"
              onClick={closeModalHandler}
              value="cancel"
            />
            <input
              className={isBtnDisable ? "btn -disable" : "btn"}
              type="submit"
              value="submit"
              disabled={isBtnDisable}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;

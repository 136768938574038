import React from 'react';
import { Oval } from 'react-loader-spinner'
// import '../../../node_modules/'

import spinner from "../../assets/images/spinner.svg"

const Loader = () => {
    return (
        <div className="modal-backdrop loader-modal">
            <img src={spinner}
            alt="spinner" 
            className="loader" 
            style={{"width": "120px", "height": "120px", "margin": "0px auto -25px"}}/>
            Loading...
        </div>
    )
}

export default Loader;
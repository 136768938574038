import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { homePageData } from "store/action/homePageAction";
import service1 from "../../assets/images/service-5.jpg";
import cardShow from "../../assets/images/card-tag-show.svg";
import "./aboutUs.scss";
import nmImage from "../../assets/images/nm-Medical.png";
import vCare from "../../assets/images/v-Care.png";
import nadkarni from "../../assets/images/nadkarni.png";
import bioGenics from "../../assets/images/bio-genics.png";
import paras from "../../assets/images/paras.png";
import sai from "../../assets/images/sai.png";
import lifeCare from "../../assets/images/lifecrare.png";
import heart from "../../assets/images/heart.png";
import ayuCare from "../../assets/images/ayucare.png";
import kulkarniImg from "../../assets/images/kulkarni.png";
import ecotownImg from "../../assets/images/ecotown.png";
import ApoorvaImg from "../../assets/images/apoorva.png";
import millenniumImg from "../../assets/images/millenium.png";
import phdImg from "../../assets/images/phd.png";
import sonoclinicImg from "../../assets/images/sonoclinic.png";
import vcareImg from "../../assets/images/v-care.png";
import streamImg from "../../assets/images/stream.png";
import kanvaImg from "../../assets/images/kanva.png";
import CodonImg from "../../assets/images/codon.png";
import sunriseImg from "../../assets/images/sunrise.png";
import patilImg from "../../assets/images/labcare.png";
import ashokaImg from "../../assets/images/ashok-lab.png";
import aarthiImg from "../../assets/images/aarthi-labs.png";
import madstarImg from "../../assets/images/medstar.png";
import infedisImg from "../../assets/images/infedis.png";
import vijayaImg from "../../assets/images/vijaya-dia.png";
import medallImg from "../../assets/images/medall.png";
import lifecareImg from "../../assets/images/life-care.png";
import sunflowerImg from "../../assets/images/sunflower.png";
import sunImg from "../../assets/images/sun-path.png";
import mordenImg from "../../assets/images/focusimg.png";
import tecnoImg from "../../assets/images/tecno.png";
import banner from '../../assets/images/Banner_about.jpg'

const AboutUS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeInfo = useSelector((state) => state.homePage);
  const getProfileInfo = useSelector((state) => state.profileView);

  useEffect(() => {
    homePageData()(dispatch);
  }, []);

  console.log("homeInfohomeInfohomeInfohomeInfo", homeInfo);

  let bannerSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="banner">
        {/* <Slider {...bannerSettings}>
          {homeInfo?.data?.bannerData?.map((item, index) => (
            <div className="slide" key={index}>
              <div className="container">
                <div className="banner-wrap">
                  <div className="banner-right">
                    <div className="img-wrap">
                      <picture>
                        <source
                          media="(min-width: 1200px)"
                          srcSet={
                            homeInfo?.data?.bannerImagePath + item.webImage
                          }
                        />
                        <img
                          src={
                            homeInfo?.data?.bannerImagePath + item.mobileImage
                          }
                          alt="banner"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider> */}

        <div className="slide" >
          <div className="container">
            <div className="banner-wrap">
              <div className="banner-right">
                <div className="img-wrap">
                  <picture>
                    {/* <source
                      media="(min-width: 1200px)"
                      srcSet={homeInfo?.data?.bannerImagePath + item.webImage}
                    /> */}
                    <img
                      src={banner}
                      alt="banner"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="core-services">
        <div className="container">
          <h2>About us</h2>
          <p>
            At Aladyyn, our aim is to grow access to blood testing and
            digitalize the blood testing industry. Our aim is to create more
            access for patients as well as help small and medium labs modernize
            and grow through digitalization. More than 300 Labs onboard.
          </p>
          <h3>Our Trusted Lab Partners</h3>
          <div className="services-block">
            {/* {homeInfo?.data?.categoryData?.map((item, index) => (
              <div className="service-wrap" key={index}>
                {getProfileInfo?.data?.[0]?.type !== "Freelancer" &&
                getProfileInfo?.data?.[0]?.type !== "Company" ? (
                  // <a href={`/category/${item._id}/${item.name}`}>
                  <Link to={`/category/${item._id}/${item.name}`}>
                    <div className="img-wrap">
                      {item.categoryIcon ? (
                        <img
                          src={
                            homeInfo?.data?.categoryImagePath +
                            item.categoryIcon
                          }
                          alt="service"
                        />
                      ) : (
                        <img src={service1} alt="service" />
                      )}
                    </div>
                    <div className="service-info">{item.name}</div>
                  </Link>
                ) : (
                  // </a>
                  <a>
                    <div className="img-wrap">
                      {item.categoryIcon ? (
                        <img
                          src={
                            homeInfo?.data?.categoryImagePath +
                            item.categoryIcon
                          }
                          alt="service"
                        />
                      ) : (
                        <img src={service1} alt="service" />
                      )}
                    </div>
                    <div className="service-info">{item.name}</div>
                  </a>
                )}
              </div>
            ))} */}

            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={nmImage} alt="Medical" />
                </div>
                <span className="service-info">NM MEDICAL</span>
                <div className="para-wrap">
                  <p>
                    NM Medical is well-knownfor its state-of-the-art medical
                    diagnostic equipment andits dedicationtoprecisioninmedical
                    testing.
                  </p>
                  <p>
                    The focus is ondeliveringcomprehensive healthcare solutions
                    witha patient-centric approach.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={vCare} alt="V-Care" />
                </div>
                <span className="service-info">Vcare</span>
                <div className="para-wrap">
                  <p>
                    Vcare Imaging & Diagnostics Centre specializes in advanced
                    imaging technologies, ensuring precise diagnostics for
                    various medical conditions.
                  </p>
                  <p>
                    {" "}
                    Their dedication to quality and patient care has established
                    them as a trusted name in diagnostic services.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={nadkarni} alt="Nadkarni" />
                </div>
                <span className="service-info">
                  Nadkarni Pathology Laboratory{" "}
                </span>
                <div className="para-wrap">
                  <p>
                    Nadkarni Pathology Laboratory is known for its high
                    standards in pathology testing and is supported by a team of
                    experienced professionals.
                  </p>
                  <p>
                    The lab is renowned for providing reliable diagnostic
                    reports for effective patient care.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={bioGenics} alt="BioGenics" />
                </div>
                <span className="service-info">Biogenics Labs </span>
                <div className="para-wrap">
                  <p>
                    Biogenics Labs is a leader in genetic testing and molecular
                    diagnostics, providing cutting-edge solutions for
                    personalized medicine.
                  </p>
                  <p>
                    Their dedicated research and development team is focused on
                    pioneering new diagnostic technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={paras} alt="Paras" />
                </div>
                <span className="service-info">Paras Pathology </span>
                <div className="para-wrap">
                  <p>
                    Paras Pathology is known for its comprehensive range of
                    diagnostic tests and its dedication to providing
                    high-quality healthcare services.
                  </p>
                  <p>
                    The laboratory employs state-of-the-art technology to ensure
                    accurate and prompt results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={sai} alt="Sai" />
                </div>
                <span className="service-info">Sai Pathology Lab </span>
                <div className="para-wrap">
                  <p>
                    Sai Pathology Lab is renowned for its dependable diagnostic
                    services and a wide range of pathology tests customized to
                    meet patients' needs.
                  </p>
                  <p>
                    Their emphasis on precision and prompt reporting makes them
                    the preferred choice for healthcare providers.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={lifeCare} alt="Life-Care" />
                </div>
                <span className="service-info">Lifecare Diagnostics </span>
                <div className="para-wrap">
                  <p>
                    Lifecare Diagnostics provides a wide range of diagnostic
                    services, focusing on precision and patient well-being.
                  </p>
                  <p>
                    They utilize state-of-the-art diagnostic technology to
                    ensure high-quality medical testing.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={heart} alt="Heart" />
                </div>
                <span className="service-info">CareFirst</span>
                <div className="para-wrap">
                  <p>
                    CareFirst Diagnostics is dedicated to providing high-quality
                    diagnostic services focusing on patient-centered care.
                  </p>
                  <p>
                    Its innovative approach and state-of-the-art technology
                    establish new standards in the diagnostics industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={ayuCare} alt="Ayucare" />
                </div>
                <span className="service-info">Ayucare</span>
                <div className="para-wrap">
                  <p>
                    Ayucare Diagnostic Centre combines traditional Ayurvedic
                    practices with modern diagnostic technology for
                    comprehensive health assessments.
                  </p>
                </div>
              </div>
            </div>

            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={kulkarniImg} alt="Medical" />
                </div>
                <span className="service-info">Kulkarni Laboratory</span>
                <div className="para-wrap">
                  <p>
                    Kulkarni Laboratory is known for its stringent quality
                    control standards and extensive range of diagnostic tests.
                  </p>
                  <p>
                    The team of highly skilled professionals is committed to
                    delivering precise and prompt diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={ecotownImg} alt="Medical" />
                </div>
                <span className="service-info">Ecotown Diagnostics</span>
                <div className="para-wrap">
                  <p>
                    Ecotown Diagnostics is recognized for its environmentally
                    friendly approach to diagnostics, integrating sustainable
                    practices with cutting-edge technology.
                  </p>
                  <p>
                    Their dedication to eco-friendly diagnostics distinguishes
                    them in the industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={ApoorvaImg} alt="Medical" />
                </div>
                <span className="service-info">Apoorva Diagnostics</span>
                <div className="para-wrap">
                  <p>
                    Apoorva Diagnostics offers a wide range of diagnostic
                    services, prioritizing accuracy and patient comfort through
                    state-of-the-art technology.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={millenniumImg} alt="Medical" />
                </div>
                <span className="service-info">Millennium</span>
                <div className="para-wrap">
                  <p>
                    Millennium Special Pathology Labs is well-known for its
                    specialized diagnostic tests and advanced pathology
                    services.
                  </p>
                  <p>
                    The company's emphasis on innovation and quality has
                    positioned it as a leader in the pathology sector.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={phdImg} alt="Medical" />
                </div>
                <span className="service-info">PH Diagnostic</span>
                <div className="para-wrap">
                  <p>
                    PH Diagnostic Centre is recognized for its comprehensive
                    diagnostic services and dedication to medical excellence.
                  </p>
                  <p>
                    It uses the latest diagnostic technologies to deliver
                    precise and prompt results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={sonoclinicImg} alt="Medical" />
                </div>
                <span className="service-info">Sonoclinic Diagnostic</span>
                <div className="para-wrap">
                  <p>
                    PImagesense Sonoclinic Diagnostic Center is renowned for its
                    state-of-the-art imaging services and diagnostic expertise.
                  </p>
                  <p>
                    Equipped with the latest imaging technology, they ensure
                    high-resolution diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={vcareImg} alt="vcareImg" />
                </div>
                <span className="service-info">Vcare Pathology Laboratory</span>
                <div className="para-wrap">
                  <p>
                    Vcare Path is renowned for its wide range of pathology
                    services and dedication to delivering top-notch diagnostics.
                  </p>
                  <p>
                    Their emphasis on excellence and patient well-being has
                    positioned them as a dependable name in the field.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={streamImg} alt="streamImg" />
                </div>
                <span className="service-info">Stream Diagnostic</span>
                <div className="para-wrap">
                  <p>
                    Stream Diagnostix Pathology Services offers innovative
                    diagnostic solutions and efficient service delivery.
                  </p>
                  <p>
                    Their cutting-edge technology and expert team ensure
                    accurate and timely diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={kanvaImg} alt="streamImg" />
                </div>
                <span className="service-info">Kanva Diagnostic</span>
                <div className="para-wrap">
                  <p>
                    Kanva Diagnostics is renowned for its comprehensive
                    diagnostic services and state-of-the-art laboratory
                    facilities.
                  </p>
                  <p>
                    Their dedication to quality and patient care makes them the
                    top choice for diagnostic testing.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={CodonImg} alt="CodonImg" />
                </div>
                <span className="service-info">Codon Diagnostic</span>
                <div className="para-wrap">
                  <p>
                    Codon Diagnostics Pvt Ltd specializes in molecular and
                    genomic testing and offers advanced solutions.
                  </p>
                  <p>
                    Their research-driven approach ensures the development of
                    cutting-edge diagnostic technologies
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={sunriseImg} alt="sunriseImg" />
                </div>
                <span className="service-info">Sunrise Diagnostic</span>
                <div className="para-wrap">
                  <p>
                    Sunrise Diagnostic Center & Pathology is renowned for its
                    comprehensive diagnostic services and patient-centered care.
                  </p>
                  <p>
                    The state-of-the-art facilities and experienced staff ensure
                    accurate and timely diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={patilImg} alt="patilImg" />
                </div>
                <span className="service-info">Lab Care Laboratory </span>
                <div className="para-wrap">
                  <p>
                    Patil's Lab Care Laboratory is renowned for its
                    comprehensive diagnostic services and unwavering commitment
                    to excellence.
                  </p>
                  <p>
                    Their cutting-edge technology ensures precise and prompt
                    diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={ashokaImg} alt="ashokImg" />
                </div>
                <span className="service-info">
                  Ashoka Pathology Laboratory
                </span>
                <div className="para-wrap">
                  <p>
                    Ashoka Pathology Laboratory is well-known for its wide range
                    of diagnostic tests and exceptional patient care.
                  </p>
                  <p>
                    Their dedication to quality and precision has earned them
                    the status of a reputable diagnostic.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={aarthiImg} alt="aarthiImg" />
                </div>
                <span className="service-info">Aarthi Labs</span>
                <div className="para-wrap">
                  <p>
                    Aarthi Labs provides various diagnostic services,
                    prioritizing accuracy and patient well-being.
                  </p>
                  <p>
                    Their advanced facilities and experienced professionals
                    ensure precise diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={madstarImg} alt="madstarImg" />
                </div>
                <span className="service-info">Medstar Diagnostics</span>
                <div className="para-wrap">
                  <p>
                    New Medstar Diagnostics Pathology Lab specializes in various
                    diagnostic tests, focusing on ensuring quality.
                  </p>
                  <p>
                    Their experienced team and state-of-the-art technology
                    guarantee accurate diagnostic outcomes.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={infedisImg} alt="infedisImg" />
                </div>
                <span className="service-info">Infedis Diagnostics</span>
                <div className="para-wrap">
                  <p>
                    Infedis Diagnostic Centre is known for its innovative
                    diagnostic services and dedication to quality.
                  </p>
                  <p>
                    Their advanced technology and expert team ensure accurate
                    and reliable diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={vijayaImg} alt="infedisImg" />
                </div>
                <span className="service-info">Vijaya Diagnostics</span>
                <div className="para-wrap">
                  <p>
                    Renowned for its comprehensive diagnostic services,
                    including advanced imaging and pathology tests.
                  </p>
                  <p>
                    They are committed to providing high-quality healthcare
                    through state-of-the-art technology and expert staff.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={medallImg} alt="infedisImg" />
                </div>
                <span className="service-info">Medall Diagnostics</span>
                <div className="para-wrap">
                  <p>
                    It offers a comprehensive range of diagnostic services,
                    emphasizing precision and efficiency
                  </p>
                  <p>
                    TIt employs state-of-the-art diagnostic equipment and highly
                    trained staff for dependable results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={lifecareImg} alt="lifecareImg" />
                </div>
                <span className="service-info">
                  Life Care Pathology Laboratory
                </span>
                <div className="para-wrap">
                  <p>
                    Provides a wide range of diagnostic services, prioritizing
                    patient satisfaction and accuracy.
                  </p>
                  <p>
                    It is equipped with advanced laboratory facilities to ensure
                    high-quality diagnostics.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={sunflowerImg} alt="sunflowerImg" />
                </div>
                <span className="service-info">Sunflower Laboratory</span>
                <div className="para-wrap">
                  <p>
                    It offers a wide array of diagnostic tests and is dedicated
                    to precision and dependability
                  </p>
                  <p>
                    It uses state-of-the-art diagnostic tools to ensure accurate
                    and prompt results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={sunImg} alt="sunImg" />
                </div>
                <span className="service-info">Sun Pathology Laboratory</span>
                <div className="para-wrap">
                  <p>
                    Known for its detailed and precise pathology services
                    focusing on research and innovation
                  </p>
                  <p>
                    Features state-of-the-art diagnostic equipment to provide
                    high-quality and reliable results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={mordenImg} alt="mordenImg" />
                </div>
                <span className="service-info">Focus Path Lab</span>
                <div className="para-wrap">
                  <p>
                    Focus Laboratory provides various diagnostic services,
                    prioritizing accuracy and patient well-being.
                  </p>
                  <p>
                    Their cutting-edge technology ensures precise and prompt
                    diagnostic results.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-wrap stats-services">
              <div className="aboutus-block">
                <div className="img-wrap">
                  <img src={tecnoImg} alt="mordenImg" />
                </div>
                <span className="service-info">Techno Clin</span>
                <div className="para-wrap">
                  <p>
                    Techno Clin Services offers innovative solutions and
                    efficient service delivery.
                  </p>
                  <p>
                    Their practices with modern diagnostic technology for
                    comprehensive health assessments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus-card-block">
        <div className="container">
          <div className="aboutus-cards-wrap">
            {/* <div className="aboutus-cards aboutus-cards-one card">
              <span className="card-tag-name">NM Medical</span>
              <p>
                Lorem ipsum dolor sit amet consectetur. Aliquet tincidunt
                bibendum sed tincidunt eget amet tristique. Aliquet in amet
                sagittis id ac odio consectetur praesent mi. Habitant enim sed
                duis sed. Integer urna diam netus pharetra vitae malesuada
                viverra nibh senectus.
              </p>
            </div> */}
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Shuban Ventures</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Pearlin Pathology Diagnostic Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Santosh Labs</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">VENKATESHWARA DIAGNOSTICS</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Primex Health Pathology</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Ashwini Pathology Laboratory{" "}
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Shri Pathology Lab </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Nidan Diagnostic Service</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Focus Laboratory</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Shwas Patholagy Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Renushil Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Pro Diagnostics</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Omkar Pathology</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Siddhi diagnostic</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Advanced Diagnostics RT- PCR Lab
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Shuban Ventures</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Vedant Pathology Lab</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Om Laboratory Institute of Pathology & Microbiology
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Daya labs</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Nirmaan Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Qline Diagnostics Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  GUJARAT PATH LAB & DIAGNOSTIC CENTRE
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">ANSH Pathology Laboratory</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Solaris Diagnostics</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Amruth Lab</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Shreyas Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Sheefa Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Goldcare path</span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Chikitsa Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Dr. Ravi Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  InSite Pathology Laboratory
                </span>
              </div>
            </div>
            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  InSite Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Parth Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Credence Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Dr Nalini Shah's Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Paras path</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Aradhana Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">LabXpert Diagnostics</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Udgam x Gujarat Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Sun Pathology laboratory & Research Institute
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Khushi Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Spandan Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Maitri Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Rushabh Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Siddhi Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  SPARSH PATHOLOGY LABORATORY
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Shiv pathology laboratory</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Mahakant Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Jeevandeep Pathology Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Anamay Path Lab</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Harish Diagnostic Laboratory
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">
                  Nidan Pathology Laboratory & Diagnostic Centre
                </span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Indore Path Lab</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">City Pathology Laboratory</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">AYUSHMAN PATHOLOGY LAB</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Lifecare Diagnostics</span>
              </div>
            </div>

            <div className="aboutus-cards aboutus-cards-two card">
              <div className="cards-show">
                <span className="card-img-wrap">
                  <img src={cardShow} alt="card-tag" />
                </span>
              </div>
              <div className="cards-details">
                <span className="card-tag-name">Shivani Diagnostic Centre</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUS;

import * as actionTypes from 'store/actionType'

const initialState = {};

const wishlistRecommendedService = (state, payload) => {
	let newState = [...state.recommendedServices];
	newState = newState.map((item) => {
		if (item._id === payload.data.serviceId) {
			return { ...item, wishlist: payload.type };
		} else {
			return item;
		}
	});
	return { ...state, recommendedServices: newState };
};

const recommendedServicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SELLER_RECOMMENDED_SERVICE:
			return { recommendedServices: action.payload }
		case "RECOMMENDED":
			return wishlistRecommendedService(state, action.payload);
		default:
			return state;
	}
}

export default recommendedServicesReducer;
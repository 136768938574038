import React, { useState, useEffect } from "react";
import { useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
// import {getFormToken } from '../../store/action/getFormToken'
// import { sellerReg } from '../../store/action/becomeSellerAction'
import sellerReg from "store/action/becomeSellerAction";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "store/action/countryList";
import { stateList, getStateList } from "store/action/stateList";
import { getCityList } from "store/action/cityListAction";
import { loginAction } from "store/action/userLoginAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import advertiserEnquiry from "store/action/advertiserSignUpAction";
import { Alert } from "components/Alert/Alert";
import advertiserReg from "store/action/advertiserSignUpAction";

const numberRegex = /^[0-9]+$/;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .min(2, "First name must be at least 2 characters")
    .max(20, "First name must be at most 20 characters")
    .matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
  lastName: yup
    .string()
    .required("Please enter Last Name")
    .min(2, "Last name must be at least 2 characters")
    .max(20, "Last name must be at most 20 characters")
    .matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
  email: yup
    .string()
    .required("Please enter your email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Please use the correct email"
    ),
  businessName: yup
    .string()
    .required("Please enter Company Name")
    .min(2, "Company Name must be at least 2 characters")
    .max(50, "Company Name must be at most 50 characters"),
  // .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Please enter valid Company Name"),
  primaryContactPerson: yup
    .string()
    .required("Please enter Primary Contact Person")
    .min(2, "Primary contact person must be at least 2 characters")
    .max(20, "Primary contact person must be at most 20 characters")
    .matches(/^[A-Za-z ]+$/i, "Please enter valid primary contact person"),
  companyRegistrationNumber: yup
    .string()
    .required("Please enter Company Register Number")
    .matches(/^[0-9a-zA-Z]+$/, "Company Register Number is not valid"),
  vat: yup.string(),
  // .required("Please enter Company Register Number")
  // .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i, "VAT Number is not valid"),
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(60, "Address must be at most 20 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup.string(),
  cityId: yup.string()
  .required("Please enter City"),
  // .min(2, "City must be at least 2 characters")
  // .max(20, "City must be at most 20 characters"),
  stateId: yup.string().required("Please enter state"),
  // countryId: yup.string().required("Please enter country"),
  phone: yup
    .string()
    .required("Please enter mobile")
    // .matches(/^[0-9]*$/, "Phone number is not valid")
    .min(7)
    .max(14),
  postcode: yup
    .string()
    .required("Please enter Zip")
    .matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid")
    .min(3),
  // .max(6),
  privacyPolicy: yup.bool().oneOf([true], "Please check the privacy policy"),
  comment: yup.string().required("Please enter Comment").min(2).max(300),
  password: yup
    .string()
    .required("Please enter the password")
    .min(8, "Password should be equal or greater that 8 characters"),
  re_password: yup
    .string()
    .required("Please enter the password")
    .min(8, "Password should be equal or greater that 8 characters")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password must be same as New Password"
    ),
});

const AdvertiserEnquiry = () => {
  const navigate = useNavigate();

  const location=useLocation();
  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const getCity = useSelector((state) => state.cityData);
  const [passwordType, setPasswordType] = useState("password");
  const [con_passwordType, setCon_passwordType] = useState("password");
  const [country, setCountry] = useState("");
  const login = useSelector((state) => state.login);
  const [region, setRegion] = useState("");
  const [checked, setChecked] = useState(false);
  const [statename, setStateName] = useState("");
  const [numberState, setNumberState] = useState("");
  const [countryname, setCountryname] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [stateListState, setStateListState] = useState([]);
  const [cityListState, setCityListState] = useState([]);

  const dispatch = useDispatch();
  const notify = () => toast("User Registered Successfully!");
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    countryList()(dispatch);
  }, []);

  const onSubmit = (data, e, val) => {
    setIsFormSubmitting(true);
    // data.type = "Company";
    data.countryId = "61c2fb83dc7c0d455ba5e68d";
    

    if (data !== "") {
      advertiserReg(data, navigate, (callback) => {
        
        toast.success("Your enquiry has been submitted successfully");
        // setTimeout(() => setIsFormSubmitting(false), 3000);
        // setTimeout(() =>(window.location.href = "/"), 3000);
        setTimeout(() =>( window.location.reload("/")), 3000);
      })(dispatch);
      // return
    } else {
      
      return;
    }
    // if (data !== "") {
    //   advertiserEnquiry(data, navigate, () => {
    //     toast.success("Your enquiry has been submitted successfully");
    //     setTimeout(() => setIsFormSubmitting(false), 3000);
    //   })(dispatch);
    //   // return
    // } else {
    //   return;
    // }
  };

  // const getStateHandler = (e) => {
  //   let val = e.target.value;
  //   setCountryname(val);
  //   getStateList({ countryId: val }, (callback) => {
  //     setStateListState(callback);
  //   })(dispatch);
  //   setCityListState([]);
  //   setValue("stateId", "");
  //   setValue("cityId", "");
  // };

  useEffect(() => {
    getStateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, (callback) => {
      setStateListState(callback);
    })(dispatch);
  }, []);

  const getCityHandler = (e) => {
    getCityList(
      { countryId: "61c2fb83dc7c0d455ba5e68d", stateId: e.target.value },
      (callback) => {
        setCityListState(callback);
      }
    )(dispatch);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const numberChangeHandler = (event) => {
    let value = event.target.value
        value = value.replaceAll(' ', '')
        setNumberState(numberState => value.length <= 10 && !isNaN(Number(value)) && { value } || numberState)

        setIsFormSubmitting(false)

    // if (
    //   numberRegex.test(event.target.value) ||
    //   event.target.value.length == 0
    // ) {
    //   setNumberState(event.target.value);
    // }
  };

  const adviserZipHandler =(e)=>{
    const inputValue = e.target.value.toString(); 
    const truncatedValue = inputValue.slice(0, 6); 
    e.target.value = truncatedValue; 
  }

  return (
    <section className="become-seller">
      {/* <Alert /> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="container">
        <h1>Enquiry For Advertiser</h1>
        <div className="company-tab">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card inputs-wrapper">
              <h4>Verification</h4>
              <div className="row input-block">
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required required">
                    first name
                  </label>
                  <div
                    className={`input-wrap ${
                      errors.firstName ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      {...register("firstName")}
                      maxLength={15}
                      placeholder="First Name"
                    />
                  </div>
                  {errors.firstName && (
                    <span className="error">{errors.firstName.message}</span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">Last name</label>
                  <div
                    className={`input-wrap ${
                      errors.lastName ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      {...register("lastName")}
                      className="form-control"
                      maxLength={15}
                      placeholder="Last Name"
                    />
                  </div>
                  {errors.lastName && (
                    <span className="error">{errors.lastName.message}</span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">Email address</label>
                  <div
                    className={`input-wrap ${errors.email ? "has-error" : ""}`}
                  >
                    <input
                      type="email"
                      {...register("email")}
                      className="form-control"
                      autoComplete="off"
                      placeholder="example@aladyyn.pro"
                    />
                  </div>
                  {errors.email && (
                    <span className="error">{errors.email.message}</span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">Password</label>
                  <div
                    className={`input-wrap password ${
                      errors.password ? "has-error" : ""
                    }`}
                  >
                    <input
                      type={passwordType}
                      {...register("password")}
                      className="form-control"
                      placeholder="**********"
                    />
                    <div className="toggle-password">
                      <i
                        onClick={() => {
                          setPasswordType(
                            passwordType === "password" ? "text" : "password"
                          );
                        }}
                        className={`icon-eye ${
                          passwordType === "password" ? "" : "open"
                        }`}
                      ></i>
                    </div>
                  </div>
                  {errors.password && (
                    <span className="error">{errors.password.message}</span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">
                    Confirm Password
                  </label>
                  <div
                    className={`input-wrap password ${
                      errors.re_password ? "has-error" : ""
                    }`}
                  >
                    <input
                      type={con_passwordType}
                      {...register("re_password")}
                      className="form-control"
                      placeholder="************"
                    />
                    <div className="toggle-password">
                      <i
                        onClick={() => {
                          setCon_passwordType(
                            con_passwordType === "password"
                              ? "text"
                              : "password"
                          );
                        }}
                        className={`icon-eye ${
                          con_passwordType === "password" ? "" : "open"
                        }`}
                      ></i>
                    </div>
                  </div>
                  {errors.re_password && (
                    <span className="error">{errors.re_password.message}</span>
                  )}
                </div>
              </div>
              <div className="inputs-heading">Business Information</div>
              <div className="row input-block margin-fix">
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">Company Name</label>
                  <div
                    className={`input-wrap ${
                      errors.businessName ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      {...register("businessName")}
                      placeholder="Company Name"
                    />
                  </div>
                  {errors.businessName && (
                    <span className="error">{errors.businessName.message}</span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">
                    Primary Contact Person
                  </label>
                  <div
                    className={`input-wrap ${
                      errors.primaryContactPerson ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      {...register("primaryContactPerson")}
                      className="form-control"
                      placeholder="Primary Person"
                    />
                  </div>
                  {errors.primaryContactPerson && (
                    <span className="error">
                      {errors.primaryContactPerson.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label required">
                    Company Registration Number
                  </label>
                  <div
                    className={`input-wrap ${
                      errors.companyRegistrationNumber ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      {...register("companyRegistrationNumber")}
                      className="form-control"
                      autoComplete="off"
                      placeholder="Company Registration Number"
                    />
                  </div>
                  {errors.companyRegistrationNumber && (
                    <span className="error">
                      {errors.companyRegistrationNumber.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 input-wrapper">
                  <label className="input-label">
                    VAT Number{" "}
                    <span className="text-lowercase">(if applicable)</span>
                  </label>
                  <div
                    className={`input-wrap ${errors.vat ? "has-error" : ""}`}
                  >
                    <input
                      type="text"
                      {...register("vat")}
                      className="form-control"
                      autoComplete="off"
                      placeholder="Vat number"
                    />
                  </div>
                  {errors.vat && (
                    <span className="error">{errors.vat.message}</span>
                  )}
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="input-wrapper required">
                    <label className="input-label required">Phone Number</label>
                    <div
                      className={`input-wrap ${
                        errors.phone ? "has-error" : ""
                      }`}
                    >
                      <input
                        type="text"
                        {...register("phone")}
                        className="form-control"
                        placeholder="Mobile"
                        // maxLength="14"
                        value={numberState.value}
                        onChange={numberChangeHandler}
                      />
                    </div>
                    {errors.phone && (
                      <span className="error">{errors.phone.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputs-heading">Registered Business Address</div>
              <div className="row input-block">
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label required">address line 1</label>
                  <div
                    className={`input-wrap ${
                      errors.addressLine1 ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      {...register("addressLine1")}
                      placeholder="Address"
                    />
                    <span className="input-info">
                      Building number and Street
                    </span>
                  </div>
                  {errors.addressLine1 && (
                    <span className="error">{errors.addressLine1.message}</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper">
                  <label className="input-label">address line 2</label>
                  <div
                    className={`input-wrap ${
                      errors.addressLine2 ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      {...register("addressLine2")}
                      className="form-control"
                      placeholder="Address"
                    />
                    <span className="input-info">Room/Block/Apartments</span>
                  </div>
                  {errors.addressLine2 && (
                    <span className="error">{errors.addressLine2.message}</span>
                  )}
                </div>

                {/* <div className="col-md-6 input-wrapper required">
                  <label className="input-label">Country</label>
                  <div
                    className={`input-wrap ${
                      errors.countryId ? "has-error" : ""
                    }`}
                  >
                    <select
                      {...register("countryId")}
                      name="countryId"
                      className="form-control drop-down"
                      id="countryId"
                      onChange={getStateHandler}
                    >
                      <option value={""} disabled selected>
                        Please select country
                      </option>
                      {getCountry?.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  {errors.countryId && (
                    <span className="error">{errors.countryId.message}</span>
                  )}
                </div> */}

                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">Country</label>
                  <div
                    className={`input-wrap ${
                      errors.countryId ? "has-error" : ""
                    }`}
                  >
                    <select className="form-control" id="countryId" disabled>
                      <option value={""}>India</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6 input-wrapper required">
                  <label className="input-label required">State / Region</label>
                  <div
                    className={`input-wrap ${
                      errors.stateId ? "has-error" : ""
                    }`}
                  >
                    <select
                      className="form-control drop-down"
                      {...register("stateId")}
                      name="stateId"
                      onChange={getCityHandler}
                    >
                      <option value={""} disabled selected>
                        Please select state
                      </option>
                      {stateListState?.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  {errors.stateId && (
                    <span className="error">{errors.stateId.message}</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper">
                  <label className="input-label">City</label>
                  <div  className={`input-wrap ${
                      errors.cityId ? "has-error" : ""
                    }`}>
                    <select
                      className="form-control drop-down"
                      {...register("cityId")}
                      name="cityId"
                    >
                      <option value={""}>Please select city</option>
                      {cityListState?.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  {errors.cityId && (
                    <span className="error">{errors.cityId.message}</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label required">
                    ZIP / Postal Code
                  </label>
                  <div
                    className={`input-wrap ${
                      errors.postcode ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      {...register("postcode",{
                        onChange: adviserZipHandler,
                      })}
                      className="form-control"
                      autoComplete="off"
                      placeholder="Zip Code"
                    />
                  </div>
                  {errors.postcode && (
                    <span className="error">{errors.postcode.message}</span>
                  )}
                </div>
              </div>
              <div className="row input-block">
                <div className="col-12 input-wrapper required mb-0 textarea-info-wrapper">
                  <label className="input-label required">Comment</label>
                  <div
                    className={`input-wrap ${
                      errors.comment ? "has-error" : ""
                    }`}
                  >
                    <textarea
                      type="text"
                      className="form-control"
                      {...register("comment")}
                      placeholder="Please enter your comment"
                    />
                  </div>
                  <label className="textarea-info comment-info input-label input-info position-static">
                    Comment should not exceed 300 characters.
                  </label>
                  {errors.comment && (
                    <span className="error">{errors.comment.message}</span>
                  )}
                </div>
              </div>
              {/* <div className="form-check">
								<input
									type="checkbox"
									name="selectCheckbox"
									id="selectCheckbox"
									{...register("privacyPolicy")}
									className={`form-check-input ${errors.privacyPolicy ? "is-invalid" : ""
										}`}
								/>
								<label htmlFor="privacyPolicy" className="form-check-label">
									Please accept our{" "}
									<Link
										target="_blank"
										to="/terms-and-conditions"
										className="term-conditions"
									>
										terms and conditions
									</Link>
								</label>
								<div className="invalid-feedback">
									{errors.privacyPolicy?.message}
								</div>
							</div> */}
            </div>
            <div className="btn-wrap">
              <input
                className="btn"
                type="submit"
                value="submit"
                disabled={isFormSubmitting ? true : false}
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AdvertiserEnquiry;

import * as types from '../actionType'

const initialState = []

const profileView = (state = initialState, action) => {
    switch (action.type) {
        case types.PROFILE_VIEW:
            return action.payload
        default:
            return state
    }
}


const profileEdit = (state = initialState, action) => {
    switch (action.type) {
        case types.EDIT_PROFILE:
            return action.payload
        default:
            return state
    }
}


const advertiserView = (state = initialState, action) => {
    switch (action.type) {
        case types.ADVERTISER_PROFILE_VIEW:
            return action.payload
        default:
            return state
    }
}

const userView = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_PROFILE:
            return action.payload
        default:
            return state
    }
}

const advertiserEdit = (state = initialState, action) => {
    switch (action.type) {
        case types.ADVERTISER_EDIT:
            return action.payload
        default:
            return state
    }
}

export { profileEdit, profileView, advertiserView, advertiserEdit, userView };
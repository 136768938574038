import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import profileImg from "assets/images/profile-1.jpg";
import { userEditAction } from "store/action/userEditAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userProfile } from "store/action/userProfileAction";
import { viewProfile } from "store/action/profileAction";
import { Alert } from "components/Alert/Alert";
import { ToastContainer } from "react-toastify";
import Loader from "components/Loader/Loader";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .min(2, "First name must be at least 2 characters")
    .max(20, "First name must be at most 20 characters"),
  lastName: yup
    .string()
    .required("Please enter Last Name")
    .min(2, "Last name must be at least 2 characters")
    .max(20, "Last name must be at most 20 characters"),
  phone: yup.string().required("Please enter mobile number").min(7).max(14),
  email: yup
    .string()
    .required("Please enter your email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Please use the correct email"
    ),
});

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let attachmentName = useRef(null);
  const [img, setImg] = useState("");
  const [fileAttach, setFileAttach] = useState(null);
  const getUserInfo = useSelector((state) => state.profileView);
  const [isloader, setIsLoader] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    viewProfile()(dispatch);
    userProfile()(dispatch);
  }, []);

  const imgHandler = (e) => {
    setFileAttach(e.target.files[0]);
    let src_ = URL.createObjectURL(e.target.files[0]);
    setImg(src_);
  };

  useEffect(() => {
    if (getUserInfo?.data?.length > 0) {
      setValue("firstName", getUserInfo.data[0].firstName);
      setValue("lastName", getUserInfo.data[0].lastName);
      setValue("email", getUserInfo.data[0].email);
      setValue("phone", getUserInfo.data[0].phone);
      setImg(
        getUserInfo.data[0].image
          ? getUserInfo.data[0].path + getUserInfo.data[0].image
          : ""
      );
    }
  }, [getUserInfo]);

  const onSubmit = (data, e) => {
    let payload = new FormData();
    payload.append("firstName", data.firstName);
    payload.append("email", data.email);
    payload.append("phone", data.phone);
    payload.append("lastName", data.lastName);
    payload.append("image", fileAttach);
    setIsLoader(true);
    userEditAction(payload, () => {
      setIsLoader(false);
      navigate({ pathname: "/user/profile" })
      // setTimeout(() => {
      //   window.location.href = "/user/profile"
      //   // navigate({ pathname: "/user/profile" })
      // }, 1000);
    })(dispatch);
  };

  const changeHandler = () => {
    navigate({ pathname: "/user/profile" });
  };

  const changePage = () => {
    navigate({ pathname: "/user/dashboard" });
  };

  return (
    <section className="user-profile edit">
      {isloader ? <Loader /> : null}
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item onClick={changePage}>User Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={changeHandler}>
            User Profile
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit User Profile</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Edit User Profile</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="account-info">
            <div className="card account-card inputs-wrapper">
              <div className="account-heading">Account Information</div>
              <div className="row input-block">
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">first name</label>
                  <div
                    className={`input-wrap ${
                      errors.firstName ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      {...register("firstName")}
                      placeholder="FirstName"
                    />
                  </div>
                  {errors.firstName && (
                    <span className="error">{errors.firstName.message}</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">last name</label>
                  <div
                    className={`input-wrap ${
                      errors.lastName ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      {...register("lastName")}
                      placeholder="LastName"
                    />
                  </div>
                  {errors.lastName && (
                    <span className="error">{errors.lastName.message}</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">email</label>
                  <div
                    className={`input-wrap ${errors.email ? "has-error" : ""}`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      {...register("email")}
                      placeholder="Email"
                    />
                  </div>
                  {errors.email && (
                    <span className="error">{errors.email.message}</span>
                  )}
                </div>
                {/* <div className="col-md-6 input-wrapper required mb-0">
									<div className="input-wrapper required mb-0">
										<label htmlFor="user-id">Mobile Number</label>
										<div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
											<input
												type="number"
												{...register("phone")}
												className='form-control'
												placeholder="Mob"
											/>
										</div>
										{errors.phone && (
											<span className="error">
												{errors.phone.message}
											</span>
										)}
									</div>
								</div> */}
              </div>
            </div>
            <div className="card account-card inputs-wrapper">
              <div className="account-heading">Basic Information</div>
              <div className="row input-block">
                {/* <div className="col-md-6 input-wrapper required"> */}
                {/* <label className="input-label">Date of Birth</label> */}
                {/* <DatePicker className="input-wrap"
                        		        onChange={onChange}
                        		        value={value}
                        		        type="date"
                        		            className="form-control"
                        		            {...register("date")}
											placeholder='Select date'
                        		    /> */}
                {/* <div className={`input-wrap ${errors.dob ? 'has-error' : ''}`}>
										<input
											type='date'
											className="form-control date-input"
											{...register("dob")}
											placeholder='Select date'
										/>
									</div>
									{errors.dob && (
										<span className="error">
											{errors.dob.message}
										</span>
									)} */}
                {/* </div> */}
                <div className="input-wrapper required img-input-fix has-input-file">
                  <label className="mb-2">Profile Image</label>
                  <div className="row input-block">
                    <div className="col-lg-8 input-wrapper">
                      <div className="img-input-wrapper">
                        <div className="img-input">
                          <i className="icon-plus"></i>
                          Upload
                          <input
                            {...register("image")}
                            type="file"
                            accept="image/*"
                            ref={attachmentName}
                            onChange={imgHandler}
                          />
                        </div>
                        <span className="img-info">Upload profile picture</span>
                      </div>
                      {img !== "" && (
                        <div className="img-thumbnail-wrapper attachment-name image">
                          <img src={img} />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {file && <ImageThumb image={file} />} */}
                </div>
              </div>
            </div>
          </div>
          <div className="btns-wrap">
            <button
              className="secondary-btn"
              onClick={changeHandler}
              type="button"
            >
              Cancel
            </button>
            <input className="btn" type="submit" />
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditProfile;

import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
	firstname: yup
		.string()
		.required("Please enter First Name")
		.min(2, "First name must be at least 2 characters")
		.max(20, "First name must be at most 20 characters")
		.matches(/^[A-Za-z]+$/i, "Please enter valid first name"),
	lastname: yup
		.string()
		.required("Please enter Last Name")
		.min(2, "Last name must be at least 2 characters")
		.max(20, "Last name must be at most 20 characters")
		.matches(/^[A-Za-z]+$/i, "Please enter valid last name"),
	email: yup
		.string()
		.required("Please enter your email address")
		.matches(
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			"Please use the correct email"
		),
	businessName: yup
		.string()
		.required("Please enter Business Name")
		.min(2, "Business name must be at least 2 characters")
		.max(20, "Business name must be at most 20 characters"),
	// .matches(/^[A-Za-z]+$/i, "Please enter valid Business name"),
	primaryContactPerson: yup
		.string()
		.required("Please enter Primary Contact Person")
		.min(2, "Primary contact person must be at least 2 characters")
		.max(20, "Primary contact person must be at most 20 characters")
		.matches(/^[A-Za-z]+$/i, "Please enter valid primary contact person"),
	companyRegNumber: yup
		.string()
		.required("Please enter Company Register Number")
		.matches(/^[0-9]*$/, "Company Register Number is not valid"),
	addressFirst: yup
		.string()
		.required("Please enter Address")
		.min(2, "Address must be at least 2 characters")
		.max(20, "Address must be at most 20 characters")
		.matches(/^[A-Za-z]+$/i, "Please enter valid Address"),
	addressSecond: yup
		.string(),
	city: yup
		.string()
		.required("Please enter City")
		.min(2, "City must be at least 2 characters")
		.max(20, "City must be at most 20 characters")
		.matches(/^[A-Za-z]+$/i, "Please enter valid City"),
	state: yup
		.string(),
	country: yup
		.string()
		.required("Please enter Country")
		.min(2, "Country must be at least 2 characters")
		.max(20, "Country must be at most 20 characters")
		.matches(/^[A-Za-z]+$/i, "Please enter valid Country"),
	phone: yup
		.string()
		.required("Please enter mobile")
		.matches(/^[0-9]*$/, "Phone number is not valid")
		.min(8)
		.max(10),
	zip: yup
		.string()
		.required("Please enter Zip")
		.matches(/^[0-9]*$/, "Zip code is not valid")
		.min(6)
		.max(6),
	comment: yup
		.string()
		.required("Please enter Comment")
		.matches(/^[A-Za-z]+$/i, "Comment is not valid")
		.min(2)
		.max(300),
});

const CardInput = () => {
	const { register, formState: { errors }, handleSubmit, reset } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	return (
		<div className='bank-inputs inputs-wrapper'>
			<div className="input-heading">Beneficiary Information</div>
			<div className="row">
				<div className="col-md-6 input-wrapper required">
					<label>Beneficiary Name</label>
					<div className="input-wrap">
						<input
							type="text"
							className="form-control"
							{...register("beneficiaryName")}
						/>
					</div>
					{errors.beneficiaryName && (
						<span className="error">
							{errors.beneficiaryName.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper required">
					<label>Beneficiary Account Number / IBAN</label>
					<div className="input-wrap">
						<input
							type="text"
							className="form-control"
							{...register("beneficiaryAcc")}
						/>
					</div>
					{errors.beneficiaryAcc && (
						<span className="error">
							{errors.beneficiaryAcc.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label margin-fix">address line 1</label>
					<div className="input-wrap">
						<input
							type="text"
							className="form-control"
							{...register("addressFirst")}
						/>
						<span className="input-info">Building number and Street</span>
					</div>
					{errors.addressFirst && (
						<span className="error">
							{errors.addressFirst.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper">
					<label className="input-label margin-fix">address line 2</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("addressSecond")}
							className="form-control"
						/>
						<span className="input-info">Room/Block/Apartments</span>
					</div>
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">City</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("city")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
					{errors.city && (
						<span className="error">
							{errors.city.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper">
					<label className="input-label">State / Region</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("state")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">Country</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("country")}
							className="form-control"
						/>
					</div>
					{errors.country && (
						<span className="error">
							{errors.country.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">ZIP / Postal Code</label>
					<div className="input-wrap">
						<input
							type="number"
							{...register("zip")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
					{errors.zip && (
						<span className="error">
							{errors.zip.message}
						</span>
					)}
				</div>
			</div>
			<div className="input-heading">Beneficiary Bank Information</div>
			<div className="row">
				<div className="col-md-6 input-wrapper required">
					<label>Beneficiary Bank Name</label>
					<div className="input-wrap">
						<input
							type="text"
							className="form-control"
							{...register("beneficiaryName")}
						/>
					</div>
					{errors.beneficiaryName && (
						<span className="error">
							{errors.beneficiaryName.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper">
					<label>Intermediary Bank</label>
					<div className="input-wrap">
						<input
							type="text"
							className="form-control"
							{...register("beneficiaryAcc")}
						/>
					</div>
					{errors.beneficiaryAcc && (
						<span className="error">
							{errors.beneficiaryAcc.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">address line 1</label>
					<div className="input-wrap">
						<input
							type="text"
							className="form-control"
							{...register("addressFirst")}
						/>
						<span className="input-info">Building number and Street</span>
					</div>
					{errors.addressFirst && (
						<span className="error">
							{errors.addressFirst.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper">
					<label className="input-label">address line 2</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("addressSecond")}
							className="form-control"
						/>
						<span className="input-info">Room/Block/Apartments</span>
					</div>
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">City</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("city")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
					{errors.city && (
						<span className="error">
							{errors.city.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper">
					<label className="input-label">State / Region</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("state")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">Country</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("country")}
							className="form-control"
						/>
					</div>
					{errors.country && (
						<span className="error">
							{errors.country.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">ZIP / Postal Code</label>
					<div className="input-wrap">
						<input
							type="number"
							{...register("zip")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
					{errors.zip && (
						<span className="error">
							{errors.zip.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper required">
					<label className="input-label">Swift Code</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("swiftCode")}
							className="form-control"
							autoComplete="off"
						/>
					</div>
					{errors.swiftCode && (
						<span className="error">
							{errors.swiftCode.message}
						</span>
					)}
				</div>
				<div className="col-md-6 input-wrapper">
					<label className="input-label">Sort Code</label>
					<div className="input-wrap">
						<input
							type="text"
							{...register("sortCode")}
							className="form-control"
						/>
						<span className="input-info">for US account holders</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CardInput

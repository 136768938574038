import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    portfolioDescription: yup
        .string()
        // .required("Please enter portfolio description")
        // .min(2, "Portfolio description must be at least 2 characters")
        // .max(500, "Portfolio description must be at most 500 characters")
        // .matches(/^[A-Za-z]+$/i, "Please enter valid portfolio description"),
        ,
    certificateDescription: yup
        .string()
        // .required("Please enter certificate description")
        // .min(2, "Certificate description must be at least 2 characters")
        // .max(500, "Certificate description must be at most 500 characters")
        // .matches(/^[A-Za-z]+$/i, "Please enter valid certificate description"),
});

const EditInfo = () => {
	const [file, setFile] = useState("");

	const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

	function handleUpload(event) {
		setFile(event.target.files[0]);
	}
	const onSubmit = (data) => {
        alert(JSON.stringify(data));
    };

	return (
		<div className='edit-info'>
			<form onSubmit={handleSubmit(onSubmit)}>
			    <div className="card">
				    <h4>Portfolio</h4>
				    <div className="input-block margin-fix">
				        <div className="input-wrapper required img-input-fix has-input-file">
                            <label className="">Upload Photo or Video</label>
                            <div className="row">
                                <div className="col-lg-9 input-wrapper">
                                    <div className="img-input-wrapper">
                                        <div className="img-input">
                                            <i className="icon-plus"></i>
                                            Upload
                                            <input type="file" onChange={handleUpload} />
                                        </div>
                                        <span className="img-info">Supported file formats: .jpg, .png (918 x 425), .mp4, .avi, .mov<br />The file size should not exceed 25 MB</span>
                                    </div>
                                </div>
                            </div>
                            {file && <ImageThumb image={file} />}
                        </div>
				    </div>
				    <div className="row input-block position-relative">
					<div className="col-md-12">
        		        <label className="input-label">Description</label>
        		        <div className={`input-wrap ${errors.portfolioDescription ? 'has-error' : ''}`}>
        		            <textarea
        		                type="text"
        		                className="form-control"
        		                {...register("portfolioDescription")}
                                placeholder='Brief description of the portfolio content'
								maxLength={500}
        		            />
        		        </div>
        		        {errors.portfolioDescription && (
        		            <span className="error">
        		                {errors.portfolioDescription.message}
        		            </span>
        		        )}
						<div className="word-limit">0 / 500</div>
        		    </div>
				    </div>
				    <div className="add-more">
				    	<Link to='/'><span className="text">Add another media</span></Link>
				    </div>
			    </div>
			    <div className="card">
				    <h4>Certifications & Licenses</h4>
				    <div className="input-block margin-fix">
					<div className="input-wrapper required img-input-fix has-input-file">
                        <label className="">Upload Certification or License</label>
                        <div className="row">
                            <div className="col-lg-9 input-wrapper">
                                <div className="img-input-wrapper">
                                    <div className="img-input">
                                        <i className="icon-plus"></i>
                                        Upload
                                        <input type="file" onChange={handleUpload} />
                                    </div>
                                    <span className="img-info">Supported file formats: .jpg, .png (918 x 425), .pdf<br />The file size should not exceed 25 MB</span>
                                </div>
                            </div>
                        </div>
                        {file && <ImageThumb image={file} />}
                	</div>
				    </div>
				    <div className="row input-block position-relative">
					<div className="col-md-12">
        		        <label className="input-label">Description</label>
        		        <div className={`input-wrap ${errors.certificateDescription ? 'has-error' : ''}`}>
        		            <textarea
        		                type="text"
        		                className="form-control"
        		                {...register("certificateDescription")}
                                placeholder='Brief description of the Certification or License'
								maxLength={500}
        		            />
        		        </div>
        		        {errors.certificateDescription && (
        		            <span className="error">
        		                {errors.certificateDescription.message}
        		            </span>
        		        )}
						<div className="word-limit">0 / 500</div>
        		    </div>
				    </div>
				    <div className="add-more">
				    	<Link to='/'><span className="text">Add another certification or license</span></Link>
				    </div>
			    </div>
                <div className="btn-wrap">
        		    <input className="secondary-btn" type="reset" value="cancel" />
        		    <input className="btn" type="submit" value="save" />
        		</div>
            </form>
		</div>
	)
}

const ImageThumb = ({ image }) => {
    return <div className='thumbnail-wrap'>
        <img src={URL.createObjectURL(image)} alt={image.name} />
    </div>
};

export default EditInfo

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import {
  reSendOtpRequestGoogle,
  sendOtpGoogleAction,
  verifyOtpGoogleSignUpAction,
} from "store/action/socialMediaAction";
// import "../pages/phNumberVerify.scss";
import './phNumberVerify.scss'

const PhoneNumberVerify = ({
  setPhNumberPopup,
  setMobileVerifed,
  phoneNumber,
  setPhoneNumber,
  googleEmailId,
}) => {
  const dispatch = useDispatch();
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpTimer, setOtpTimer] = useState(10);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  console.log("googleEmailId",googleEmailId)

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      setIsOtpSent(false);
      setOtp("");
      setErrorMessage("");
    }
  };

  useEffect(() => {
    let timer;
    if (isOtpSent && otpTimer > 0) {
      timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [isOtpSent, otpTimer]);

  const sendOtpRequest = async (phoneNumber) => {
    const payload = {
      phone: phoneNumber,
      countryCode: "+91",
      email:googleEmailId
    };

    sendOtpGoogleAction(payload,setPhNumberPopup,setPhoneNumber, dispatch);
  };

  const handleSendOtp = () => {
    if (phoneNumber.length === 10) {
      sendOtpRequest(phoneNumber);
      setIsOtpSent(true);
      setOtpTimer(10);
    }
  };

  const handleResendOtp = () => {
    const payload = {
      phone: phoneNumber,
      countryCode: "+91",
    };

    reSendOtpRequestGoogle(payload);
    setOtpTimer(10);
  };

  const handleOtpComplete = async (value) => {
    setOtp(value);
    verifyOtp(value);
  };

  const verifyOtp = async (otp) => {
    const payload = {
      phone: phoneNumber,
      countryCode: "+91",
      otp: otp,
      email:googleEmailId
    };
    if (otp) {
      verifyOtpGoogleSignUpAction(
        payload,
        setPhNumberPopup,
        setMobileVerifed,
        dispatch
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNumber.length !== 10) {
      setErrorMessage("Please enter a valid 10-digit phone number.");
    } else {
      setErrorMessage("");
    }
  };

  const closePhonePopupHandler = () => {
    setPhNumberPopup(false);
  };

  return (
    <div className="modal-backdrop">
      <div className="cancel-order cancel-order-block">
        <h3>Verify Phone Number</h3>
        <div onClick={closePhonePopupHandler} className="cross-icon">
          X
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row inputs-wrapper">
            <div className="col-12 input-block mobile-otp">
              <div className="input-wrapper required mobile-input">
                <label htmlFor="user-id" className="required-block">
                  Phone Number
                </label>
                <div className="input-wrap p-relative input-wrap-block">
                  <input
                    type="text"
                    className="form-control"
                    id="user-id"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                  {phoneNumber.length === 10 && !isOtpSent && (
                    <button
                      type="button"
                      className="send-button-otp"
                      onClick={handleSendOtp}
                    >
                      Send OTP
                    </button>
                  )}

                  {isOtpSent && otpTimer <= 0 && (
                    <div className="resend-otp">
                      <button
                        type="button"
                        className="send-button-otp"
                        onClick={handleResendOtp}
                      >
                        Resend OTP
                      </button>
                    </div>
                  )}

                  {errorMessage && (
                    <span className="error">{errorMessage}</span>
                  )}
                </div>
                {isOtpSent && (
                  <div className="otp-create-verify input-wrapper required otp-verfiy-block">
                    <label className="input-label text-normal input-label-block">
                      Verify Code
                    </label>
                    <div className="input-wrap security-input verfiy-number-box">
                      <ReactCodeInput
                        fields={4}
                        onComplete={handleOtpComplete}
                      />
                    </div>
                    {/* {otpTimer > 0 ? (
                      <div className="resend-otp">
                        <span>Resend OTP in {otpTimer} seconds</span>
                      </div>
                    ) : (
                      <div className="resend-otp">
                        <button
                          type="button"
                          className="send-button-otp"
                          onClick={handleResendOtp}
                        >
                          Resend OTP
                        </button>
                      </div>
                    )} */}

                    {otpTimer > 0 && (
                      <div className="resend-otp">
                        <span>Resend OTP in {otpTimer} seconds</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="btn-wrap">
            <input
              className="secondary-btn"
              type="reset"
              value="Cancel"
              onClick={closePhonePopupHandler}
            />
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default PhoneNumberVerify;

import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Alert = ({ position, delay, hideprogressbar, newonTop }) => {
  return (
    <div className="">
      <ToastContainer
        // className={'modal-backdrop'}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </div>
  );
};
const useNotify = () => toast;
export { Alert, useNotify };

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const navigate = useNavigate();
  const login = useSelector((state) => state.login);
  let checkIslogin = useSelector((state) => state.login);
  const getProfileInfo = useSelector((state) => state.profileView);

  const hanldeNav = () => {
    if (login.islogin) {
      return;
    } else {
    }
  };

  const hanldeNavigator = () => {
    if (login.islogin) {
      return;
    } else {
      setTimeout(() => navigate({ pathname: "/" }), 100);
    }
  };

  const isUser = () => {
    let user = true;
    if (
      getProfileInfo?.data?.[0]?.type==="Lab"
    ) {
      user = false;
    }
    return user;
  };

  return (
    <nav className="nav">
      <ul className="w-100">
        {/* {isUser() ? ( */}
        <>
          {/* {!checkIslogin.islogin ? ( */}
          {/* <li>
            <Link to="/customer-service">Customer Service</Link>
          </li> */}
          {/* ) : ( */}
          {/* <li>
              <Link to="#">Customer Service</Link>
            </li>
          )} */}
          {/* {!checkIslogin.islogin ? ( */}
          {isUser() && <li>
              <Link to="/best-seller">Best Sellers</Link>
        </li>}
          {/* ) : ( */}
          {/* <li>
              <Link to="/best-seller">Best Sellers</Link>
            </li>
          )} */}
          {/* {!checkIslogin.islogin ? (
            <li>
              <a href="/become-seller-form">Become a Seller</a>
            </li>
          ) : (
            <li>
              <a href="#">Become a Seller</a>
            </li>
          )} */}
        </>
        {/* ) : null} */}
      </ul>
    </nav>
  );
};

export default Navbar;

import * as types from "../actionType";

const initialState = [];

const wishlistUser = (state = initialState, action) => {
  switch (action.type) {
    case types.WISHLIST_USER:
      return action.payload;
    default:
      return state;
  }
};

const priceRange = (state = initialState, action) => {
  switch (action.type) {
    case types.RANGE_PRICE:
      return action.payload;
    default:
      return state;
  }
};

const promoCode = (state = initialState, action) => {
  switch (action.type) {
    case types.CODE:
      return action.payload;
    default:
      return state;
  }
};

export { wishlistUser, priceRange, promoCode };

import * as types from '../actionType'

const initialState = []

const sellerGetCategoryByUser = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_CATEGORY_INSERVICE_DETAIL:
            return action.payload
        default:
            return state
    }
}


export { sellerGetCategoryByUser }
import * as types from '../actionType'

const initialState = {}

const userToSellerConversation = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_CONVER:
            return action.payload
        default:
            return state
    }
}

const userToSellerConversationList = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_CONVER_LIST:
            return action.payload
        default:
            return state
    }
}

export { userToSellerConversation, userToSellerConversationList }
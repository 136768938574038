import * as types from "../actionType";

const initialState = [];

const location = (state = initialState, action) => {
  switch (action.type) {
    case types.COUNTRY_SELECT:
      return action.payload;
    default:
      return state;
  }
};

export default location;

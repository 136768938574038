import * as types from '../actionType'

const initialState = []

const userNotify = (state = initialState, action) => {
    switch (action.type) {
        case types.NOTIFICATION_USER:
            return action.payload
        default:
            return state
    }
}

const sellerNotify = (state = initialState, action) => {
    switch (action.type) {
        case types.NOTIFICATION_SELLER:
            return action.payload
        default:
            return state
    }
}

export { userNotify, sellerNotify };
import { combineReducers } from 'redux';
import Users from './User'
import { countryData, stateData, cityData, countryDataBank, stateDataBank, cityDataBank } from './countryList.reducer'
import login from './login.reducer';
import { profileView, advertiserView, advertiserEdit, userView } from './profile.reducer';
// import categoryData from './category.reducer';
import { sellerBillingMethod } from './sellingBillingMethod.reducer';
import { sellerServiceAdd } from './sellerServiceAdd.reducer';
import { sellerServiceCategory } from './sellerServiceAdd.reducer';
import { sellerServiceSubCategory } from './sellerServiceAdd.reducer';
import { categoryData, subCategoryData, finalCategoryData, homeSubCat, homeService } from './category.reducer';
import { languageDrop } from './language.reducer';
import { sellerUploadMedia } from './sellerServiceAdd.reducer';
import { newAddress } from './addAddress.reducer';
import { sellerData } from './sellerInfo.reduer';
import { forgetPass } from './forgetPassword.reducer';
import { newPassword } from './resetPas.reducer';
import { billingView } from './billing.reducer';
import { sellerServiceList } from './serviceList.reducer';
import { faqRedu } from './faqReducer'
import bannerShow from './showBanners.reducers';
import { homePage } from './home.reducer';
import { category } from './newcategory.reducer';
import { sellerServiceDetail } from './ServiceDetailReducer'
import { sellerProfileRedu } from './sellerProfileRedu'
import { userNotify, sellerNotify } from './notification.reducer';
import { cartDetail } from './cartDetails.reducer';
import { summary } from './ordersSummary.reducer';
import { orderPlaced } from './orderPlaced.reducer';
import { userToSeller } from './sellerChat.reducer';
import { userToSellerConversation, userToSellerConversationList } from './conversationSeller.reduer';
import { userAllMessage } from './userAllCount.reducer';
import { sellerToUserConversation, sellerToUserConversationList } from './conversationOfSeller.reducer';
import { userAllName } from './userAllCount.reducer';
import {loader} from './loader';
import {priceRange} from '../reducer/wishList.reducer'


import { cityBankDataReducer } from "./bankCity.reducer";
import { orderListConverstion } from "./sellerOrderlisting.reducer";
import { sellerOrderDetails } from "./sellerOrderdetails.reducer";
import { userOrderListing } from "./userOrderlisting.reducer";
import { sellerRaiseInvoice } from "./sellerRaiseinvoice.reducer";
import { sellerOrderCompleted } from "./sellerOrdercompleted.reducer";
import { sellerOrderCanceled } from "./sellerOrdercanceled.reducer";
import { userOrderDisputed } from "./userOrderdisputed.reducer";
import { sellerOrderRefund } from "./sellerOrderrefund.reducer";
import { sellerOrderRejected } from "./sellerOrderrejected.reducer";
import { userBookAgain } from "./userBookAgain.reducer";
import { sellerOrderStat } from "./sellerOrderstat.reducer";
import { wishlistUser } from "./wishList.reducer";
import { userOrderDetails } from "./userOrderdetails.reducer";
import { sellerGetCategoryByUser } from "./sellerGetcategoryByuser.reducer";
import { sellerSubcategoryById } from "./sellerGetSubcategoryByuser.reducer";
import { sellerInfoOtherServices } from "./sellerInfoOtherServiceList.reducer";

import { userAddressList } from "./userAddresslisting.reducer";
import { userAddAddress } from "./userAddaddress.reducer";
import { userDeleteAddress } from "./userDeleteaddress.reducer";
import { userEditAddress } from "./userEditaddress.reducer";
import { userUpdateAddress } from "./userUpdateaddress.reducer";
import location from "./location.reducer";
import { bestSellerReducer } from "./bestSeller.reducer";
import { userBillingReducer } from "./userBilling.reducer";

import sellerReviewReducer from "./sellerReview.reducer";
import otherServicesReducer from "./otherServices.reducer";
import recommendedServicesReducer from "./recommendedServices.reducer";
import { reviewEdit } from "./reviewEdit.reducer";

import { sellerAllName } from "./userAllCount.reducer";
import { sellerAllMessage } from "./userAllCount.reducer";

// import { sellerInvoice } from "./sellerProfileRedu";
import { promoCode } from "./wishList.reducer";



const rootReducer = combineReducers({
    sellerProfileRedu,
    sellerServiceDetail,
    countryData,
    stateData,
    cityData,
    users: Users,
    login,
    profileView,
    categoryData,
    sellerBillingMethod,
    sellerServiceAdd,
    sellerServiceCategory,
    sellerServiceSubCategory,
    newPassword,
    subCategoryData,
    finalCategoryData,
    languageDrop,
    sellerUploadMedia,
    newAddress,
    sellerData,
    forgetPass,
    countryDataBank,
    stateDataBank,
    cityDataBank,
    billingView,
    sellerServiceList,
    faqRedu,
    advertiserView,
    advertiserEdit,
    bannerShow,
    userView,
    homePage,
    category,
    homeSubCat,
    homeService,
    userNotify,
    sellerNotify,
    cartDetail,
    summary,
    orderPlaced,
    userToSeller,
    userToSellerConversation,
    userToSellerConversationList,
    userAllMessage,
    sellerToUserConversation,
    sellerToUserConversationList,
    userAllName,
    loader,
    priceRange,
  sellerAllName,
  sellerAllMessage,
  orderListConverstion,
  sellerOrderDetails,
  userOrderListing,
//   sellerInvoice,
  sellerRaiseInvoice,
  sellerOrderCompleted,
  sellerOrderCanceled,
  userOrderDisputed,
  sellerOrderRefund,
  sellerOrderRejected,
  userBookAgain,
  sellerOrderStat,
  wishlistUser,
  userOrderDetails,
  sellerGetCategoryByUser,
  sellerSubcategoryById,
  sellerInfoOtherServices,
  userAddressList,
  userAddAddress,
  userDeleteAddress,
  userEditAddress,
  userUpdateAddress,
  location,
  bestSellerReducer,
  userBillingData: userBillingReducer,
  promoCode,
  sellerReviews: sellerReviewReducer,
  otherServicesData: otherServicesReducer,
  recommendedServicesData: recommendedServicesReducer,
  cityBankDataReducer,
  reviewEdit,
});

export default rootReducer
import * as types from '../actionType'

const initialState = []

function removeList(state, payload) {
    let newState = [...state];
    newState = newState.filter(item => item._id !== payload.serviceId);
    return newState;
}

const sellerServiceList = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_SERVICE_LIST:
            return action.payload
        case types.READ_NOTIFY_SINGLE:
            return removeList(state, action.payload)
        default:
            return state
    }
}

export { sellerServiceList };
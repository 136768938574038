import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "assets/images/logo.png";
import location from "assets/images/location.png";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import { useWindowSize } from "hooks/window-size";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import OutsideClickHandler from "components/OutsideClickHandler";
import { viewProfile, checkAuth } from "store/action/profileAction";
import { advertiserProfile } from "store/action/advertiserProfileAction";
import { userProfile } from "store/action/userProfileAction";
import { getCategory } from "store/action/categoryAction";
import { cartView } from "store/action/sowCartDataAction";
import {
  totalMessageCount,
  totalMessageSeller,
} from "store/action/userTotalMessagesAction";
import Loader from "components/Loader/Loader";
import { categorySearch } from "store/action/globalSearchAction";
import { getServiceListing } from "store/action/getServiceAction";
import SellerProfile from "assets/images/listing-description-image.png";
// import { REACT_APP_GOOGLE_MAPS_KEY } from "components/Seller/constants/constants";
// import { REACT_APP_GOOGLE_MAPS_KEY } from "../../User/Category/constants/constants";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../../pages/User/Category/constants/constants";

import { geocode } from "react-geocode";
import { axiosInstanceWithUserToken } from "utils/Axios";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef();

  const ref1 = useRef();
  let checkIslogin = useSelector((state) => state.login);
  const [windowWidth] = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [listBlockDisplay, setListBlockDisplay] = useState("none");
  const getProfileInfo = useSelector((state) => state.profileView);
  const getServiceInfo = useSelector((state) => state.homeService);
  const loader = useSelector((state) => state.loader.loader);

  console.log("getProfileInfogetProfileInfo", getProfileInfo);

  const [isLoggedin, setIsLoggedin] = useState(true);
  const getAdvertiserInfo = useSelector((state) => state.advertiserView);
  const getUserInfo = useSelector((state) => state.userView);
  const categoryUser = useSelector((state) => state.category);

  const getCartInfo = useSelector((state) => state.cartDetail);
  const getMessageCount = useSelector((state) => state.userAllMessage);
  const getMessageCountSeller = useSelector((state) => state.sellerAllMessage);

  const [newSearch, setNewSearch] = useState([]);

  const [coordinates, setCoordinates] = useState(null);
  const [ipAddress, setIpAddress] = useState("");

  console.log("coordinates1111", coordinates);

  const [locationError, setLocationError] = useState("");

  let country_initial = localStorage.getItem("country_name");

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      dispatch(viewProfile());
      dispatch(advertiserProfile());
      dispatch(userProfile());
      dispatch(cartView());
      dispatch(totalMessageCount());
      dispatch(totalMessageSeller());
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      dispatch(checkAuth());
    }
  }, [params]);

  OutsideClickHandler(ref, () => {
    setTimeout(() => setShowMenu(false), 100);
  });

  OutsideClickHandler(ref1, () => {
    setTimeout(() => setListBlockDisplay("none"), 100);
  });

  const handleCategory = () => {
    if (isUser()) {
      setShowMenu(!showMenu);
      getCategory()(dispatch);
    }
  };

  const categoryNaviagationClasses = showMenu
    ? "navigation active"
    : "navigation";

  const menuBtnClasses = showMenu ? "menu-btn active" : "menu-btn";

  const headerClasses = isLoggedin ? "header logged-in" : "header";

  const submitHandler = (event) => {
    event.preventDefault();
  };

  const handleCategoryPage = (id) => {
    window.location.href = "/category";
  };

  const logoutUser = () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("keepMeLogin");
    localStorage.removeItem("lastLoginTime");
    window.location.href = "/";
    setTimeout(() => navigate({ pathname: "/" }), 500);
  };

  const handleNotification = () => {
    if (getProfileInfo.data[0].type == "Freelancer") {
      setTimeout(() => navigate({ pathname: "/seller-notification" }), 500);
    } else if (getProfileInfo.data[0].type == "User") {
      setTimeout(() => navigate({ pathname: "/user/notifications" }), 500);
    } else {
      setTimeout(() => navigate({ pathname: "/lab-notification" }), 500);
    }
  };

  const handleAccount = () => {
    if (getProfileInfo.data[0].type == "Lab") {
      setTimeout(() => navigate({ pathname: "/lab-dashboard" }), 500);
    } else if (getProfileInfo.data[0].type == "Advertiser") {
      setTimeout(
        () => navigate({ pathname: "/user/advertiser-dashboard" }),
        500
      );
    } else if (getProfileInfo.data[0].type == "User") {
      setTimeout(() => navigate({ pathname: "/user/dashboard" }), 500);
    } else {
      setTimeout(
        () => navigate({ pathname: "/seller-dashboard-company" }),
        500
      );
    }
  };

  const isUser = () => {
    let user = true;
    if (getProfileInfo?.data?.[0]?.type === "Lab") {
      user = false;
    }
    return user;
  };

  const showFilterList = (e) => {
    setSearchValue(e.target.value);
    let payload = {
      country: country_initial,
      string: e.target.value,
    };
    if (e.target.value.trim() !== "" && e.target.value.trim().length > 2) {
      setListBlockDisplay("block");
      categorySearch(payload, setNewSearch)(dispatch);
      var send = {
        pageno: 1,
        limit: 10,
      };
      getServiceListing(
        undefined,
        undefined,
        e.target.value,
        send,
        (callback) => {}
      )(dispatch);
    } else {
      setListBlockDisplay("none");
      return;
    }
  };

  const gotoServiceDetail = (
    e,
    catid,
    subcatid,
    serviceid,
    sellerid,
    labId,
    slug
  ) => {
    if (slug) {
      window.location.href = `/category/${catid}/${slug}`;
    } else {
      window.location.href = `/service-detail/${serviceid}/${labId}`;
    }
  };
  // =========================================== current location ============================================================

  let token =localStorage.getItem("user_token")

  useEffect(() => {
    const sendGeoLocation = async () => {
      let payload = {
        ipAddress: ipAddress,
        lat: coordinates?.lat,
        long: coordinates?.lng,
        userId: getProfileInfo?.data?.[0]?.userId
          ? getProfileInfo?.data?.[0]?.userId
          : "",
      };
  
      try {
        await axiosInstanceWithUserToken.post("/user_geo_location", payload);
        // setGeoLocation(response.data);
      } catch (err) {
        // setError(err);
        console.error("Error fetching geo-location:", err);
      }
    };
  
    if (getProfileInfo?.data?.[0]?.type === "User" && coordinates && ipAddress && token) {
      sendGeoLocation();
    }

    if(!token && coordinates && ipAddress){
      sendGeoLocation();
    }

  }, [getProfileInfo, coordinates, ipAddress ,token]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);


  useEffect(()=>{
    if (getProfileInfo?.data?.[0]?.type === "User" && token ) {
      console.log("No Current Location For User")
      cuurentLocationForUser()
    } 

    if(!token){
      console.log("No Current Location For NO token")
      cuurentLocationForUser()
    }

    if(getProfileInfo?.data?.[0]?.type === "Lab"){
      console.log("No Current Location For Lab")
    }
  },[getProfileInfo,token])

  const cuurentLocationForUser =()=>{
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
    script.async = true;
    script.onload = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCoordinates({ lat: latitude, lng: longitude });

            geocode({ lat: latitude, lng: longitude })
              .then((response) => {
                console.log("Geocoding result:", response);
              })
              .catch((error) => {
                console.error("Error geocoding:", error);
              });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }


 

  // =========================================== current location ============================================================

  return (
    <header className={headerClasses}>
      <div className="primary-header">
        <div className="container">
          {loader ? <Loader /> : null}
          <div className="logo">
            {isUser() ? (
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            ) : (
              <Link to="#">
                <img src={logo} alt="logo" />
              </Link>
            )}
          </div>

          <div className="search-wrapper" ref={ref1}>
            <form onSubmit={submitHandler}>
              <div className="input-wrap">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  // onChange={(e) => showFilterList(e)}
                  value={searchValue}
                  minLength={3}
                />
                <div className="btn-wrap">
                  <button type="submit" className="btn">
                    <i className="icon-search"></i>
                  </button>
                </div>
              </div>
            </form>

            {listBlockDisplay && (
              <div
                className={`searched-items_wrapper ${
                  listBlockDisplay === "block" ? "active" : ""
                }`}
                ref={ref}
              >
                {newSearch?.length === 0 ? (
                  <Scrollbars className="scroll_searched-items">
                    <div
                      className="global-searched-items"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #d3d3d3",
                        width: "100%",
                        position: "absolute",
                        zIndex: "9999",
                        display: listBlockDisplay,
                        top: "100%",
                        left: "0",
                        height: "300px",
                        overflow: "auto",
                      }}
                    >
                      {getServiceInfo?.data?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              gotoServiceDetail(
                                e,
                                item.categoryId,
                                item.subcategoryId,
                                item._id,
                                item.sellerId,
                                item.labId,
                                item?.slug
                              )
                            }
                          >
                            <img
                              src={
                                item?.categoryIcon
                                  ? item?.path + item?.categoryIcon
                                  : item?.coverImage
                                  ? item?.path + item?.coverImage
                                  : item?.packageCoverImage
                                  ? item?.path + item?.packageCoverImage
                                  : SellerProfile
                              }
                              alt="rating"
                              style={{ height: "40px", width: "40px" }}
                            />
                            <div style={{ marginLeft: "20px" }}>
                              <div
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#13171C",
                                  fontWeight: "700",
                                }}
                              >
                                {item.title}
                              </div>
                              {/* <div>
															Category :{" "}
															<strong style={{ color: "#666666" }}>
																{item.categoryName}
															</strong>
														</div>
														<div>
															Sub Category :{" "}
															<strong style={{ color: "#666666" }}>
																{item.subcategoryName}
															</strong>
														</div> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Scrollbars>
                ) : (
                  <Scrollbars className="scroll_searched-items">
                    <div
                      className="global-searched-items"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #d3d3d3",
                        width: "100%",
                        position: "absolute",
                        zIndex: "9999",
                        display: listBlockDisplay,
                        top: "100%",
                        left: "0",
                        height: "300px",
                        overflow: "auto",
                      }}
                    >
                      {newSearch?.data?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              gotoServiceDetail(
                                e,
                                item.categoryId,
                                item.subcategoryId,
                                item._id,
                                item.sellerId,
                                item.labId,
                                item?.slug
                              )
                            }
                          >
                            <img
                              src={
                                item?.categoryIcon
                                  ? newSearch?.categoryImagePath +
                                    item?.categoryIcon
                                  : item?.coverImage
                                  ? newSearch?.serviceImagePath +
                                    item?.coverImage
                                  : item?.packageCoverImage
                                  ? newSearch?.serviceImagePath +
                                    item?.packageCoverImage
                                  : SellerProfile
                              }
                              alt="rating"
                              style={{ height: "40px", width: "40px" }}
                            />
                            <div style={{ marginLeft: "20px" }}>
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  gotoServiceDetail(
                                    e,
                                    item.categoryId,
                                    item.subcategoryId,
                                    item.serviceId,
                                    item.sellerId,
                                    item.labId
                                  )
                                }
                              >
                                {item.title
                                  ? item.title
                                  : item?.name
                                  ? item?.name
                                  : item?.package_title}
                              </div>
                              {/* <div>
														Category :{" "}
														<strong style={{ color: "#666666" }}>
															{item.categoryName}
														</strong>
													</div>
													<div>
														Sub Category :{" "}
														<strong style={{ color: "#666666" }}>
															{item.subcategoryName}
														</strong>
													</div> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Scrollbars>
                )}
              </div>
            )}

            {/* {
							getProfileInfo?.data?.[0]?.type !== "Lab" &&
								getProfileInfo?.data?.[0]?.type !== "User" ? (
								<div
									className={`searched-items_wrapper ${listBlockDisplay === "block" ? "active" : ""
										}`}
									ref={ref}
								>
									{listBlockDisplay ? (
										<Scrollbars className="scroll_searched-items">
											<div
												className="global-searched-items"
												style={{
													backgroundColor: "#fff",
													border: "1px solid #d3d3d3",
													width: "100%",
													position: "absolute",
													zIndex: "9999",
													display: listBlockDisplay,
												}}
											>
												{getServiceInfo?.data?.map((item, i) => (
													<div
														key={i}
														style={{
															display: "flex",
															flexDirection: "row",
															padding: "10px",
															cursor: "pointer",
														}}
														onClick={(e) =>
															gotoServiceDetail(
																e,
																item.categoryId,
																item.subcategoryId,
																item.serviceId,
																item.sellerId
															)
														}
													>
														<img
															src={
																item?.serviceCover
																	? item?.path + item?.serviceCover
																	: SellerProfile
															}
															alt="rating"
															style={{ height: "40px", width: "40px" }}
														/>
														<div style={{ marginLeft: "20px" }}>
															<div
																style={{
																	fontSize: "1.2rem",
																	color: "#13171C",
																	fontWeight: "700",
																}}
															>
																{item.title}
															</div>
															<div>
																Category :{" "}
																<strong style={{ color: "#666666" }}>
																	{item.categoryName}
																</strong>
															</div>
															<div>
																Sub Category :{" "}
																<strong style={{ color: "#666666" }}>
																	{item.subcategoryName}
																</strong>
															</div>
														</div>
													</div>
												))}
											</div>
										</Scrollbars>
									) : null}
								</div>
							) : null
						} */}
          </div>
          <div className="header-right">
            {isUser() ? (
              <div className="cart-wrap">
                <Link to="/user/cart">
                  <i className="icon-cart"></i>
                  {getCartInfo?.length >= 1 ? (
                    <div className="item-counter">{getCartInfo?.length}</div>
                  ) : (
                    <div className="item-counter">0</div>
                  )}
                </Link>
              </div>
            ) : null}

            {checkIslogin.islogin &&
              getProfileInfo?.data?.[0]?.type === "Lab" && (
                <div className="notification-wrap">
                  <Link to="/lab/lab-messages">
                    <i className="icon-mail"></i>
                    <div className="item-counter">
                      {getMessageCountSeller ? (
                        <div>{getMessageCountSeller?.data} </div>
                      ) : (
                        <div className="item-counter">0</div>
                      )}
                    </div>
                  </Link>
                </div>
              )}

            {/* {
							checkIslogin.islogin ? (
								isUser() &&
									getProfileInfo?.data?.[0]?.type !== "Freelancer" &&
									getProfileInfo?.data?.[0]?.type !== "Company" ? (
									<div className="notification-wrap">
										<Link to="/user/messages">
											<i className="icon-mail"></i>
											{getMessageCount ? (
												<div className="item-counter">{getMessageCount.data}</div>
											) : (
												<div className="item-counter">
													0
												</div>
											)}
										</Link>
									</div>
								) : (
									null
									// getMessageCountSeller.data && <div className="notification-wrap">
									// 	<Link to="/seller/seller-messages">
									// 		<i className="icon-mail"></i>
									// 		<div className="item-counter">
									// 			{getMessageCountSeller.data}
									// 		</div>
									// 	</Link>
									// </div>
								)
							) : (
								<div className="notification-wrap">
									<Link to="#">
										<i className="icon-mail"></i>
										<div className="item-counter">0</div>
									</Link>
								</div>
							)
						} */}

            {checkIslogin.islogin ? (
              isUser() &&
              getProfileInfo?.data?.[0]?.type !== "Freelancer" &&
              getProfileInfo?.data?.[0]?.type !== "Company" ? (
                <div className="notification-wrap">
                  <Link to="/user/messages">
                    <i className="icon-mail"></i>
                    {getMessageCount ? (
                      <div className="item-counter">{getMessageCount.data}</div>
                    ) : (
                      <div className="item-counter">0</div>
                    )}
                  </Link>
                </div>
              ) : !isUser() &&
                getProfileInfo?.data?.[0]?.type === "Advertiser" ? (
                <div className="notification-wrap">
                  <Link to="/user/messages">
                    <i className="icon-mail"></i>
                    {getMessageCount && getMessageCount?.length > 0 ? (
                      <div className="item-counter">{getMessageCount.data}</div>
                    ) : (
                      <div className="item-counter">0</div>
                    )}
                  </Link>
                </div>
              ) : null
            ) : (
              <div className="notification-wrap">
                <Link to="#">
                  <i className="icon-mail"></i>
                  <div className="item-counter">0</div>
                </Link>
              </div>
            )}

            {!checkIslogin.islogin && isUser() ? (
              <div className="signin-btn">
                <Link to="/sign-in" className="btn">
                  Sign In
                </Link>
              </div>
            ) : null}

            {!checkIslogin.islogin && !isUser() ? (
              <div className="signin-btn">
                <Link to="#" className="btn">
                  Sign In
                </Link>
              </div>
            ) : null}

            {checkIslogin.islogin &&
              getProfileInfo?.data?.map((item, i) => (
                <div className="profile-dropdown" key={i}>
                  <DropdownButton align="end" title={item.firstName}>
                    <Dropdown.Item onClick={handleAccount}>
                      Your Account
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleNotification}>
                      Notifications
                    </Dropdown.Item>
                    {getProfileInfo?.data?.[0]?.type === "User" ? (
                      // <Dropdown.Item href="/wishlist" /*onClick={logoutUser}*/>
                      //   My Wishlist
                      // </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/wishlist">
                        My Wishlist
                      </Dropdown.Item>
                    ) : null}
                    <Dropdown.Item href="#" onClick={logoutUser}>
                      Sign Out
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="secondary-header">
        <div className="container">
          <div className="header-left">
            {
              // getProfileInfo?.data?.[0]?.type !== "Freelancer" && getProfileInfo?.data?.[0]?.type !== "Company" ?
              // {getProfileInfo?.data?.[0]?.type === "User" ?
              <button
                type="button"
                ref={ref}
                className={showMenu ? "menu-btn active" : "menu-btn"}
                onClick={handleCategory}
              >
                <div className="ham-burger">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
                <div className="text-wrap">All Categories</div>
              </button>
              // :
              // <button type='button' className={showMenu ? "menu-btn active" : "menu-btn"}>
              // 	<div className="ham-burger">
              // 		<span className="line"></span>
              // 		<span className="line"></span>
              // 		<span className="line"></span>
              // 	</div>
              // 	<div className="text-wrap">All Categories</div>
              // </button>
            }
          </div>
          {windowWidth > 767 && <Navbar />}
          <div className="nav">
            {/* <Link to='#'>
							<img src={location} alt="location" />
						</Link> */}
            <ul className="w-100">
              {getProfileInfo?.data?.[0]?.type !== "Lab" ? (
                <li>
                  <Link to="/register-Lab">Register as a Lab</Link>
                </li>
              ) : (
                <li>
                  <Link to="#">
                    Welcome {getProfileInfo?.data?.[0]?.firstName}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className={categoryNaviagationClasses}>
          <div className="container">
            {showMenu ? (
              <div ref={ref} className="menu-wrapper">
                <Scrollbars style={{ maxWidth: 360, height: "100%" }}>
                  {windowWidth < 767 && <Navbar />}
                  <ul className="category-list">
                    {categoryUser?.map((item) => (
                      <li key={item._id}>
                        <a href={`/category/${item._id}/${item.name}`}>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Scrollbars>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import queryString from "query-string";
import { resetPascode } from "store/action/resetPasswordAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter the password")
    .min(8, "Password length should be equal or greater than 8"),
  re_passwords: yup
    .string()
    .required("Please enter the password")
    .min(8, "Password length should be equal or greater than 8")
    .oneOf([yup.ref('password'), null], 'Confirm Password must be same as new password'),

});

const SignIn = (props) => {
  let btnRef = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let token = queryString.parse(location.search).token;
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowFirstPassword,setIsShowFirstPassword]=useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const togglePasswordHandler = () => {
    setIsShowPassword(!isShowPassword);
  };

  const toggleEyePasswordHandler=()=>{
    setIsShowFirstPassword(!isShowFirstPassword)
  }

  const inputType = isShowPassword ? "text" : "password";
  const togglePasswordBtnClasses = isShowPassword ? 'toggle-btn show' : 'toggle-btn hide';

  const inputTypeFirst = isShowFirstPassword ? "text" : "password";
  const firstTogglePasswordBtnClasses = isShowFirstPassword ? 'toggle-btn show' : 'toggle-btn hide';




  const onSubmit = (data) => {
    data.token = token;
    setIsLoading(false);
    resetPascode(data, navigate, callback => {
      if (callback) {
        setIsLoading(true);
      }
    })(dispatch);
  };


  return (
    <section className="sign-in reset-password">
      <ToastContainer
        // className={'modal-backdrop'}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="container">
        <div className="card">
          <h2>Set New Password</h2>
          <div className="reset-info">
            Your new password must be different to
            <br />
            previously used passwords.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-block">
              <label htmlFor="user-id">Password</label>
              <div className="input-wrap">
                <input
                  type={inputTypeFirst}
                  {...register("password")}
                  className="form-control"
                  autoComplete={false}
                />
                 <div className="toggle-wrap">
                  <button
                    className={firstTogglePasswordBtnClasses}
                    type="button"
                    onClick={toggleEyePasswordHandler}
                  >
                    <i className="icon-eye"></i>
                  </button>
                </div>
              </div>
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </div>
            {/* {!errors.password && <div className="password-hint">Must be at least 8 characters.</div>} */}
            <div className="input-block">
              <label htmlFor="password">Confirm password</label>
              <div className="input-wrap">
                <input
                  type={inputType}
                  className="form-control"
                  {...register("re_passwords")}
                />
                <div className="toggle-wrap">
                  <button
                    className={togglePasswordBtnClasses}
                    type="button"
                    onClick={togglePasswordHandler}
                  >
                    <i className="icon-eye"></i>
                  </button>
                </div>
              </div>
              {errors.re_passwords && (
                <span className="error">{errors.re_passwords.message}</span>
              )}
            </div>
            <div className="btn-wrap">
              <button
                type="submit"
                className="btn w-100"
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
        <div className="card text-center">
          <div className="login-wrap">
            <Link to="/sign-in" className="login-link">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;

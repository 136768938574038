import * as types from '../actionType'

const initialState = []

const sellerToUserConversation = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_CONVER:
            return action.payload
        default:
            return state
    }
}

const sellerToUserConversationList = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_CONVER_LIST:
            return action.payload
        default:
            return state
    }
}

export { sellerToUserConversation, sellerToUserConversationList }
import * as types from '../actionType'

const initialState = []

const sellerOrderCanceled = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_POST_CANCELED_ORDER:
            return action.payload
        default:
            return state
    }
}


export { sellerOrderCanceled }
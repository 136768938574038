import * as types from '../actionType'

const initialState = {
    loader:false
}

const loader = (state = initialState, action) => {
    switch (action.type) {
        case "LOADER":
            return {
                ...state, loader: true
            }
        case "LOADERSTOP":
            return {
                ...state, loader: false
            }
        default:
            return state
    }
}

export {loader};

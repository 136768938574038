import * as types from "../actionType";

const initialState = [];

function setWishList(state, payload) {
  let newState = [...state.data];
  newState = newState.map((item) => {
    if (item.serviceId === payload.data.serviceId) {
      return { ...item, wishlist: payload.type };
    } else {
      return { ...item };
    }
  });
  return { ...state, data: newState };
}

const userOrderListing = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_GET_ORDERS_LIST:
      return action.payload;
    case types.SET_WISHLIST_ORDER_LIST:
      return setWishList(state, action.payload);
    default:
      return state;
  }
};

export { userOrderListing };

import * as types from "../actionType";

const initialState = [];

export const reviewEdit = (state = initialState, action) => {
  switch (action.type) {
    case types.REVIEW_EDIT:
      return action.payload;
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import "./App.scss";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { UserRoute } from "./utils/PrivetRoutes";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Homepage from "pages/Homepage";
import BecomeSeller from "pages/BecomeSeller";
import BecomeSellerForm from "pages/BecomeSellerForm/BecomeSellerForm";
import SellerDashboardFreelancer from "pages/Seller/SellerDashboardFreelancer";
import SellerDashboardCompany from "pages/Seller/SellerDashboardCompany";
import SellerInfo from "pages/Seller/SellerInfo";

import SellerInfoEdit from "pages/Seller/SellerInfoEdit";
import UserOrderDetals from "pages/User/Orders/OrderDetails";

import SellerVerification from "pages/Seller/SellerVerification";
import EditSellerVerification from "pages/Seller/EditSellerVerification";
import SellerVerificationCompany from "pages/Seller/Company/SellerVerification";
import EditSellerVerificationCompany from "pages/Seller/Company/EditSellerVerification";
import OptionalInformation from "pages/Seller/OptionalInformation";
import EditOptionalInformation from "pages/Seller/EditOptionalInformation";
import SignIn from "pages/SignIn";
import SellerSignIn from "pages/SignIn";
import BillingMethod from "pages/Seller/Company/BillingMethod/BillingMethod";
import EditBillingMethod from "pages/Seller/Company/BillingMethod/EditMethod";
import Profile from "pages/Seller/Profile";
import ServiceDetail from "pages/Seller/ServiceDetail";
import OptionalInformation2 from "pages/Seller/Company/OptionalInformation";
import ServiceList from "pages/Seller/Company/Services/ServiceList";
import SellerMessages from "pages/Seller/Company/Messages/SellerMessages";
import EditOptionalInfo from "pages/Seller/Company/EditOptionalInfo";

import EditInfo from "components/Seller/EditInfo";
import AddService from "pages/Seller/Company/Services/AddService";
import ForgotPassword from "pages/ForgotPassword";
import CheckEmail from "pages/CheckEmail";
import ResetPassword from "pages/ResetPassword";
import PasswordResetSuccess from "pages/PasswordResetSuccess";
import CreateAccount from "pages/CreateAccount";
import CreateAdvertiserAccount from "pages/Advertiser/CreateAccount";
import SellerDialogue from "pages/Seller/Company/Messages/SellerDialogue";
import { Fade } from "react-bootstrap";
import Faq from "pages/Faq";
import PageNotFound from "pages/PageNotFound";
import CustomerService from "pages/CustomerService";
import RaiseInvoice from "pages/RaiseInvoice";
import UserProfile from "pages/User/Profile/Profile";
import LoginAndSecurity from "pages/User/LoginAndSecurity/LoginAndSecurity";
import MyAddresses from "pages/User/Addresses/MyAddresses";
import AddAddress from "pages/User/Addresses/AddAddress";
import Notifications from "pages/User/Notifications";
import Reviews from "pages/User/Reviews";
import SaveCard from "pages/User/Payments/SaveCard";
import EditSavedCard from "pages/User/Payments/EditSavedCard";
import Payment from "pages/User/Payments/Payment";
import Dashboard from "pages/User/Payments/Dashboard";
import EditProfile from "pages/User/Profile/EditProfile";
import EditLoginAndSecurity from "pages/User/LoginAndSecurity/EditLoginAndSecurity";
import AdvertiserDashboard from "pages/User/AdvertiserDashboard";
import Cart from "pages/User/Cart/Cart";
import Checkout from "pages/User/Cart/Checkout";
import Orders from "pages/User/Orders/Orders";
import Category from "pages/User/Category/Category";
import SubCategory from "pages/User/Category/SubCategory";
import Search from "pages/User/Search/Search";
// import Category from "pages/Category";
import { useDispatch, useSelector } from "react-redux";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import EditAdvertiserProfile from "pages/Advertiser/Dashboard/EditAdvertiserProfile";
import SellerAccountComplete from "pages/Seller/SellerAccountComplete";
import SellerLoginComplete from "pages/Seller/SellerLoginComplete";
import SellerNotification from "pages/Seller/SellerNotification";
import SuccessPayment from "pages/Payment/SuccessPayment";
import FailedPayment from "pages/Payment/FailedPayment";
import SellerOrders from "pages/Seller/SellerOrders/SellerOrders";
import UserMessages from "pages/User/Messages/UserMessages";
import UserDialogue from "pages/User/Messages/UserDialogue";
import ViewList from "pages/meCustomPagination/ViewList";
import VerifyEmail from "pages/VerifyEmail/VerifyEmail";
import ViewPackage from "pages/Package/ViewPackage";
import ServiceListPackage from "pages/Seller/Company/Services/ServiceListPackage";
import PackageService from "pages/Seller/Company/Services/PackageService";
import LabInformation from "components/Seller/LabInformation";
import LabProfile from "components/Seller/LabProfile";
import LoginAsAdmin from "pages/LoginAsAdmin";
import { autoLogoutAction } from "store/action/userLoginAction";
import { viewProfile } from "store/action/profileAction";
// import Map from 'pages/Map';
import TermsConditions from "pages/TermsConditions";
import BestSeller from "pages/BestSeller/BestSeller";
import Wishlist from "pages/Wishlist/Wishlist";
import UserBillingMethod from "pages/User/BillingMethod/UserBillingMethod";
import EditUserBillingMethod from "pages/User/BillingMethod/EditUserBillingMethod";
import EditAddress from "pages/User/Addresses/EditAddress";
import OrderDetails from "pages/Seller/SellerOrders/OrderDetails";
import AdvertiserEnquiry from "pages/Advertiser/AdvertiserEnquiry";
import VerifyEmailAdvertiser from "pages/Advertiser/VerifyEmailAdvertiser";
import EnquirySuccess from "pages/Advertiser/EnquirySuccess";
import UserTermsConditions from "pages/User/UserTermsConditions";
import Career from "pages/Career/Career";
import Sustainability from "pages/Sustainability/Sustainability";
import UserTransactionDetails from "pages/UserTransactionDetails";
import SellerPayoutDeatils from "pages/Seller/SellerOrders/SellerPayoutDeatils";
import AboutUS from "pages/AboutUs/AboutUS";

const App = () => {
  const login = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let token = localStorage.getItem("user_token");

  const getProfileInfo = useSelector((state) => state.profileView);

  //==============================================================================================================================================
  // const keepMeLogin = getProfileInfo.data?.[0].keep_me_signed_in;

  // const lastLoginTime = localStorage.getItem('lastLoginTime');

  // let keyLogin = parseInt(localStorage.getItem("keepMeLogin"))

  // let loginTime = localStorage.getItem('lastLoginTime'); // You need to set this during login

  // const checkLogoutConditions = () => {

  //   const currentTime = new Date().getTime();
  //   const endDate = new Date(currentTime);

  //   const year = endDate.getFullYear();
  //   const month = endDate.getMonth() + 1; // Note: Month is 0-indexed, so add 1 to get the correct month
  //   const day = endDate.getDate();
  //   const hours = endDate.getHours();
  //   const minutes = endDate.getMinutes();
  //   const seconds = endDate.getSeconds();

  //   const currentTimeForLogout = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;

  //   // let timePeriod = 30 * 24 * 60 * 60 * 1000;

  //   // keyLogin && currentTimeForLogout > loginTime + 1 * 60 * 1000 for one minute

  //   if (keyLogin === 1 && currentTimeForLogout > loginTime + 30 * 24 * 60 * 60 * 1000) {

  //     // Logout after 30 days if keyLogin is '1'
  //     localStorage.removeItem('user_token');
  //     localStorage.removeItem('keepMeLogin');
  //     localStorage.removeItem('lastLoginTime')

  //     setTimeout(() => { viewProfile()(dispatch) }, 500)
  //     navigate('/');
  //     window.location.reload('/')

  //   } else if (keyLogin === 0 || currentTimeForLogout > loginTime + 24 * 60 * 60 * 1000) {

  //     localStorage.removeItem('user_token');
  //     localStorage.removeItem('keepMeLogin');
  //     localStorage.removeItem('lastLoginTime')

  //     setTimeout(() => { viewProfile()(dispatch) }, 500)
  //     navigate('/');
  //     window.location.reload('/')
  //   }
  // }

  // useEffect(() => {
  //   setInterval(checkLogoutConditions, 60 * 60 * 1000);

  // }, [keyLogin, loginTime])

  //==============================================================================================================================================

  //------------------------------------------------------------------------------------------------------------------------------------

  function checkLogout() {
    let keepMeLogin = localStorage.getItem("keepMeLogin");

    // Check if keepMeLogin is set and equals "1"
    if (keepMeLogin === "1") {
      let thirtyDays = 30 * 24 * 60 * 60 * 1000;

      let currentTime = new Date();
      let loginTime = new Date(localStorage.getItem("lastLoginTime"));

      // Calculate the time difference in milliseconds
      let timeDifference = currentTime - loginTime;

      // Compare the time difference with the one-minute threshold
      if (timeDifference > thirtyDays) {
        localStorage.removeItem("user_token");
        localStorage.removeItem("keepMeLogin");
        localStorage.removeItem("lastLoginTime");

        setTimeout(() => {
          viewProfile()(dispatch);
        }, 500);
        navigate("/");
        window.location.reload("/");
      }
    } else if (keepMeLogin === "0") {
      let oneDay = 24 * 60 * 60 * 1000;

      let currentTime = new Date();
      let loginTime = new Date(localStorage.getItem("lastLoginTime"));

      // Calculate the time difference in milliseconds
      let timeDifference = currentTime - loginTime;

      // Compare the time difference with the one-minute threshold
      if (timeDifference > oneDay) {
        localStorage.removeItem("user_token");
        localStorage.removeItem("keepMeLogin");
        localStorage.removeItem("lastLoginTime");

        setTimeout(() => {
          viewProfile()(dispatch);
        }, 500);
        navigate("/");
        window.location.reload("/");
      }
    }
  }

  // Check every 10 seconds
  setInterval(checkLogout, 24 * 60 * 60 * 1000);

  //------------------------------------------------------------------------------------------------------------------------------------

  const isUser = () => {
    let user = true;
    if (getProfileInfo?.data?.[0]?.type === "Lab") {
      user = false;
    }
    return user;
  };

  return (
    <div className="App">
      <Header />
      <Routes>
        {/* <Route
          path="/"
          element={
            isUser() ? <Homepage /> : <Navigate replace to="/lab-dashboard" />
          }
        />
        <Route path="/become-lab/verify" element={<SellerLoginComplete />} />
        <Route path="/lab-sign" index element={<SellerSignIn />} />
        <Route path="/sign-in" index element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/reset-password/success"
          element={<PasswordResetSuccess />}
        />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route
          path="/user-terms-conditions"
          element={<UserTermsConditions />}
        />
        <Route
          path="/advertiser/create-account"
          element={<CreateAdvertiserAccount />}
        />

        <Route path="/career" element={<Career />} />
        <Route path="/about-us" element={<AboutUS />} />

        <Route path="/sustainability" element={<Sustainability />} />

        <Route path="/register-Lab" element={<BecomeSeller />} />

        <Route path="/advertiser-enquiry" element={<AdvertiserEnquiry />} />
        <Route
          path="/advertiser-enquiry-success"
          element={<EnquirySuccess />}
        />
        <Route
          path="verify-email-advertiser"
          element={<VerifyEmailAdvertiser />}
        />

        <Route
          path="/seller-dashboard-freelancer"
          element={<SellerDashboardFreelancer />}
        />
        <Route path="/lab-dashboard" element={<SellerDashboardCompany />} />
        <Route path="/lab-verification" element={<SellerVerification />} />


        <Route
          path="/lab/lab-verification/edit"
          element={<EditSellerVerificationCompany />}
        />

        <Route path="/lab-messages" element={<SellerMessages />} />

        <Route
          path="/lab/billing-method"
          element={
            login.islogin || token ? (
              <BillingMethod />
            ) : (
              <Navigate replace to="/lab-sign" />
            )
          }
        />

        <Route
          path="/lab/billing-method/edit"
          element={
            login.islogin || token ? (
              <EditBillingMethod />
            ) : (
              <Navigate replace to="/lab-sign" />
            )
          }
        />

        <Route
          path="/user/billing-method"
          element={
            login.islogin || token ? (
              <UserBillingMethod />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/billing-method/edit"
          element={
            login.islogin || token ? (
              <EditUserBillingMethod />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />


        <Route path="/terms-and-conditions" element={<TermsConditions />} />


        <Route
          path="/seller/optional-info"
          element={
            login.islogin || token ? (
              <OptionalInformation />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route
          path="/seller/optional-info/edit"
          element={<EditOptionalInformation />}
        />
        <Route
          path="/lab/company/lab-verification"
          element={
            login.islogin || token ? (
              <SellerVerificationCompany />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route
          path="/lab/company/optional-info/edit"
          element={<EditOptionalInfo />}
        />
        <Route path="/lab-profile/:sid/:cid" element={<Profile />} />
        <Route path="/service-detail/:id/:sid" element={<ServiceDetail />} />
        <Route
          path="/seller/company/optional-info"
          element={<OptionalInformation2 />}
        />
        <Route path="/list_lab_test" exact element={<ServiceList />} />
        <Route
          path="/list_lab_package"
          exact
          element={<ServiceListPackage />}
        />

        <Route path="/lab/test-list/add/:id" element={<AddService />} />
        <Route path="/lab/test-list/add" element={<AddService />} />

        <Route path="/lab/package-list/edit/:id" element={<PackageService />} />
        <Route path="/lab/package-list/add" element={<PackageService />} />

        <Route path="/view-package" element={<ViewPackage />} />

        <Route path="/verify-email" element={<VerifyEmail />} />

        <Route path="/lab/company/lab-messages" element={<SellerMessages />} />
        <Route
          path="/seller/edit-optional-info"
          element={<EditOptionalInfo />}
        />
        <Route
          path="/lab/messages/opened/:conid"
          element={<SellerDialogue />}
        />
        <Route path="/lab-information" element={<SellerInfo />} />
        <Route path="/lab-information-edit/:cid" element={<SellerInfoEdit />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/customer-service" element={<CustomerService />} />
        <Route path="/raise-invoice" element={<RaiseInvoice />} />
        <Route path="/lab-notification" element={<SellerNotification />} />
        <Route path="/lab/orders" element={<Orders />} />
        <Route path="/best-seller" element={<BestSeller />} />

        {<Route path="/category/:cid/:name" element={<SubCategory />} />}
        <Route path="/search" element={<Search />} />
        <Route
          path="/user/dashboard"
          element={
            login.islogin || token ? (
              <Dashboard />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/profile"
          element={
            login.islogin || token ? (
              <UserProfile />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/profile/edit"
          element={
            login.islogin || token ? (
              <EditProfile />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/login-and-security"
          element={
            login.islogin || token ? (
              <LoginAndSecurity />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/login-and-security/edit"
          element={
            login.islogin || token ? (
              <EditLoginAndSecurity />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/my-addresses"
          element={
            login.islogin || token ? (
              <MyAddresses />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/my-addresses/add"
          element={
            login.islogin || token ? (
              <AddAddress />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/my-addresses/edit/:adid"
          element={
            login.islogin || token ? (
              <EditAddress />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route
          path="/lab/order-details/:orderid"
          element={
            login.islogin || token ? (
              <OrderDetails />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route
          path="user/order-details/:orderid"
          element={<UserOrderDetals />}
        />
        <Route
          path="/user/notifications"
          element={
            login.islogin || token ? (
              <Notifications />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route path="/user/reviews" element={<Reviews />} />
        <Route path="/user/payment" element={<Payment />} />
        <Route path="/user/save-card" element={<SaveCard />} />
        <Route path="/user/save-card/edit" element={<EditSavedCard />} />

        <Route
          path="/user/advertiser-dashboard"
          element={
            login.islogin || token ? (
              <AdvertiserDashboard />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/user/advertiser-dashboard/edit"
          element={
            login.islogin || token ? (
              <EditAdvertiserProfile />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route path="/user/cart" element={<Cart />} />
        <Route path="/user/checkout" element={<Checkout />} />
        <Route path="/user/your-orders" element={<Orders />} />

        <Route path="/user-transaction-details" element={<UserTransactionDetails />}/>

        <Route
          path="/user/payment-success"
          element={
            login.islogin || token ? (
              <SuccessPayment />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route
          path="/user/payment-failed"
          element={
            login.islogin || token ? (
              <FailedPayment />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />
        <Route
          path="/become-seller/success"
          element={<SellerAccountComplete />}
        />
        <Route path="/lab-orders" element={<SellerOrders />} />
        <Route path="/user/messages" element={<UserMessages />} />
        <Route path="/user/messages/opened/:conid" element={<UserDialogue />} />

        <Route path='/lab-payout-details' element={<SellerPayoutDeatils />} />

        <Route
          path="/lab/order-details/:orderid"
          element={
            login.islogin || token ? (
              <OrderDetails />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        />

        <Route
          // path="/seller/seller-messages"
          path="/lab/lab-messages"
          element={
            login.islogin || token ? (
              <SellerMessages />
            ) : (
              <Navigate replace to="/lab-sign" />
            )
          }
        />

        <Route path="/lab-prfile-information" element={<LabProfile />} />

        <Route path="/customPagination" element={<ViewList />} />

        <Route path="*" index element={<PageNotFound />} />
        <Route path="/login-as-admin/:id" element={<LoginAsAdmin />} />
        <Route
          path="/wishlist"
          element={
            login.islogin || token ? (
              <Wishlist />
            ) : (
              <Navigate replace to="/sign-in" />
            )
          }
        /> */}
        <Route path="*" index element={<PageNotFound />} />
        <Route path="/" index element={<AboutUS />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;

// Autologout
// let loginTime = localStorage.getItem('lastLoginTime');
// loginTime = loginTime ? parseInt(loginTime, 10) : 0; // Convert to numeric value or set to 0 if null

// const checkLogoutConditions = () => {
//   const currentTime = new Date().getTime();

//   // Compare numeric values instead of formatted string
//   if (keyLogin === 1 && currentTime > loginTime + 30 * 24 * 60 * 60 * 1000) {
//     // Logout after 30 days if keyLogin is '1'
//     handleLogout();
//   } else if (keyLogin === 0 || currentTime > loginTime + 24 * 60 * 60 * 1000) {
//     handleLogout();
//   }
// };

// const handleLogout = () => {
//   localStorage.removeItem('user_token');
//   localStorage.removeItem('keepMeLogin');
//   localStorage.removeItem('lastLoginTime');
//   setTimeout(() => { viewProfile()(dispatch) }, 500);
//   navigate('/');
//   window.location.reload('/');
// };

// useEffect(() => {
//   setInterval(checkLogoutConditions, 60 * 60 * 1000);
// }, [keyLogin, loginTime]);

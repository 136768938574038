import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { matchPasswordFor } from 'store/action/becomeSellerFromAction';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const schema = yup.object().shape({
	password: yup.string().required()
		.required("Please enter the password")
		.min(8, "Password length should be greater than 8"),
})

const SellerLoginComplete = () => {
	const navigate = useNavigate();
	let dispatch = useDispatch();

	const [showPassword, setShowPassword] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema)
	});

	const showPasswordHandler = () => {
		setShowPassword(prev => !prev);
	}
	const onSubmit = (data) => {
		matchPasswordFor(data, callback => {
			if (callback) {
				setTimeout(() => navigate("/register-Lab"), 1000)
			}

		})(dispatch)
	};
	return (
		<div className='seller-login-complete'>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>
			<div className="container">
				<div className="card">
					<p><strong>Dear Lab,  Welcome to Aladyyn!!</strong></p>
					<p>Please enter your password provided by our team below to continue and complete your registration process.</p>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={`input-wrap ${errors.password ? 'contains-error' : ''}`}>
							<input placeholder='Password' type={showPassword ? 'text' : 'password'} className='form-control' {...register('password')} />
							{<i className={`icon-eye ${!showPassword ? 'open' : ''}`} onClick={showPasswordHandler}></i>}
							{errors.password && <span className="error">{errors.password.message}</span>}
						</div>
						<div className="btn-wrap">
							<button type="submit" className='btn'>submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default SellerLoginComplete
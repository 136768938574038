import * as types from '../actionType'

const initialState = []

const userOrderDetails = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_GET_ORDERS_DETAILS:
            return action.payload
        default:
            return state
    }
}


export { userOrderDetails }
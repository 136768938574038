import * as types from '../actionType'

const initialState = []

const countryData = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_COUNTRY_DATA:
            return action.payload
        default:
            return state
    }
}


const stateData = (state = [], action) => {
    switch (action.type) {
        case types.SET_STATE_DATA:
            return action.payload
        default:
            return state
    }
}


const cityData = (state = [], action) => {
    switch (action.type) {
        case types.SET_CITY_DATA:
            return action.payload
        default:
            return state
    }
}



const countryDataBank = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_COUNTRY_BANK_DATA:
            return action.payload
        default:
            return state
    }
}


const stateDataBank = (state = [], action) => {
    switch (action.type) {
        case types.SET_STATE_BANK_DATA:
            return action.payload
        default:
            return state
    }
}


const cityDataBank = (state = [], action) => {
    switch (action.type) {
        case types.SET_CITY_BANK_DATA:
            return action.payload
        default:
            return state
    }
}

export  {countryData, stateData, cityData, countryDataBank, stateDataBank, cityDataBank};
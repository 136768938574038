import React, { useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countryList } from "store/action/countryList";
import { useDispatch, useSelector } from "react-redux";
import { userCodeSend } from "store/action/userCodeAction";
import ReactCodeInput from "react-verification-code-input";
import { userCodeVerify } from "store/action/userOptVerifyAction";
import { userEditAction } from "store/action/userEditAction";
import { userProfile } from "store/action/userProfileAction";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  password: yup.string().required("Please enter the password").min(8, "Password length should be equal or greater than 8"),
  re_password: yup.string().oneOf([yup.ref('password'), null], 'Confirm Password must be same as New Password'),
});

const EditLoginAndSecurity = () => {
  let navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("");
  const dispatch = useDispatch();
  const getCountry = useSelector((state) => state.countryData);
  const [isOTPWrong, setIsOTPWrong] = useState(false);
  const [phone, setPhone] = useState({ value: "" });
  const [phoneFieldDisabled, setPhoneFieldDisabled] = useState(false);
  const [sendOTPEnable, setSendOTPEnable] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [enableInst, setEnableInst] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [isPhoneVerify, setIsPhoneVerify] = useState(true);
  const [otpValue, setOtpValue] = useState();
  const getProfileInfo = useSelector((state) => state.profileView);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [resendEnable, setResendEnable] = useState(false);
  console.log("getProfileInfo",getProfileInfo)

  useEffect(() => {
    countryList()(dispatch);
  }, [dispatch]);

  const { register, formState: { errors }, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isPhoneVerify) {
      setResendEnable(true);
    }
  }, [isPhoneVerify]);

  useEffect(() => {
    if (seconds === 0) {
      setShowTimer(false);
      setSeconds(null);
      if (`${getProfileInfo?.data?.[0]?.phone}` !== `${phone.value}`) {
        setResendEnable(true);
      }
    }
    if (!seconds) return;
    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [seconds, getProfileInfo, phone.value]);

  useEffect(() => {
    if (phone.value?.length === 10) {
      setSendOTPEnable(true);
    } else {
      setSendOTPEnable(false);
    }
  }, [phone]);

  useEffect(() => {
    if (getProfileInfo.data) {
      setIsPhoneVerify(getProfileInfo.data[0]?.phoneNumberVerified);
      setPhone({ value: getProfileInfo.data[0]?.phone });
    }
  }, [getProfileInfo]);

  const sendOtp = () => {
    const payload = {
      phone: phone.value,
      countryCode: "+91",
    };
    userCodeSend(payload, toast, setSendOTPEnable, setShowTimer, setIsOtp, () => setSeconds(25), setEnableInst)(dispatch);
  };

  const verifyOtp = (val) => {
    setOtpValue(val);
    let payload = {
      phone: phone.value,
      otp: val,
    };
    userCodeVerify(payload, toast, setPhoneFieldDisabled, setSendOTPEnable, setShowTimer, setResendEnable, resendEnable, setIsOtp, setSubmitDisable, setEnableInst, setIsPhoneVerify)(dispatch);
  };

  const mobHandler = (event) => {
    setIsPhoneVerify(false);
    let value = event.target.value;
    if (`${getProfileInfo?.data?.[0]?.phone}` === `${event.target.value}`) {
      setSubmitDisable(false);
      setResendEnable(false);
      setIsOtp(false);
      setSeconds(null);
    } else {
      setSubmitDisable(true);
    }
    value = value.replaceAll(' ', '');
    setPhone({ value: value.length <= 10 && !isNaN(Number(value)) ? value : phone.value });
    setResendEnable(false);
  };

  const onSubmit = (data) => {
    if (!isPhoneVerify) {
      toast.error("please verify your phone number");
    } else {
      const updatedData = { ...data, phone: phone.value };
      userEditAction(updatedData)(dispatch);
      setIsFormSubmitting(true);
      setTimeout(() => setIsFormSubmitting(false), 3500);
      setTimeout(() => navigate("/user/dashboard"), 3000);
    }
  };

  const goHome = () => {
    navigate("/user/dashboard");
  };

  const changePage = () => {
    navigate("/user/login-and-security");
  };

  return (
    <section className="login-security edit">
      <div className="container">
      <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <Breadcrumb>
          <Breadcrumb.Item onClick={goHome}>User Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={changePage}>
            Login & Security
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Login & Security</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Edit Login & Security</h1>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="account-info edit">
            <div className="card account-card inputs-wrapper">
              <div className="info-heading">Account Information</div>
              <div className="row input-block">
                <div className="col-md-6 input-wrapper">
                  <label className="input-label">Password</label>
                  <div className={`input-wrap password ${errors.password ? "has-error" : ""}`}>
                    <input
                      type={!passwordType ? "password" : "text"}
                      {...register("password")}
                      className="form-control"
                      placeholder="**********"
                    />
                    <div className="toggle-password" onClick={() => setPasswordType((prev) => !prev)}>
                      <i className={`icon-eye ${!passwordType ? "" : "open"}`}></i>
                    </div>
                  </div>
                  {errors.password && (
                    <span className="error">{errors.password.message}</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper">
                  <label className="input-label">Confirm Password</label>
                  <div className={`input-wrap password ${errors.re_password ? "has-error" : ""}`}>
                    <input
                      type={!confirmPasswordType ? "password" : "text"}
                      {...register("re_password")}
                      className="form-control"
                      placeholder="**********"
                    />
                    <div className="toggle-password" onClick={() => setConfirmPasswordType((prev) => !prev)}>
                      <i className={`icon-eye ${!confirmPasswordType ? "" : "open"}`}></i>
                    </div>
                  </div>
                  {errors.re_password && (
                    <span className="error">{errors.re_password.message}</span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-4 edit-user-secure">
                  <div className="input-wrapper required">
                    <label className="input-label required">Phone Number for verification</label>
                    <div className={`input-wrap ${isOTPWrong ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        onChange={mobHandler}
                        className="form-control"
                        placeholder="Phone"
                        disabled={phoneFieldDisabled}
                        value={phone.value}
                      />
                      <div className="btn-wrap otp-text">
                        {isPhoneVerify || `${getProfileInfo?.data?.[0]?.phone}` === `${phone.value}` ? (
                          <div className="verify-btn">Verified</div>
                        ) : ""}
                        {`${getProfileInfo?.data?.[0]?.phone}` !== `${phone.value}` && !resendEnable && !showTimer && sendOTPEnable && phone.value?.length === 10 && (
                          <button onClick={sendOtp} type="button">Send OTP</button>
                        )}
                        {showTimer && <div>Time remaining: {seconds} seconds</div>}
                        {!isPhoneVerify && !showTimer && resendEnable && phone.value?.length === 10 && (
                          <button onClick={sendOtp} type="button">Resend OTP</button>
                        )}
                      </div>
                    </div>
                    {enableInst && (
                      <div className='number-sent'>
                        <span className='number-detail'>We have sent you a four-digit code. Please enter it in the next input to confirm the number</span>
                      </div>
                    )}
                    {errors.phone && (
                      <span className="error">{errors.phone.message}</span>
                    )}
                  </div>
                </div>
                {isOtp && (
                  <div className="col-md-6 col-lg-4 input-wrapper required">
                    <label className="input-label text-normal">Verification Code</label>
                    <div className="input-wrap security-input">
                      <ReactCodeInput fields={4} onComplete={verifyOtp} />
                    </div>
                    {errors.phone && (
                      <span className="error">{errors.phone.message}</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="btns-wrap">
            <button className="secondary-btn" type="button" onClick={changePage}>Cancel</button>
            <input className="btn" type="submit" value="save" disabled={!isPhoneVerify || submitDisable} />
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditLoginAndSecurity;

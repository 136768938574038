import * as types from '../actionType'

const initialState = []

export const orderPlaced = (state = initialState, action) => {
    switch (action.type) {
        case types.NOTIFICATION_USER:
            return action.payload
        default:
            return state
    }
}
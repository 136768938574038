import * as types from '../actionType'

const initialState = []

const orderListConverstion = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_GET_ORDERS_LIST:
            return action.payload
        default:
            return state
    }
}


export { orderListConverstion }
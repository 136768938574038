import React, { useState, useEffect } from "react";
import itemImg from "assets/images/item-img.jpg";
import { Link } from "react-router-dom";
import { cartView } from "store/action/sowCartDataAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeCartListing } from "store/action/removeCartAction";
import { updateCartListing } from "store/action/removeCartAction";
import { Alert } from "components/Alert/Alert";
import { orderBooking } from "store/action/bookOrderAction";
import emptyCart from "assets/images/emptyCart.png";
import Loader from "components/Loader/Loader";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import LoaderWithText from "components/Loader/LoaderWithText";
import { ToastContainer, toast } from "react-toastify";
import ConfirmBox from "components/Alert/ConfirmBox/ConfirmBox";

const cartData = JSON.parse(localStorage.getItem("ala_cart"));

const Cart = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const getCartInfo = useSelector((state) => state.cartDetail);
  const [ids, setIds] = useState([]);
  let checkIslogin = useSelector((state) => state.login);
  const [isloader, setIsLoader] = useState(true);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [localCart, setLocalCart] = useState([]);

  const [localCartTotal, setLocalCartTotal] = useState(0);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();

  // const [showTotalCost, setShowTotalCost] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  let cart = JSON.parse(localStorage.getItem("ala_cart"));

  useEffect(() => {
    setLocalCart(
      JSON.parse(localStorage.getItem("ala_cart"))?.length
        ? JSON.parse(localStorage.getItem("ala_cart"))
        : []
    );
  }, []);

  useEffect(() => {
    const body = document.getElementById("body");
    if (showConfirmBox) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }
  }, [showConfirmBox]);

  const handleAddQuantity = (item) => {
    let country_initial = localStorage.getItem("country_name");
    let cart = JSON.parse(localStorage.getItem("ala_cart"));
    if (cart) {
      cart = cart.map((newitem, i) => {
        if (newitem.serviceId === item.serviceId) {
          return { ...newitem, quantity: item.quantity + 1 };
        } else {
          return newitem;
        }
      });
      localStorage.setItem("ala_cart", JSON.stringify(cart));
      
      setTimeout(
        () =>
          cartView({ country: country_initial }, (callback) => {
            setIsLoader(false);
            setIsSkeleton(true);
          })(dispatch),
        100
      );
    } else {
      var q = item.quantity;
      if (item.fromInvoice === true) {
        if (q < 3) {
          q = item.quantity + 1;
        } else {
          q = item.quantity;
        }
      } else {
        q = item.quantity + 1;
      }
      let payload = {
        cartId: item._id,
        quantity: q,
      };
      setIsLoader(true);
      updateCartListing(payload, (callback) => {
        setIsLoader(false);
        setIsSkeleton(true);
      })(dispatch);
      setTimeout(
        () =>
          cartView({ country: country_initial }, (callback) => {
            setIsLoader(false);
            setIsSkeleton(true);
          })(dispatch),
        150
      );
    }
  };

  const handleSubtractQuantity = (item) => {
    let country_initial = localStorage.getItem("country_name");
    if (item.quantity > 1) {
      let cart = JSON.parse(localStorage.getItem("ala_cart"));
      if (cart) {
        cart = cart.map((newitem, i) => {
          if (newitem.serviceId === item.serviceId) {
            return { ...newitem, quantity: item.quantity - 1 };
          } else {
            return newitem;
          }
        });
        localStorage.setItem("ala_cart", JSON.stringify(cart));
        setTimeout(
          () =>
            cartView({ country: country_initial }, (callback) => {
              setIsLoader(false);
              setIsSkeleton(true);
            })(dispatch),
          100
        );
      } else {
        let payload = {
          cartId: item._id,
          quantity: item.quantity - 1,
        };
        setIsLoader(true);
        updateCartListing(payload, (callback) => {
          setIsLoader(false);
          setIsSkeleton(true);
        })(dispatch);
        setTimeout(
          () =>
            cartView({ country: country_initial }, (callback) => {
              setIsLoader(false);
              setIsSkeleton(true);
            })(dispatch),
          150
        );
      }
    }
  };

  useEffect(() => {
    let country_initial = localStorage.getItem("country_name");
    let payload = {
      country: country_initial,
    };
    setIsLoader(true)
    cartView(payload, (callback) => {
      setIsLoader(false);
      setIsSkeleton(true);
    })(dispatch);
  }, []);

  const deleteCartItemHandler = (item) => {
    setItemToDelete(item);
    setShowConfirmBox(true);
  };

  const deleteService = () => {
    let country_initial = localStorage.getItem("country_name");
    let cart = JSON.parse(localStorage.getItem("ala_cart"));

    let checkboxesAll = document.querySelectorAll("#cartBox");
    let selectAll = document.getElementById("selectAll");

    if (cart) {
      cart = cart.filter((newitem, i) => {
        if (newitem.serviceId !== itemToDelete.serviceId) {
          return { ...itemToDelete, quantity: itemToDelete.quantity - 1 };
        }
      });

      setSelectedItems([
        ...selectedItems.filter((service) => !ids.includes(service._id)),
      ]);
      localStorage.setItem("ala_cart", JSON.stringify(cart));
      setTimeout(() => cartView({ country: country_initial })(dispatch), 100);
      setSelectedItems([]);
      checkboxesAll.forEach((item) => (item.checked = false));
      selectAll.checked = false;
    } else {
      let payload = {
        cartId: [itemToDelete._id],
      };
      removeCartListing(payload)(dispatch);
      setItemToDelete();
      setSelectedItems([]);
      setIds([]);
      setTimeout(() => cartView({ country: country_initial })(dispatch), 300);
      checkboxesAll.forEach((item) => (item.checked = false));
      selectAll.checked = false;
    }
  };

  const getTotalCosts = (id) => {
    let total = 0;

    getCartInfo &&
      getCartInfo.forEach((item) => {
        if (ids?.length > 0) {
          if (ids.includes(item._id)) {
            total += item?.price * item?.quantity;
          }
        } else {
          total = 0;
        }
      });
    return total;

    // return getCartInfo?.reduce((total, list) => {
    //   if (ids.includes(list._id)) {
    //     return total + list?.price * list?.quantity;
    //   }
    // }, 0);
  };

  const handleSelectAll = (e) => {
    let newState = [...ids];
    let checkboxesAll = document.querySelectorAll("#cartBox");
    if (e.target.checked) {
      checkboxesAll.forEach((item) => {
        item.checked = true;
        newState.push(item.value);
      });
      setSelectedItems([...getCartInfo]);
    } else {
      checkboxesAll.forEach((item) => {
        item.checked = false;
        newState = [];
      });
      setSelectedItems([]);
    }
    setIds(newState);
  };

  const handleClick = (e) => {
    let newState = [...ids];
    let checkboxesAll = document.querySelectorAll("#cartBox");
    let all = document.getElementById("selectAll");

    // const cart = JSON.parse(localStorage.getItem("ala_cart"));

    if (e.target.checked) {
      newState.push(e.target.value);
      if (newState?.length === getCartInfo?.length) {
        all.checked = true;
      }

      setSelectedItems((prev) => {
        if (localCart.length > 0) {
          return [
            ...prev,
            ...getCartInfo.filter((item) => item.serviceId === e.target.value),
          ];
        } else {
          return [
            ...prev,
            ...getCartInfo.filter((item) => item._id === e.target.value),
          ];
        }
      });

      // if(Cart){
      //   setSelectedItems(prev => {
      //     return [...prev, ...cart.filter(item => item.serviceId === e.target.value)]
      //   })
      // }
    } else {
      newState = newState.filter((item) => item !== e.target.value);
      all.checked = false;
      if (localCart.length > 0) {
        setSelectedItems([
          ...selectedItems.filter((item) => item.serviceId !== e.target.value),
        ]);
      } else {
        setSelectedItems([
          ...selectedItems.filter((item) => item._id !== e.target.value),
        ]);
      }
      // if(cart){
      //   setSelectedItems([...selectedItems.filter((item) => item._id !== e.target.value)])
      // }
    }
    setIds(newState);
  };

  const bookOrder = (e) => {
    e.preventDefault();
    // let ids = getCartInfo?.map(key => key._id)
    if (ids.length === 0) {
      toast.error("Please select any one service");
      return;
    }
    if (checkIslogin.islogin) {
      let payload = {
        cartIds: ids,
      };
      orderBooking(payload)(dispatch);
      setTimeout(() => navigate("/user/checkout"), 50);
    } else {
      navigate("/sign-in");
    }
  };

  const costPerItem = () => {
    const price = selectedItems?.map((list) =>
      (
        getCartInfo?.filter((item) => item.serviceId === list.serviceId)[0]
          ?.price *
        getCartInfo?.filter((item) => item.serviceId === list.serviceId)[0]
          ?.quantity
      )?.toFixed(2)
    );
    let totalPrice = 0;

    price.forEach((item) => (totalPrice += +item));
    // setLocalCartTotal(totalPrice);
    return { price, totalPrice };
  };

  return (
    <section className="cart">
      {/* <ReactPlaceholder
        type="text"
        rows={77}
        ready={isSkeleton}
        showLoadingAnimation={true}
        customPlaceholder={<LoaderWithText />}
      >
        {
          // true ?
          isloader ? <Loader /> : null
        }
        <Alert />
        
      </ReactPlaceholder> */}
      <div className="container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        {getCartInfo?.length > 0 ? <h1>Cart ({getCartInfo?.length})</h1> : null}
        <form>
          {getCartInfo?.length > 0 ? (
            <div className="select-all">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="selectAll"
                  onClick={handleSelectAll}
                />
                <label className="form-check-label">Select All</label>
              </div>
            </div>
          ) : null}
          <div className="cart-wrap">
            <div className="left-block">
              {getCartInfo?.map((item, index) => (
                <div className="cart-item" key={index}>
                  <div className="checkbox-wrap">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        key={item._id}
                        type="checkbox"
                        id="cartBox"
                        value={localCart?.length ? item?.serviceId : item._id}
                        onClick={handleClick}
                      />
                    </div>
                  </div>

                  <div className="item-img mb-2 mb-md-0">
                    {localStorage.getItem("user_token") ? (
                      item?.serviceData?.[0]?.coverImage ? (
                        <img
                          src={item.path + item?.serviceData?.[0]?.coverImage}
                          alt="item"
                        />
                      ) : item?.serviceData?.[0]?.packageCoverImage ? (
                        <img
                          src={
                            item.path +
                            item?.serviceData?.[0]?.packageCoverImage
                          }
                          alt="item"
                        />
                      ) : (
                        <img src={itemImg} alt="item" />
                      )
                    ) : item?.coverImage ? (
                      <img src={item?.path + item?.coverImage} alt="item" />
                    ) : (
                      <img src={itemImg} alt="item" />
                    )}
                  </div>

                  {/* 
                    <div className="item-img mb-2 mb-md-0">
                      {item?.serviceData?.[0]?.coverImage ? (
                        <img
                         src={item.path + item?.serviceData?.[0]?.coverImage} 
                         alt="item" />
                      ) : (
                        item?.serviceData?.[0]?.packageCoverImage ? <img
                         src={item.path + item?.serviceData?.[0]?.packageCoverImage} alt="item" />:
                        <img
                         src={itemImg} 
                        alt="item" />
                      )}
                    </div> */}

                  <div className="item-right">
                    <div className="item-info">
                      <div className="item-name">
                        <Link to="#">
                          {item.serviceTitle
                            ? item.serviceTitle
                            : item?.serviceData?.[0]?.package_title}
                        </Link>
                      </div>
                      <div className="item-provider">
                        {item?.sellerData?.[0]?.firstName +
                          " " +
                          item?.sellerData?.[0]?.lastName}
                      </div>
                      {item?.pName && (
                        <div
                          className="item-provider mt-1"
                          style={{
                            fontSize: "15px",
                            display: "flex",
                            fontWeight: "400",
                            color: "#13171C",
                          }}
                        >
                          <span style={{ fontWeight: "700" }}>
                            Name: &nbsp;
                          </span>
                          {item?.pName}
                        </div>
                      )}

                   

                      <div
                        className="item-provider mt-1"
                        style={{
                          fontSize: "15px",
                          display: "flex",
                          fontWeight: "400",
                          color: "#13171C",
                        }}
                      >
                        <span style={{ fontWeight: "700"  }}>
                          Slot Timing: &nbsp;
                        </span>{" "}
                        {/* {item?.slotData?.slotData?.time} */}
                        {new Date(item?.slotDate).toLocaleDateString("en-US", {
                          timeZone: "UTC",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                        {" "}
                        {new Date(
                          "1970-01-01T" + item?.slotData?.slotData?.time + "Z"
                        ).toLocaleTimeString("en-US", {
                          timeZone: "UTC",
                          hour12: true,
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </div>
                      <div className="remove-btn">
                        <button
                          type="button"
                          // onClick={() => deleteService(item)}
                          onClick={() => deleteCartItemHandler(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M4.625 1.87354H4.5C4.56875 1.87354 4.625 1.81729 4.625 1.74854V1.87354H9.375V1.74854C9.375 1.81729 9.43125 1.87354 9.5 1.87354H9.375V2.99854H10.5V1.74854C10.5 1.19697 10.0516 0.748535 9.5 0.748535H4.5C3.94844 0.748535 3.5 1.19697 3.5 1.74854V2.99854H4.625V1.87354ZM12.5 2.99854H1.5C1.22344 2.99854 1 3.22197 1 3.49854V3.99854C1 4.06729 1.05625 4.12354 1.125 4.12354H2.06875L2.45469 12.2954C2.47969 12.8282 2.92031 13.2485 3.45313 13.2485H10.5469C11.0813 13.2485 11.5203 12.8298 11.5453 12.2954L11.9313 4.12354H12.875C12.9438 4.12354 13 4.06729 13 3.99854V3.49854C13 3.22197 12.7766 2.99854 12.5 2.99854ZM10.4266 12.1235H3.57344L3.19531 4.12354H10.8047L10.4266 12.1235Z"
                              fill="#9A9FA5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="item-price">
                      <div className="price-wrap">
                        {"₹"}
                        {item?.price}
                      </div>
                      <div className="quantity-wrap">
                        <div className="quantity-decrease">
                          <button
                            type="button"
                            onClick={() => handleSubtractQuantity(item)}
                          >
                            —
                          </button>
                        </div>
                        <div className="quantity">{item.quantity}</div>
                        <div className="quantity-increase">
                          <button
                            type="button"
                            onClick={() => handleAddQuantity(item)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {getCartInfo?.length ? (
              <div className="right-block">
                <div className="cart-heading">Total Cost</div>
                {selectedItems?.map((list, index) => (
                  <ul className="added-items" key={index}>
                    <li>
                      <div className="added-item">
                        <div className="item-name">
                          <Link to="#">
                            {list.serviceTitle
                              ? list.serviceTitle
                              : list?.serviceData?.[0]?.package_title}
                          </Link>
                        </div>
                        <div className="item-provider">
                          {list?.sellerData?.[0]?.firstName +
                            " " +
                            list?.sellerData?.[0]?.lastName}
                        </div>
                      </div>
                      <div className="item-price">
                        {list?.currency ? list?.currency : "₹"}
                        {/* {list?.price?.toFixed(2) * list.quantity} */ " "}
                        {localCart.length
                          ? costPerItem().price[index]
                          : (
                              getCartInfo?.filter(
                                (item) => item._id === list._id
                              )[0]?.price *
                              getCartInfo?.filter(
                                (item) => item._id === list._id
                              )[0]?.quantity
                            )?.toFixed(2)}
                      </div>
                    </li>
                  </ul>
                ))}

                <div className="total-wrap">
                  <div className="text-wrap">Total Price</div>
                  <div className="amount-wrap">
                    {getCartInfo?.[0]?.currency
                      ? getCartInfo?.[0]?.currency
                      : "₹"}
                    {/* {getTotalCosts()?.toFixed(2)} */}

                    {localCart.length
                      ? costPerItem().totalPrice?.toFixed(2)
                      : getTotalCosts()?.toFixed(2)}
                  </div>
                </div>
                {/* <div className="total-wrap">
                  <div className="text-wrap">Discounted Price</div>
                  <div className="amount-wrap">
                    {getCartInfo?.[0]?.currency
                      ? getCartInfo?.[0]?.currency
                      : "$"}
                    {getTotalCosts()?.toFixed(2)}
                  </div>
                </div>
                <div className="total-wrap">
                  <div className="text-wrap">To Pay</div>
                  <div className="amount-wrap">
                    {getCartInfo?.[0]?.currency
                      ? getCartInfo?.[0]?.currency
                      : "$"}
                    {getTotalCosts()?.toFixed(2)}
                  </div>
                </div> */}
                <div className="btn-wrap">
                  <button type="submit" className="btn" onClick={bookOrder}>
                    Book
                  </button>
                </div>
              </div>
            ) : (
              <div className="image-wrap">
                <img src={emptyCart}></img>
              </div>
            )}
          </div>
        </form>
      </div>
      {showConfirmBox ? (
        <ConfirmBox
          confirmMsg={"Are you sure you want to delete this service?"}
          onConfirm={deleteService}
          onHidePopup={setShowConfirmBox}
        />
      ) : null}
    </section>
  );
};

export default Cart;

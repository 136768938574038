import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import CardInput from "./Card/CardInput";
// import BankInput from "./Bank/BankInput";
import { useForm } from "react-hook-form";
import { billingMethod } from "store/action/sellerBillingMethod";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import { countryData } from "store/action/countryDataAction";
import { stateDataList } from "store/action/stateDataAction";
import { cityData } from "store/action/cityDataAction";
import { userBillingViewAction } from "store/action/userBillingViewAction";
import { userBillingAction } from "store/action/userBillingAction";
import { ToastContainer } from "react-toastify";

const schema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(20, "Address must be at most 20 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup.string(),
  cityId: yup.string().required("Please Select City"),
  stateId: yup.string().required("Please Select State"),
  countryId: yup.string(),
  postcode: yup
    .string()
    .required("Please enter Zip")
    .matches(/^[0-9]*$/, "Zip code is not valid")
    .min(6)
    .max(7),
  bankAddressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(20, "Address must be at most 20 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  bankAddressLine2: yup.string(),
  bankCityId: yup.string().required("Please Select City"),
  // .required("Please enter City")
  // .min(2, "City must be at least 2 characters")
  // .max(20, "City must be at most 20 characters")
  // .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
  bankStateId: yup.string().required("Please Select State"),
  bankCountryId: yup.string(),
  bankPostcode: yup
    .string()
    .required("Please enter Zip")
    .matches(/^[0-9]*$/, "Zip code is not valid")
    .min(6,"Please enter valid zip")
    .max(7),
  beneficiaryName: yup
    .string()
    .required("Please enter Beneficiary Name")
    .matches(/^[A-Za-z ]+$/i, "Beneficiary Name is not valid")
    .min(2)
    .max(20),
  beneficiaryAccountNumber: yup
    .string()
    .required("Please enter Beneficiary Account")
    .matches(/^[0-9]*$/, "Beneficiary Account is not valid")
    .min(2)
    .max(20),
  beneficiaryBankName: yup
    .string()
    .required("Please enter Beneficiary Bank Name")
    .matches(/^[A-Za-z ]+$/i, "Beneficiary Bank Name is not valid")
    .min(2)
    .max(20),
  intermediaryBank: yup.string(),
  // .required("Please enter Intermediary Bank")
  // .matches(/^[A-Za-z]+$/i, "Intermediary Bank is not valid")
  // .min(2)
  // .max(20),
  swiftCode: yup
  .string()
  // .required("Please Enter 11 Alphabets And Numbers")
  .required(" Please enter a valid Swift Code / IFSC Code")
  // .matches(/^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/, "Swift Code is not valid"),
  .matches(/^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/, "Please enter a valid Swift Code / IFSC Code"),
  // .min(2, "Please Enter atleast 2 characters")
  // .max(11, "please Enter 11 Alphabets And Numbers"),
  // sortCode: yup.string().matches(/^[0-9]*$/, "Sort Code is not valid"),
});

const EditBankDetails = () => {
  const navigate = useNavigate();
  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const getCity = useSelector((state) => state.cityData);
  const getCountryBank = useSelector((state) => state.countryDataBank);
  const getStateBank = useSelector((state) => state?.stateDataBank);
  const getCityBank = useSelector((state) => state.cityDataBank);
  
  const login = useSelector((state) => state.login);
  const [isCardInputShowing, setIsCardInputShowing] = useState(true);
  const [isBankInputShowing, setIsBankInputShowing] = useState(false);
  const [countryname, setCountryname] = useState("");
  const [countrynameBank, setCountrynameBank] = useState("");
  const getBillingInfo = useSelector((state) => state.userBillingData);
  const [getSecState, setGetSecState] = useState([]);
  const user = useSelector((state) => state.profileView);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [ifscError, setIfscError] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    userBillingViewAction()(dispatch);
    countryList()(dispatch);
    countryData()(dispatch);
  }, []);

  useEffect(() => {
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
  }, []);

  useEffect(() => {
    let bankData = getBillingInfo?.data?.filter(
      (item) => item.billingType === "Bank Account"
    )
      ? getBillingInfo?.data?.filter(
          (item) => item.billingType === "Bank Account"
        )
      : [];
    if (bankData.length > 0) {
      stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" },dispatch);
      cityList({
        countryId: bankData?.[0].countryId,
        stateId: bankData?.[0].stateId,
      })(dispatch);
      stateDataList({ countryId: "61c2fb83dc7c0d455ba5e68d" })(dispatch);
      cityData({
        countryId:"61c2fb83dc7c0d455ba5e68d",
        stateId: bankData?.[0].bankStateId,
      })(dispatch);

      // stateDataList({ bankCountryId: getBillingInfo?.data?.countryId })(dispatch);
      // cityData({ bankCountryId: getBillingInfo?.data?.countryId, bankStateId: getBillingInfo?.data?.stateId })(dispatch);
      setGetSecState([
        { _id: bankData?.[0].bankStateId, name: bankData?.[0].bankStateName },
      ]);

      setTimeout(() => {
        setValue("addressLine1", bankData?.[0].addressLine1);
        setValue("addressLine2", bankData?.[0].addressLine2);
        setValue("bankAddressLine1", bankData?.[0].bankAddressLine1);
        setValue("bankAddressLine2", bankData?.[0].bankAddressLine2);
        setValue("stateId", bankData?.[0].stateId);
        setValue("cityId", bankData?.[0].cityId);
        setValue("countryId", "61c2fb83dc7c0d455ba5e68d");
        setValue("bankPostcode", bankData?.[0].bankPostcode);
        setValue("postcode", bankData?.[0].postcode);
        setValue("sortCode", bankData?.[0].sortCode);
        setValue("swiftCode", bankData?.[0].swiftCode);
        setValue("intermediaryBank", bankData?.[0]?.intermediaryBank);
        setValue("beneficiaryName", bankData?.[0].beneficiaryName);
        setValue(
          "beneficiaryAccountNumber",
          bankData?.[0].beneficiaryAccountNumber
        );
        setValue("beneficiaryBankName", bankData?.[0].beneficiaryBankName);
        setValue("bankCountryId", "61c2fb83dc7c0d455ba5e68d");
        setValue("bankStateId", bankData?.[0].bankStateId);
        setValue("bankCityId", bankData?.[0].bankCityId);
        setValue("postcode", bankData?.[0].postcode);
      }, 1000);
    }
  }, [getBillingInfo]);

  const changeHandler = () => {
    navigate("/user/billing-method");
  };

  const getStateHandlerBank = (e) => {
    let vals = e.target.value;
    setCountrynameBank(vals);
    stateDataList({ countryId: vals }, (callback) => {
      // setGetSecState(callback);
    })(dispatch);
    setValue("bankCityId", "");
  };

  const getStateHandler = (e) => {
    let val = e.target.value;
    setCountryname(val);
    stateList({ countryId: val })(dispatch);
    setValue("cityId", "");
  };

  const getCityHandlerBank = (e) => {
    cityData({ countryId: "61c2fb83dc7c0d455ba5e68d", stateId: e.target.value })(dispatch);
  };

  const getCityHandler = (e) => {
    cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
  };
  const submitHandler = (data) => {
    setIsFormSubmitting(true);
    data.billingType = "Bank Account";
    let payload = new FormData();
    payload.append("billingType", data.billingType);
    payload.append("swiftCode", data.swiftCode);
    payload.append("bankAddressLine2", data.bankAddressLine2);
    payload.append("beneficiaryAccountNumber", data.beneficiaryAccountNumber);
    payload.append("stateId", data.stateId);
    payload.append("bankAddressLine1", data.bankAddressLine1);
    payload.append("countryId", "61c2fb83dc7c0d455ba5e68d");
    payload.append("addressLine1", data.addressLine1);
    payload.append("addressLine2", data.addressLine2);
    payload.append("cityId", data.cityId);
    payload.append("bankPostcode", data.bankPostcode);
    payload.append("beneficiaryName", data.beneficiaryName);
    payload.append("intermediaryBank", data?.intermediaryBank);
    payload.append("bankCountryId", "61c2fb83dc7c0d455ba5e68d");
    payload.append("bankStateId", data.bankStateId);
    payload.append("bankCityId", data.bankCityId);
    payload.append("beneficiaryBankName", data.beneficiaryBankName);
    payload.append("postcode", data.postcode);
    payload.append("sortCode", data.sortCode);
    userBillingAction(payload, () => {
      // setTimeout(() => setIsFormSubmitting(false), 3000);
      setTimeout(() => {
        setIsFormSubmitting(false)
        navigate("/user/billing-method")
      }, 3000);
    })(dispatch);
  };

  const bankHandlePostcodeChange = (e) => {
    const inputValue = e.target.value.toString(); // Convert to string
    const truncatedValue = inputValue.slice(0, 6); // Truncate to 6 digits
    e.target.value = truncatedValue; // Update the input value
  };

  const handlePostcodeChange = (e) => {
    const inputValue = e.target.value.toString(); // Convert to string
    const truncatedValue = inputValue.slice(0, 6); // Truncate to 6 digits
    e.target.value = truncatedValue; // Update the input value
  };

  const ifscHandler = (e) => {
    const inputValue = e.target.value.toString(); // Convert to string
    const truncatedValue = inputValue.slice(0, 11); // Truncate to 6 digits
    e.target.value = truncatedValue; // Update the input value

    if (inputValue.length < 11) {
      setIfscError(true);
    } else {
      setIfscError(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="bank-inputs inputs-wrapper">
        <div className="input-heading">Beneficiary Information</div>
        <div className="row">
          <div className="col-md-6 input-wrapper required">
            <label>Beneficiary Name</label>
            <div
              className={`input-wrap ${
                errors.beneficiaryName ? "has-error" : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                {...register("beneficiaryName")}
                placeholder="Beneficiary Name"
              />
            </div>
            {errors.beneficiaryName && (
              <span className="error">{errors.beneficiaryName.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper required">
            <label>Beneficiary Account Number / IBAN</label>
            <div
              className={`input-wrap ${
                errors.beneficiaryAccountNumber ? "has-error" : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                {...register("beneficiaryAccountNumber")}
                placeholder="Beneficiary Account Number / IBAN"
              />
            </div>
            {errors.beneficiaryAccountNumber && (
              <span className="error">
                {errors.beneficiaryAccountNumber.message}
              </span>
            )}
          </div>
          <div className="col-md-6 input-wrapper required">
            <label className="input-label margin-fix">address line 1</label>
            <div
              className={`input-wrap ${errors.addressLine1 ? "has-error" : ""}`}
            >
              <input
                type="text"
                className="form-control"
                {...register("addressLine1")}
                placeholder="Address line 1"
              />
              <span className="input-info">Building number and Street</span>
            </div>
            {errors.addressLine1 && (
              <span className="error">{errors.addressLine1.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper">
            <label className="input-label margin-fix">address line 2</label>
            <div className="input-wrap">
              <input
                type="text"
                {...register("addressLine2")}
                className="form-control"
                placeholder="Address line 2"
              />
              <span className="input-info">Room/Block/Apartments</span>
            </div>
          </div>
          {/* <div className="col-md-6 input-wrapper required">
            <label className="input-label">Country</label>
            <div className="input-wrap">
              <select
                {...register("countryId")}
                name="countryId"
                className="form-control drop-down"
                id="countryId"
                onChange={getStateHandler}
              >
                <option value={""} disabled selected>
                  Please select country
                </option>
                {getCountry?.map((item) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
            </div>
            {errors.countryId && (
              <span className="error">{errors.countryId.message}</span>
            )}
          </div> */}

          <div className="col-md-6 input-wrapper required">
            <label className="input-label">Country</label>
            <div
              className={`input-wrap ${errors.countryId ? "has-error" : ""}`}
            >
              <select className="form-control" id="countryId" disabled>
                <option value={""}>India</option>
              </select>
            </div>
          </div>

          <div className="col-md-6 input-wrapper required">
            <label className="input-label">State / Region</label>
            <div className={`input-wrap ${errors.stateId ? "has-error" : ""}`}>
              <select
                className="form-control drop-down"
                {...register("stateId")}
                name="stateId"
                onChange={getCityHandler}
              >
                <option value={""} disabled selected>
                  Please select state
                </option>
                {getState?.map((item) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
            </div>
            {errors.stateId && (
              <span className="error">{errors.stateId.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper">
            <label className="input-label">City</label>
            <div className={`input-wrap ${errors.cityId ? "has-error" : ""}`}>
              <select
                className="form-control drop-down"
                {...register("cityId")}
                name="cityId"
              >
                <option value={""}>Please select city</option>
                {getCity?.map((item) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
            </div>
            {errors.cityId && (
              <span className="error">{errors.cityId.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper required">
            <label className="input-label">ZIP / Postal Code</label>
            <div className={`input-wrap ${errors.postcode ? "has-error" : ""}`}>
              <input
                type="number"
                {...register("postcode", { onChange: handlePostcodeChange })}
                className="form-control"
                autoComplete="off"
                placeholder="ZIP / Postal Code"
              />
            </div>
            {errors.postcode && (
              <span className="error">{errors.postcode.message}</span>
            )}
          </div>
        </div>
        <div className="input-heading">Beneficiary Bank Information</div>
        <div className="row">
          <div className="col-md-6 input-wrapper required">
            <label>Beneficiary Bank Name</label>
            <div
              className={`input-wrap ${
                errors.beneficiaryBankName ? "has-error" : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                {...register("beneficiaryBankName")}
                placeholder="Beneficiary Bank Name"
              />
            </div>
            {errors.beneficiaryBankName && (
              <span className="error">
                {errors.beneficiaryBankName.message}
              </span>
            )}
          </div>
          <div className="col-md-6 input-wrapper">
            <label>Intermediary Bank</label>
            <div
              className={`input-wrap ${
                errors.intermediaryBank ? "has-error" : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                {...register("intermediaryBank")}
                placeholder="Intermediary Bank"
              />
            </div>
            {errors.intermediaryBank && (
              <span className="error">{errors.intermediaryBank.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper required">
            <label className="input-label">address line 1</label>
            <div
              className={`input-wrap ${
                errors.bankAddressLine1 ? "has-error" : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                {...register("bankAddressLine1")}
                placeholder="Address line 1"
              />
              <span className="input-info">Building number and Street</span>
            </div>
            {errors.bankAddressLine1 && (
              <span className="error">{errors.bankAddressLine1.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper">
            <label className="input-label">address line 2</label>
            <div className="input-wrap">
              <input
                type="text"
                {...register("bankAddressLine2")}
                className="form-control"
                placeholder="Address line 2"
              />
              <span className="input-info">Room/Block/Apartments</span>
            </div>
          </div>
          {/* <div className="col-md-6 input-wrapper required">
            <label className="input-label">Country</label>
            <div className="input-wrap">
              <select
                {...register("bankCountryId")}
                name="bankCountryId"
                className="form-control drop-down"
                id="bankCountryId"
                onChange={getStateHandlerBank}
              >
                <option value={""} disabled selected>
                  Please select country
                </option>
                {getCountryBank?.map((items) => (
                  <option value={items._id}>{items.name}</option>
                ))}
              </select>
            </div>
            {errors.bankCountryId && (
              <span className="error">{errors.bankCountryId.message}</span>
            )}
          </div> */}

          <div className="col-md-6 input-wrapper required">
            <label className="input-label">Country</label>
            <div
              className={`input-wrap ${errors.countryId ? "has-error" : ""}`}
            >
              <select className="form-control" id="countryId" disabled>
                <option value={""}>India</option>
              </select>
            </div>
          </div>

          <div className="col-md-6 input-wrapper required">
            <label className="input-label">State / Region</label>
            <div
              className={`input-wrap ${errors.bankStateId ? "has-error" : ""}`}
            >
              <select
                className="form-control drop-down"
                {...register("bankStateId", { onChange: getCityHandlerBank })}
                name="bankStateId"
                // onChange={getCityHandlerBank}
              >
                <option value={""} disabled selected>
                  Please select state
                </option>
                {getStateBank?.map((items) => (
                  <option value={items._id}>{items.name}</option>
                ))}
              </select>
            </div>
            {errors.bankStateId && (
              <span className="error">{errors.bankStateId.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper">
            <label className="input-label">City</label>
            <div
              className={`input-wrap ${errors.bankCityId ? "has-error" : ""}`}
            >
              <select
                className="form-control drop-down"
                {...register("bankCityId")}
                name="bankCityId"
              >
                <option value={""}>Please select country</option>
                {getCityBank?.map((items) => (
                  <option value={items._id}>{items.name}</option>
                ))}
              </select>
            </div>
            {errors.bankCityId && (
              <span className="error">{errors.bankCityId.message}</span>
            )}
          </div>
          <div className="col-md-6 input-wrapper required">
            <label className="input-label">ZIP / Postal Code</label>
            <div
              className={`input-wrap ${errors.bankPostcode ? "has-error" : ""}`}
            >
              <input
                type="number"
                {...register("bankPostcode", {
                  onChange: bankHandlePostcodeChange,
                })}
                className="form-control"
                autoComplete="off"
                placeholder="ZIP / Postal Code"
              />
            </div>
            {errors.bankPostcode && (
              <span className="error">{errors.bankPostcode.message}</span>
            )}
          </div>

          {/* <div className="col-md-6 input-wrapper required">
            <label className="input-label">Swift Code/IFSC</label>
            <div
              className={`input-wrap ${errors.swiftCode ? "has-error" : ""}`}
            >
              <input
                type="text"
                {...register("swiftCode")}
                className="form-control"
                autoComplete="off"
                placeholder="Swift Code"
              />
            </div>
            {errors.swiftCode && (
              <span className="error">{errors.swiftCode.message}</span>
            )}
          </div> */}

          <div className="col-md-6 input-wrapper required">
            <label className="input-label">Swift Code/IFSC</label>
            <div
              className={`input-wrap ${errors.swiftCode ? "has-error" : ""}`}
            >
              <input
                type="text"
                {...register("swiftCode", { onChange: ifscHandler })}
                className="form-control"
                autoComplete="off"
                placeholder="Swift Code"
                // onChange={ifscHandler}
              />
            </div>
            {errors.swiftCode && (
              <span className="error">{errors.swiftCode.message}</span>
            )}
            {ifscError && (
              <span className="error">
                Please enter a valid Swift Code / IFSC Code
              </span>
            )}
          </div>

          {/* <div className="col-md-6 input-wrapper">
            <label className="input-label">Sort Code</label>
            <div className={`input-wrap ${errors.sortCode ? "has-error" : ""}`}>
              <input
                type="text"
                {...register("sortCode")}
                className="form-control"
                placeholder="Sort Code"
              />
              <span className="input-info">for US account holders</span>
            </div>
            {errors.sortCode && (
              <span className="error">{errors.sortCode.message}</span>
            )}
          </div> */}
        </div>
      </div>
      <div className="btn-wrap">
        <input
          onClick={changeHandler}
          className="secondary-btn"
          type="reset"
          value="cancel"
        />
        <input
          className="btn"
          type="submit"
          value="save"
          disabled={isFormSubmitting ? true : false}
        />
      </div>
    </form>
  );
};

export default EditBankDetails;

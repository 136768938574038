import React, { useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-date-picker";
import ReactCodeInput from "react-verification-code-input";
import { useDispatch, useSelector } from "react-redux";
import {
  viewProfile,
  sellerVerificationCode,
  sellerVerifyCode,
} from "store/action/profileAction";
import { editAction } from "store/action/editProfileAction";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNotify } from "components/Alert/Alert";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .min(2, "First name must be at least 2 characters")
    .max(20, "First name must be at most 20 characters")
    .matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
  lastName: yup
    .string()
    .required("Please enter Last Name")
    .min(2, "Last name must be at least 2 characters")
    .max(20, "Last name must be at most 20 characters")
    .matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
  businessName: yup
    .string()
    .required("Please enter Business Name")
    .min(2, "Business name must be at least 2 characters")
    .max(20, "Business name must be at most 20 characters"),
  // .matches(/^[a-zA-Z0-9 ]+$/i, "Please enter valid business name"),
  primaryContactPerson: yup
    .string()
    .required("Please enter Primary Contact Person")
    .min(2, "Primary Contact Person must be at least 2 characters")
    .max(20, "Primary Contact Person must be at most 20 characters")
    .matches(/^[A-Za-z ]+$/i, "Please enter valid Primary Contact Person"),
  // regNumber: yup
  // 	.string()
  // 	.required("Please enter Registration Number")
  // 	.min(2, "Registration Number must be at least 2 characters")
  // 	.max(20, "Registration Number must be at most 20 characters")
  // 	.matches(/^[0-9]*$/, "Please enter valid Registration Number"),
  vat: yup.string(),
  // .required("Please enter VAT Name")
  // .min(2, "VAT name must be at least 2 characters")
  // .max(20, "VAT name must be at most 20 characters")
  // .matches(/^[0-9]*$/, "Please enter valid VAT number"),
  email: yup
    .string()
    .required("Please enter your email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Please use the correct email"
    ),
  // date: yup
  // 	.string()
  // 	.required("Please select your Birthday"),
  // businessName: yup
  // 	.string()
  // 	.required("Please enter Business Name")
  // 	.min(2, "Business name must be at least 2 characters")
  // 	.max(20, "Business name must be at most 20 characters")
  // 	.matches(/^[A-Za-z]+$/i, "Please enter valid Business name"),
  // primaryContactPerson: yup
  // 	.string(),
  // // .required("Please enter Primary Contact Person")
  // // .min(2, "Primary contact person must be at least 2 characters")
  // // .max(20, "Primary contact person must be at most 20 characters")
  // // .matches(/^[A-Za-z]+$/i, "Please enter valid primary contact person"),
  companyRegistrationNumber: yup.string(),
  // .required("Please Company Register Number"),
  // .matches(/^[0-9]*$/, "Company Register Number is not valid"),
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(50, "Address must be at most 20 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup.string(),
  cityId: yup.string(),
  // .required("Please enter City"),
  // .min(2, "City must be at least 2 characters")
  // .max(20, "City must be at most 20 characters")
  // .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
  stateId: yup.string().required("Please enter Country"),
  countryId: yup.string().required("Please enter Country"),
  // .min(2, "Country must be at least 2 characters")
  // .max(20, "Country must be at most 20 characters")
  // .matches(/^[A-Za-z]+$/i, "Please enter valid Country"),
  phone: yup
    .string()
    .required("Please enter mobile number")
    // .matches(/^[0-9]*$/, "Phone number is not valid")
    .min(7)
    .max(14),
  postcode: yup.string().required("Please enter Zip"),
  // .matches(/^[0-9]*$/, "Zip code is not valid")
  // .min(6)
  // .max(6),
  // comment: yup
  // 	.string()
  // 	.required("Please enter Comment")
  // 	.matches(/^[A-Za-z]+$/i, "Comment is not valid")
  // 	.min(2)
  // 	.max(300),
  password: yup.string(),
  // .required("Please enter the password")
  // .min(8, "Password length should be greater than 8"),
  re_password: yup
    .string()
    // .required("Please enter the password")
    // .min(8, "Password length should be greater than 8")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password must be same as Password"
    ),
});

const EditSellerVerification = () => {
  const toast = useNotify();

  const navigate = useNavigate();
  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const getCity = useSelector((state) => state.cityData);
  const dispatch = useDispatch();
  const [value, onChange] = useState(new Date());
  const [file, setFile] = React.useState("");
  const edit = useSelector((state) => state.profileEdit);
  const getProfileInfo = useSelector((state) => state.profileView);
  const [passwordType, setPasswordType] = useState("password");
  const [con_passwordType, setCon_passwordType] = useState("password");
  const [countryname, setCountryname] = useState("");
  const [isOTPWrong, setIsOTPWrong] = useState(false);
  const [mob, setMob] = useState("");
  const [issend, setIssend] = useState(false);
  const [isLoader,setIsloader]=useState(false)
  let history = useNavigate();

  //====================================Verify otp====================

  const [isOtp, setIsOtp] = useState(false);
  const [phone, setPhone] = useState({ value: "" });
  const [resendEnable, setResendEnable] = useState(false);

  const [phoneFieldDisabled, setPhoneFieldDisabled] = useState(false);
  const [sendOTPEnable, setSendOTPEnable] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [enableInst, setEnableInst] = useState(false);

  const [submitDisable, setSubmitDisable] = useState(false);

  const [isPhoneVerify, setIsPhoneVerify] = useState(true);

  const [otpValue, setOtpValue] = useState();

  const [oldNumber, setOldNumber] = useState();

  const [btnControl, setBtnControl] = useState(false);

  //====================================Verify otp====================

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  // useEffect(() => {
  // 	if (getProfileInfo?.data?.[0]?.phone === oldNumber && btnControl) {

  // 		setResendEnable(false)
  // 		setSendOTPEnable(false)
  // 		setIsOtp(false)

  // 	}
  // }, [oldNumber])

  useEffect(() => {
    if (getProfileInfo?.data?.[0]?.phoneNumberVerified === true) {
      setSubmitDisable(true);
    }
    setOldNumber(getProfileInfo?.data?.[0]?.phone);
  }, [getProfileInfo]);

  const mobHandler = (event) => {
    setIsPhoneVerify(false);

    let value = event.target.value;
    if (`${getProfileInfo?.data?.[0]?.phone}` === `${event.target.value}`) {
      setSubmitDisable(false);
      // setBtnControl(true)
      setResendEnable(false);
      // setSendOTPEnable(false)
      // setShowTimer(false)
      setIsOtp(false);

      setSeconds(null);
    } else {
      setSubmitDisable(true);

      // setSendOTPEnable(false)
      setShowTimer(false);
      // handleSeconds()
    }

    // if (`${getProfileInfo?.data?.[0]?.phone}` !== `${event.target.value}`){
    // 	setBtnControl(false)
    // }

    value = value.replaceAll(" ", "");
    setPhone(
      (phone) =>
        (value.length <= 10 && !isNaN(Number(value)) && { value }) || phone
    );
    setResendEnable(false);
  };

  const onSubmit = (data) => {
    let payload = new FormData();
    payload.append("type", "lab");
    payload.append("firstName", data.firstName);
    payload.append("password", data.password);
    payload.append("lastName", data.lastName);
    payload.append("stateId", data.stateId);
    payload.append("cityId", data.cityId);
    payload.append("primaryContactPerson", data.primaryContactPerson);
    payload.append("countryId", "61c2fb83dc7c0d455ba5e68d");
    payload.append("addressLine1", data.addressLine1);
    payload.append("addressLine2", data.addressLine2);
    payload.append("city", "");
    payload.append("vat", "");
    payload.append("email", data.email);
    payload.append("companyRegistrationNumber", data.companyRegistrationNumber);
    payload.append("businessName", data.businessName);
    payload.append("postcode", data.postcode);
    // payload.append("phone", mob);
    payload.append("phone", phone.value);
    payload.append("bookingType", data?.booking);

    if (isPhoneVerify || `${phone.value}` === `${oldNumber}`) {
      setIsloader(true)
      editAction(payload, data,setIsloader)(dispatch);
      setTimeout(
        // () => (window.location.href = "/seller/company/seller-verification"),
        // () => (window.location.href = "/lab-dashboard"),
        ()=>{
          navigate('/lab-dashboard')
      },
        4000
      );
    }
    // else {
    // 	alert("Please verify your mobile number first")
    // }
  };

  useEffect(() => {
    countryList()(dispatch);
  }, []);

  // const getStateHandler = (e) => {
  // 	let val = e.target.value;
  // 	setCountryname(val)
  // 	stateList({ countryId: val }, dispatch);
  // }

  useEffect(() => {
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
  }, []);

  const getCityHandler = (e) => {
    cityList({
      countryId: "61c2fb83dc7c0d455ba5e68d",
      stateId: e.target.value,
    })(dispatch);
  };

  const changeHandler = () => {
    navigate({ pathname: "/lab-verification" });
  };

  const changePage = () => {
    navigate({ pathname: "/lab-dashboard" });
  };

  useEffect(
    (data) => {
      if (getProfileInfo?.data?.length > 0) {
        stateList({ countryId: getProfileInfo.data[0].countryId }, dispatch);
        cityList({
          countryId: getProfileInfo.data[0].countryId,
          stateId: getProfileInfo.data[0].stateId,
        })(dispatch);
        setTimeout(() => {
          setValue("firstName", getProfileInfo.data[0].type);
          setValue("firstName", getProfileInfo.data[0].firstName);
          setValue("lastName", getProfileInfo.data[0].lastName);
          setValue("cityId", getProfileInfo.data[0].cityId);
          setValue("stateId", getProfileInfo.data[0].stateId);
          setValue("countryId", getProfileInfo.data[0].countryId);
          setValue("addressLine1", getProfileInfo.data[0].addressLine1);
          setValue("addressLine2", getProfileInfo.data[0].addressLine2);
          setValue(
            "primaryContactPerson",
            getProfileInfo.data[0].primaryContactPerson
          );
          setValue("vat", getProfileInfo.data[0].vat);
          setValue("email", getProfileInfo.data[0].email);
          setValue(
            "companyRegistrationNumber",
            getProfileInfo.data[0].companyRegistrationNumber
          );
          setValue("businessName", getProfileInfo.data[0].businessName);
          setValue("postcode", getProfileInfo.data[0].postcode);
          setValue("phone", getProfileInfo.data[0].phone);
          setValue("booking", getProfileInfo?.data[0]?.bookingType);
          setIsPhoneVerify(getProfileInfo.data[0].phoneNumberVerified);

          // setMob(getProfileInfo.data[0].phone);
          setPhone({ value: getProfileInfo.data[0].phone });
        }, 1000);
      }
    },
    [getProfileInfo]
  );

  useEffect(() => {
    if (isPhoneVerify) {
      setResendEnable(true);
    }
  }, [isPhoneVerify]);

  useEffect(() => {
    if (seconds === 0) {
      setShowTimer(false);

      if (`${oldNumber}` !== `${phone.value}`) {
        setResendEnable(true);
      }
      setSeconds(null);
    }

    // exit early when we reach 0
    if (!seconds) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // clear interval on re-render to avoid memory leaks
    // return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [seconds]);

  const handleSeconds = () => {
    setSeconds(25);
  };

  useEffect(() => {
    if (phone.value.length === 10) {
      setSendOTPEnable(true);
    } else {
      setSendOTPEnable(false);
    }
  }, [phone]);

  const sendOtp = () => {
    const payload = {
      phone: phone.value,
      countryCode: "+91",
    };

    sellerVerificationCode(
      payload,
      toast,
      setSendOTPEnable,
      setShowTimer,
      setIsOtp,
      handleSeconds,
      setEnableInst
    )(dispatch);
  };

  // const verifyOtp = (val) => {
  // 	sellerVerifyCode({ otp: val }, callback => {
  // 		if (callback) {
  // 			setIsOtp(false)
  // 			setIsOTPWrong(false)
  // 			setIsPhoneVerify(true)
  // 		} else {
  // 			setIsOTPWrong(true)
  // 		}
  // 	})(dispatch)
  // }

  const verifyOtp = (val) => {
    setOtpValue(val);

    let payload = {
      phone: phone.value,
      otp: val,
    };

    if (val !== otpValue) {
      sellerVerifyCode(
        payload,
        toast,
        setPhoneFieldDisabled,
        setSendOTPEnable,
        setShowTimer,
        setResendEnable,
        resendEnable,
        setIsOtp,
        setSubmitDisable,
        setEnableInst,
        setIsPhoneVerify
      )(dispatch);
    }
  };

  return (
    <section className="edit-verification become-seller">
      <div className="container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <Breadcrumb>
          <Breadcrumb.Item onClick={changePage}>Lab account</Breadcrumb.Item>
          {/* <Breadcrumb.Item href="/lab-verification">
            Verification & Security
          </Breadcrumb.Item> */}
          <Breadcrumb.Item>
            <Link to="/lab-verification">Verification & Security</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Verification & Security</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Edit Verification & Security</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card inputs-wrapper">
            <h4>Verification</h4>

            <div
              className="lab-radios radio-box-wrap"
              style={{ position: "relative" }}
            >
              Taking bookings :{/* <label for="online">Online</label> */}
              <div className="radio-btn-wrapper">
                <input
                  type="radio"
                  id="online"
                  // checked={selectedOption === 'online'}
                  // onChange={handleOptionChange}
                  {...register("booking")}
                  // name="booking"
                  value="Online"
                  className="ticketBooking"
                />

                <label for="online">Online</label>
              </div>
              <div className="radio-btn-wrapper">
                <input
                  type="radio"
                  id="offline"
                  // checked={selectedOption === 'offline'}
                  // onChange={handleOptionChange}
                  {...register("booking")}
                  // name="booking"
                  value="Offline"
                  className="ticketBooking"
                />

                <label for="offline">Offline</label>
              </div>
              <div className="radio-btn-wrapper">
                <input
                  type="radio"
                  id="both"
                  // checked={selectedOption === 'both'}
                  // onChange={handleOptionChange}
                  {...register("booking")}
                  // name="booking"
                  value="Both"
                  className="ticketBooking"
                />

                <label for="both">Both</label>
              </div>
              {errors.booking?.type === "typeError" && (
                <span className="error text-left">
                  Please select bookings option
                </span>
              )}
            </div>

            <div className="inputs-heading">Account Information</div>
            <div className="row input-block">
              <div className="col-md-6 col-lg-4 input-wrapper required">
                <label className="input-label">first name</label>
                <div
                  className={`input-wrap ${
                    errors.firstName ? "has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    {...register("firstName")}
                    placeholder="First Name"
                  />
                </div>
                {errors.firstName && (
                  <span className="error">{errors.firstName.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 input-wrapper required">
                <label className="input-label">Last name</label>
                <div
                  className={`input-wrap ${errors.lastName ? "has-error" : ""}`}
                >
                  <input
                    type="text"
                    {...register("lastName")}
                    className="form-control"
                    placeholder="Last Name"
                  />
                </div>
                {errors.lastName && (
                  <span className="error">{errors.lastName.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 input-wrapper required">
                <label className="input-label">Email address</label>
                <div
                  className={`input-wrap ${errors.email ? "has-error" : ""}`}
                >
                  <input
                    type="email"
                    {...register("email")}
                    className="form-control"
                    autoComplete="off"
                    placeholder="example@aladyyn.pro"
                  />
                </div>
                {errors.email && (
                  <span className="error">{errors.email.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 input-wrapper">
                <label className="input-label">Password</label>
                <div
                  className={`input-wrap password ${
                    errors.password ? "has-error" : ""
                  }`}
                >
                  <input
                    type={passwordType}
                    {...register("password")}
                    className="form-control"
                    placeholder="Password"
                  />
                  <div className="toggle-password">
                    <i
                      onClick={() => {
                        setPasswordType(
                          passwordType === "password" ? "text" : "password"
                        );
                      }}
                      className={`icon-eye ${
                        passwordType === "password" ? "" : "open"
                      }`}
                    ></i>
                  </div>
                </div>
                {errors.password && (
                  <span className="error">{errors.password.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 input-wrapper">
                <label className="input-label">Confirm Password</label>
                <div
                  className={`input-wrap password ${
                    errors.re_password ? "has-error" : ""
                  }`}
                >
                  <input
                    type={con_passwordType}
                    {...register(
                      "re_password"
                      // { required: true }
                    )}
                    className="form-control"
                    placeholder="Confirm password"
                  />
                  <div className="toggle-password">
                    <i
                      onClick={() => {
                        setCon_passwordType(
                          con_passwordType === "password" ? "text" : "password"
                        );
                      }}
                      className={`icon-eye ${
                        con_passwordType === "password" ? "" : "open"
                      }`}
                    ></i>
                  </div>
                </div>
                {errors.re_password && (
                  <span className="error">{errors.re_password.message}</span>
                )}
              </div>
            </div>
            <div className="inputs-heading">Business Information</div>
            <div className="row input-block margin-fix">
              <div className="col-md-6 col-lg-4 input-wrapper required">
                <label className="input-label">Business Name</label>
                <div
                  className={`input-wrap ${
                    errors.businessName ? "has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    {...register("businessName")}
                    placeholder="Business name"
                  />
                </div>
                {errors.businessName && (
                  <span className="error">{errors.businessName.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 input-wrapper required">
                <label className="input-label">Primary Contact Person</label>
                <div
                  className={`input-wrap ${
                    errors.primaryContactPerson ? "has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    {...register("primaryContactPerson")}
                    className="form-control"
                    placeholder="Primary contact person"
                  />
                </div>
                {errors.primaryContactPerson && (
                  <span className="error">
                    {errors.primaryContactPerson.message}
                  </span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 input-wrapper required">
                <label className="input-label">Lab Registration Number</label>
                <div
                  className={`input-wrap ${
                    errors.companyRegistrationNumber ? "has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    {...register("companyRegistrationNumber")}
                    className="form-control"
                    autoComplete="off"
                    placeholder="Lab registration number"
                  />
                </div>
                {errors.companyRegistrationNumber && (
                  <span className="error">
                    {errors.companyRegistrationNumber.message}
                  </span>
                )}
              </div>
              {/* <div className="col-md-6 col-lg-4 input-wrapper">
								<label className="input-label">VAT Number (if applicable)</label>
								<div className={`input-wrap ${errors.vat ? 'has-error' : ''}`}>
									<input
										type="text"
										{...register("vat")}
										className="form-control"
										autoComplete="off"
										placeholder="VAT number"
									/>
								</div>
								{errors.vat && (
									<span className="error">
										{errors.vat.message}
									</span>
								)}
							</div> */}
              <div className="col-md-6 col-lg-4 ">
                <div className="input-wrapper required">
                  <label className="input-label required">
                    Phone Number for verification
                  </label>
                  <div
                    className={`input-wrap ${isOTPWrong ? "has-error" : ""}`}
                  >
                    <input
                      type="text"
                      // {...register("phone")}
                      onChange={mobHandler}
                      // defaultValue={mob}
                      className="form-control"
                      placeholder="Phone"
                      disabled={phoneFieldDisabled}
                      value={phone.value}
                    />
                    <div className="btn-wrap otp-text">
                      {/* {
												isPhoneVerify ?
													<div className="verify-btn">Verified</div>
													: <button onClick={sendOtp} type='button'>{isOtp ? "Resend OTP" : "send OTP"}</button>
											} */}
                      {isPhoneVerify ||
                      `${getProfileInfo?.data?.[0]?.phone}` ===
                        `${phone.value}` ? (
                        <div className="verify-btn">Verified</div>
                      ) : (
                        ""
                      )}

                      {`${getProfileInfo?.data?.[0]?.phone}` !==
                        `${phone.value}` &&
                        !resendEnable &&
                        !showTimer &&
                        sendOTPEnable &&
                        phone.value?.length === 10 && (
                          <button onClick={sendOtp} type="button">
                            Send OTP
                          </button>
                        )}

                      {showTimer ? (
                        <div>Time remaining: {seconds} seconds</div>
                      ) : (
                        ""
                      )}

                      {!isPhoneVerify &&
                        !showTimer &&
                        resendEnable &&
                        phone.value?.length === 10 && (
                          <button onClick={sendOtp} type="button">
                            Resend OTP{" "}
                          </button>
                        )}
                    </div>
                  </div>

                  {enableInst && (
                    <div className="number-sent">
                      <span className="number-detail">
                        We have sent you a four-digit code. Please enter it in
                        the next input to confirm the number
                      </span>
                    </div>
                  )}

                  {errors.phone && (
                    <span className="error">{errors.phone.message}</span>
                  )}
                </div>
              </div>

              {isOtp ? (
                <div className="col-md-6 col-lg-4 input-wrapper required">
                  <label className="input-label text-normal">
                    Verification Code
                  </label>
                  <div className="input-wrap security-input">
                    <ReactCodeInput fields={4} onComplete={verifyOtp} />
                  </div>
                  {errors.phone && (
                    <span className="error">{errors.phone.message}</span>
                  )}
                </div>
              ) : null}
            </div>
            <div className="inputs-heading">Registered Business Address</div>
            <div className="row input-block">
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">address line 1</label>
                <div
                  className={`input-wrap ${
                    errors.addressLine1 ? "has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    {...register("addressLine1")}
                    placeholder="Address line 1"
                  />
                  <span className="input-info">Building number and Street</span>
                </div>
                {errors.addressLine1 && (
                  <span className="error">{errors.addressLine1.message}</span>
                )}
              </div>
              <div className="col-md-6 input-wrapper">
                <label className="input-label">address line 2</label>
                <div className="input-wrap">
                  <input
                    type="text"
                    {...register("addressLine2")}
                    className="form-control"
                    placeholder="Address line 2"
                  />
                  <span className="input-info">Room/Block/Apartments</span>
                </div>
              </div>
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">Country</label>
                {/* <div className="input-wrap">
									<select {...register("countryId")} name="countryId"
										className="form-control" id="countryId"
										onChange={getStateHandler}
										placeholder='Select'
									>
										<option value={""}>Please select country</option>
										{
											getCountry?.map(item =>
												<option value={item._id}>{item.name}</option>
											)
										}

									</select>
								</div> */}

                <div className="input-wrap ">
                  <select
                    // name="countryId"
                    className="form-control"
                    id="countryId"
                    disabled
                  >
                    <option value={""}>India</option>
                  </select>
                </div>
                {/* {errors.countryId && (
									<span className="error">
										{errors.countryId.message}
									</span>
								)} */}
              </div>
              <div className="col-md-6 input-wrapper">
                <label className="input-label">State / Region</label>
                <div
                  className={`input-wrap ${errors.stateId ? "has-error" : ""}`}
                >
                  <select
                    className="form-control icon-down"
                    {...register("stateId")}
                    name="stateId"
                    onChange={getCityHandler}
                  >
                    <option value={""}>Please select state</option>
                    {getState?.map((item) => {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                  </select>
                </div>
                {errors.stateId && (
                  <span className="error">{errors.stateId.message}</span>
                )}
              </div>
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">City</label>
                <div className="input-wrap">
                  <select
                    className="form-control icon-down"
                    {...register("cityId")}
                    name="cityId"
                  >
                    <option value={""}>Please select city</option>
                    {getCity?.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                {errors.cityId && (
                  <span className="error">{errors.cityId.message}</span>
                )}
              </div>
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">ZIP / Postal Code</label>
                <div
                  className={`input-wrap ${errors.postcode ? "has-error" : ""}`}
                >
                  <input
                    type="text"
                    {...register("postcode")}
                    className="form-control"
                    autoComplete="off"
                    placeholder="ZIP / Postal Code"
                  />
                </div>
                {errors.postcode && (
                  <span className="error">{errors.postcode.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="btn-wrap">
            <input
              onClick={changeHandler}
              className="secondary-btn"
              type="reset"
              value="cancel"
            />

            {isPhoneVerify ? (
              <input className="btn" type="submit" value="save" />
            ) : (
              <input
                className="btn"
                type="submit"
                disabled={submitDisable}
                value="save"
              />
            )}

            {/* <input className="btn" type="submit" disabled={submitDisable} value="save" /> */}

            {/* <input className="btn" type="submit" value="save" /> */}
          </div>
        </form>
      </div>
    </section>
  );
};

const ImageThumb = ({ image }) => {
  return (
    <div className="thumbnail-wrap">
      <img src={URL.createObjectURL(image)} alt={image.name} />
    </div>
  );
};

export default EditSellerVerification;

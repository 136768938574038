import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const totalMessageCount = (data) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .get(endPoints.USER_MESSAGE_COUT, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.USER_TOTAL_MESSAGE,
                    payload: {
                        ...response.data
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
            // loadingAction(false)(dispatch);
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }
        });
};

export const totalMessageSeller = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
      .get(endPoints.SELLER_MESSAGE_COUT, {
        headers: {
          Authorization: token,
        },
      })
      .then(async (response) => {
        if (response?.data?.status === "success") {
          dispatch({
            type: actiontype.SELLER_TOTAL_MESSAGE,
            payload: {
              ...response.data,
            },
          });
        } else {
          dispatch({
            type: actiontype.SELLER_TOTAL_MESSAGE,
            payload: {
              ...response.data,
            },
          });
        }
        // loadingAction(false)(dispatch);
      })
      .catch((errResponse) => {
        if (errResponse?.response?.data?.status === "error") {
        }
      });
  };

export const totalName = (data) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.USER_CONVERSAION_NAME, {}, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.USER_MESSAGE_NAME_LIST,
                    payload: {
                        ...response.data
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
            // loadingAction(false)(dispatch);
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }
        });
};

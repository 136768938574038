import * as types from '../actionType'
const initialState = []
function setWishList(state, payload) {
    let newState = [...state];
    newState = newState.map(item => {
        if (item._id === payload.data.serviceId) {
            return { ...item, wishlist: payload.type }
        } else {
            return { ...item }
        }
    });
    return newState;
}

export const bestSellerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.BEST_SELLER_LIST:
            return action.payload;
			case types.SET_WISHLIST:
				return setWishList(state, action.payload)
        default:
            return state
    }
}
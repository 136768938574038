import * as types from '../actionType'

const initialState = []

export const languageDrop = (state = initialState, action) => {
    switch (action.type) {
        case types.LANGUAGES:
            return action.payload
        default:
            return state
    }
}
import * as actionTypes from 'store/actionType'

const initialState = {};

const wishlistOtherServices = (state, payload) => {
	let newState = [...state.otherServices];
	newState = newState.map((item) => {
		if (item._id === payload.data.serviceId) {
			return { ...item, wishlist: payload.type };
		} else {
			return item;
		}
	});
	return { ...state, otherServices: newState };
};

const otherServicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SELLER_OTHER_SERVICE:
			return { otherServices: action.payload }
		case actionTypes.WISHLIST_OTHER_SERVICE:
			return wishlistOtherServices(state, action.payload);
		case "WISHLIST":
			return wishlistOtherServices(state, action.payload);
		default:
			return state;
	}
}

export default otherServicesReducer;
import * as types from '../actionType'

const initialState = []

const sellerOrderRejected = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_POST_REJECTED_ORDER:
            return action.payload
        default:
            return state
    }
}


export { sellerOrderRejected }
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { loginAction, sellerLoginAction } from "store/action/userLoginAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "react-google-login"; 
import FacebookLogin from "react-facebook-login";
import { Alert } from "components/Alert/Alert";
import SocialButton from "./SocialLogin";
import { googleSocialLogin, socialLogin } from "store/action/socialMediaAction";
import { gapi } from "gapi-script";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { addToCart } from "store/action/ServiceDetailAction";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Za-z]{2,}$/i,
      "Please use the correct email"
    ),
  password: yup
    .string()
    .required("Please enter the password")
    .min(8, "Password should be equal or greater than 8 characters"),
});

const SignIn = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const param = useParams();

  const clientId =
    "16226243066-56t8lc4tc0m4r4cmvtkaps5bho4tes3a.apps.googleusercontent.com";

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");
  const isToastVisible = useRef(false);

  const login = useSelector((state) => state.login);
  let serviceDetail = useSelector((store) => store.sellerServiceDetail);

  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const togglePasswordHandler = () => {
    setIsShowPassword(!isShowPassword);
  };

  const inputType = isShowPassword ? "text" : "password";

  let token = localStorage.getItem("user_token");

  useEffect(() => {
    if (login.islogin) {
      if (login.type === "Lab") {
        setTimeout(() => (window.location.href = "/lab-dashboard"), 3000);
      } else if (login.type === "Advertiser") {
        setTimeout(
          () => (window.location.href = "/user/advertiser-dashboard"),
          3000
        );
      } else if (login.type === "User") {
        setTimeout(() => (window.location.href = "/user/dashboard"), 3000);
      } else if (login.type === "Freelancer") {
        setTimeout(
          () => (window.location.href = "/seller-dashboard-freelancer"),
          3000
        );
        return;
      }
    }
  }, [login]);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const onGoogleLogin = (user) => {
    if (location.pathname === "/sign-in") {
      let payload = {
        email: user.profileObj.email,
        socialMediaType: "Google",
        googleData: {
          email: user.profileObj.email,
          family_name: user.profileObj.familyName,
          given_name: user.profileObj.givenName,
          id: user.profileObj.googleId,
          name: user.profileObj.name,
          picture: user.profileObj.imageUrl,
        },
        isSignUp: false,
        userType: location.pathname === "/lab-sign" ? "Lab" : "User",
      };
      setTimeout(() => googleSocialLogin(payload)(dispatch), 2000);
    }
  };

  const onLoginFailure = (user) => {};

  const responseGoogle = (response) => {};

  const responseFacebook = (response) => {
    let payload = {
      ...response,
      email: response?.data?.email,
      socialMediaType: "Facebook",
      facebookData: {
        email: response?.data?.email,
        family_name: response?.data?.last_name,
        given_name: response?.data?.first_name,
        id: response?.data?.userID,
        name: response?.data?.name,
        picture: response?.data?.picture?.data?.url,
      },
    };
    setTimeout(() => googleSocialLogin(payload)(dispatch), 2000);
  };

  const togglePasswordBtnClasses = isShowPassword
    ? "toggle-btn show toggle-btn-wrap"
    : "toggle-btn hide sign-in-btn";

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const onSubmit = (data, e) => {
    if (data.email.includes(" ")) {
      setEmailError("Email cannot contain spaces.");
      return;
    }
    e.preventDefault();

    let payload = {
      email: data.email,
      password: data.password,
      keep_me_signed_in: rememberMe,
    };
    if (location.pathname !== "/lab-sign") {
      loginAction(payload, isToastVisible)(dispatch);
    } else {
      sellerLoginAction(payload, isToastVisible)(dispatch);
    }
  };

  const handleSocialLogin = (user) => {
    let payload = {
      email: user.profile.email,
      socialMediaType: user._provider ? "Google" : "",
      facebookData: {
        email: user.profile.email,
        family_name: user.profile.lastName,
        given_name: user.profile.firstName,
        id: user.profile.id,
        name: user.profile.firstName + user.profile.lastName,
        picture: user.profile.profilePicURL,
      },
    };
    setTimeout(() => socialLogin(payload)(dispatch), 2000);
  };

  const handleSocialLoginFailure = (err) => {};

  return (
    <section className="sign-in">
      <Alert />

      <div className="container">
        <div className="card">
          <h2>Sign In</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-block">
              <label htmlFor="user-id">Email</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("email")}
                  className="form-control"
                  placeholder="Email"
                  pattern="[^\s]+"
                />
              </div>
              {emailError && <span className="error">{emailError}</span>}
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
            </div>
            <div className="input-block">
              <label htmlFor="password">Password</label>
              <div className="input-wrap">
                <input
                  type={inputType}
                  className="form-control"
                  {...register("password")}
                  placeholder="***********"
                />
                <div className="toggle-wrap">
                  <button
                    className={togglePasswordBtnClasses}
                    type="button"
                    onClick={togglePasswordHandler}
                  >
                    <i className="icon-eye"></i>
                  </button>
                </div>
              </div>
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </div>
            <div className="row">
              <div className="col-md-6 mb-2 mb-md-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleRememberMeChange}
                    checked={rememberMe}
                  />
                  <label className="form-check-label">Keep me signed in</label>
                </div>
              </div>
              <div className="col-md-6 mb-2 mb-md-0 text-md-end">
                <Link to="/forgot-password" className="forgot-link">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className="btn-wrap">
              <button type="submit" className="btn w-100">
                Sign In
              </button>
            </div>
          </form>

          {location.pathname === "/lab-sign" ? (
            ""
          ) : (
            <div className="signin-options text-center">
              <Link
                to="#"
                className="google-login"
                style={{ position: "relative" }}
              >
                <i className="icon-google"></i>
                <span className="text">Google</span>
                <div className="google-login-btn social-login-main">
                  <GoogleLogin
                    clientId={clientId}
                    onSuccess={onGoogleLogin}
                    onFailure={onLoginFailure}
                  />
                </div>
              </Link>

              <Link
                to="#"
                className="fb-login"
                style={{ position: "relative" }}
              >
                <i className="icon-fb"></i>
                <span className="text">Facebook</span>
                <div className="fb-login-btn social-login-main">
                  <LoginSocialFacebook
                    appId="882123360010207"
                    onResolve={responseFacebook}
                    scope="email"
                    onReject={(responseFail) => {}}
                  >
                    <FacebookLoginButton />
                  </LoginSocialFacebook>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="card text-center">
          {location.pathname === "/lab-sign" ? (
            <div className="signup-wrap">
              Don’t have lab account?
              <Link to="/register-Lab" className="signup-link">
                Sign Up
              </Link>
            </div>
          ) : (
            <div className="signup-wrap">
              Don’t have an account?
              <Link to="/create-account" className="signup-link">
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default SignIn;

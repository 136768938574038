import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
// import {getFormToken } from '../../store/action/getFormToken'
// import { sellerReg } from '../../store/action/becomeSellerAction'
import sellerReg, { labRegAction } from 'store/action/becomeSellerAction';
import { useDispatch, useSelector } from 'react-redux'
import { countryList } from 'store/action/countryList';
import { stateList } from 'store/action/stateList';
import { cityList } from 'store/action/cityListAction';
import { loginAction } from 'store/action/userLoginAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TimerForPopUp from './TimerForPopUp';
import ReactCodeInput from 'react-verification-code-input';
import { reSendOtpAction, sendOtpRegAction, verifyOtpRegLabAction } from 'store/action/profileAction';
import { useNotify } from 'components/Alert/Alert';

const schema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter first name")
        .min(2, "First name must be at least 2 characters")
        .max(20, "First name must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
    lastName: yup
        .string()
        .required("Please enter last name")
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),

    email: yup
        .string()
        .required("Please enter your email address")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Please use the correct email"
        ),

    businessName: yup
        .string()
        .required("Please enter business name"),
    // .min(2, "Business name must be at least 2 characters")
    // .max(20, "Business name must be at most 20 characters"),
    // .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Please enter valid Business name"),
    primaryContactPerson: yup
        .string()
        .required("Please enter primary contact person")
        .min(2, "Primary contact person must be at least 2 characters")
        .max(20, "Primary contact person must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid primary contact person"),
    companyRegistrationNumber: yup
        .string()
        .required("Please enter company register number")
        .matches(/^[0-9a-zA-Z]+$/, "Company Register Number is not valid"),
    // vat: yup
    //     .string(),
    // .required("Please enter Company Register Number")
    // .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i, "VAT Number is not valid"),
    addressLine1: yup
        .string()
        .required("Please enter address")
        .min(2, "Address must be at least 2 characters")
        .max(60, "Address must be at most 20 characters")
        .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
    addressLine2: yup
        .string(),
    cityId: yup
        .string()
        .required("Please select city"),
    // .required("Please enter City")
    // .min(2, "City must be at least 2 characters")
    // .max(20, "City must be at most 20 characters")
    // .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
    stateId: yup
        .string()
        .required("Please select state"),
    // countryId: yup
    //     .string()
    //     .required("Please enter country"),
    phone: yup
        .string()
        .required("Please enter mobile number")
        .matches(/^[0-9]*$/, "Please enter a valid mobile number")
        // .min(7)
        .max(10),
    postcode: yup
        .string()
        .required("Please enter zip")
        .matches(/^[0-9]*$/, "Zip code is not valid")
        // .min(3),
        .max(6),
    comment: yup
        .string()
        .required("Please enter comment")
        // .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Comment is not valid")
        .min(2)
        .max(300),
    password: yup
        .string()
        .required("Please enter the password")
        .min(8, "Password should be equal or greater that 8 characters"),
    booking: yup.string().required(),
    re_password: yup
        .string()
        .required("Please enter the password")
        .min(8, "Password should be equal or greater that 8 characters")
        .oneOf([yup.ref('password'), null], 'Confirm Password must be same as New Password'),
    proofOfIdentity: yup
        .string(),
    privacyPolicy: yup.bool().oneOf([true], "Please check the privacy policy"),
});

const LabForm = () => {

    const toast = useNotify()

    const navigate = useNavigate();
    let attachmentName = useRef(null);
    const getCountry = useSelector(state => state.countryData)
    const getState = useSelector(state => state.stateData)
    const getCity = useSelector(state => state.cityData)
    const [passwordType, setPasswordType] = useState("password");
    const [con_passwordType, setCon_passwordType] = useState("password");
    const [country, setCountry] = useState("");
    const login = useSelector(state => state.login)
    const [region, setRegion] = useState("");

    const [statename, setStateName] = useState("");
    const [countryname, setCountryname] = useState("");
    const [img, setImg] = useState("");
    const [file, setFile] = useState("");
    const [fileAttach, setFileAttach] = useState(null);

    const [selectedOption, setSelectedOption] = useState('');

    const [enableInst, setEnableInst] = useState(false)

    const [online, setOnline] = useState()
    const [offline, setOffline] = useState()
    const [both, setBoth] = useState()
    const [isPhoneVerify, setIsPhoneVerify] = useState(false);
    const [isOtp, setIsOtp] = useState(false)
    const [showSendOtpBtn, setShowSendOtpBtn] = useState(false)


    const [isVisible, setIsVisible] = useState(true);

    const initialTime = 5;

    const [seconds, setSeconds] = useState(0);
    const [reSendTimerSeconds, setreSendTimerSeconds] = useState(20);
    const [resendBtn, setResendBtn] = useState(false)
    const [phone, setPhone] = useState({ value: "" })
    const [phoneFieldDisabled, setPhoneFieldDisabled] = useState(false);

    const [showTimer, setShowTimer] = useState(false)

    const [phLength, setPhLength] = useState()

    const [sendOTPEnable, setSendOTPEnable] = useState(false)
    const [oTPInputEnable, setOTPInputEnable] = useState(false)

    const [resendEnable, setResendEnable] = useState(false)

    const [privacyMessage, setPrivacyMessage] = useState(false)




    const [submitDisable, setSubmitDisable] = useState(true)

    const [priceValue, setPriceValue] = useState("")


    const dispatch = useDispatch()
    const notify = () => toast("User Registered Successfully!");
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    // useEffect(() => {
    //     countryList()(dispatch);
    // }, []);

    useEffect(() => {
        if (login.islogin) {
            if (login.type === "Company") {
                setTimeout(() => (window.location.href = '/seller-dashboard-company'), 3000);
                // return
            }
        }
    }, [login])

    const imgHandler = (e) => {
        setFileAttach(e.target.files[0])
        let src_ = URL.createObjectURL(e.target.files[0]);
        setImg(src_)
    }

    const ImageThumb = ({ image }) => {
        return <div className='thumbnail-wrap'>
            <img src={URL.createObjectURL(image)} alt={image.name} />
        </div>
    };


    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setOnline(e.target.value)
    };


    const onSubmit = (data) => {

        let payload = new FormData();
        payload.append('type', "Lab");
        payload.append('bookingType', data.booking)
        payload.append('firstName', data.firstName);
        payload.append('lastName', data.lastName);
        payload.append("cityId", data.cityId);
        payload.append('stateId', data.stateId);
        payload.append('password', data.password);
        payload.append('comment', data.comment);
        payload.append("addressLine1", data.addressLine1);
        payload.append("addressLine2", data.addressLine2);
        payload.append("email", data.email);
        payload.append("postcode", data.postcode);
        payload.append("phone", data.phone);
        payload.append("proofOfIdentity", fileAttach);
        payload.append("privacyPolicy", true);
        payload.append("countryId", "61c2fb83dc7c0d455ba5e68d")
        payload.append("companyRegistrationNumber", data.companyRegistrationNumber)
        payload.append("primaryContactPerson", data.primaryContactPerson)
        payload.append("businessName", data.businessName)


        // if (privacyMessage === false) {
        //     setPrivacyMessage(true)
        // }



        dispatch(labRegAction(payload, navigate, callback => {
        }))


    };

    useEffect(() => {
        stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
    }, [])

    // const getStateHandler = (e) => {
    //     let val = e.target.value;
    //     setCountryname(val)
    //     stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" })(dispatch);
    // }

    const getCityHandler = (e) => {
        cityList({ countryId: "61c2fb83dc7c0d455ba5e68d", stateId: e.target.value })(dispatch);
    }


    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' },
    // ];

    useEffect(() => {
        if (phone.value.length === 10) {
            setSendOTPEnable(true)
        }
        else {
            setSendOTPEnable(false)
        }
    }, [phone])


    useEffect(() => {
        if (seconds === 0) {
            setShowTimer(false)
            if (!phoneFieldDisabled) {
                setResendEnable(true)
            }
            setSeconds(null)
        }

        // exit early when we reach 0
        if (!seconds) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setSeconds(seconds - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [seconds]);


    const handleSeconds = () => {
        setSeconds(25)
    }


    const phoneVerifyHandler = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)
        setResendEnable(false)

        // if (e.target.value.length > 0) {
        //     setSubmitDisable(true);
        // }

        // const newValue = e.target.value.replace(/\D/g, "");
        // if (newValue.length <= 10) {
        //     setPhLength(newValue);
        // }
        // if (e.target.value.length === 10) {
        //     setPhoneNumber(e.target.value)
        //     setShowSendOtpBtn(true)
        // }
        // if (e.target.value.length === 10) {
        //     setShowSendOtpBtn(true)
        // }

        // const numericInput = document.getElementById('numericInput');

        // numericInput.addEventListener('input', function (event) {
        //     this.value = this.value.replace(/\D/g, '');

        //     if (this.value.length > 10) {
        //         this.value = this.value.slice(0, 10);
        //     }

        //     if (this.value.includes('e')) {
        //         this.value = this.value.replace('e', '');
        //     }
        // });
    }




    const sendOtpHandler = () => {
        const payload = {
            phone: phone.value,
            countryCode: "+91"
        }
        sendOtpRegAction(payload, dispatch, toast, setSendOTPEnable, setShowTimer, setIsOtp, handleSeconds, setEnableInst)
    }

    const verifyOtp = (val) => {

        let payload = {
            phone: phone.value,
            otp: val
        }

        verifyOtpRegLabAction(payload, dispatch, toast, setPhoneFieldDisabled, setSendOTPEnable, setShowTimer, setResendEnable, setIsOtp, setSubmitDisable, setEnableInst)
    }


    const privacyPolicyHandler = (e) => {
        

        if (e.target.checked === true && phoneFieldDisabled) {
            setSubmitDisable(false)
        } else {
            setSubmitDisable(true)


        }
    }

    return (
        <div
        // className='company-tab'
        >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />

            {/* <TimerForPopUp /> */}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card inputs-wrapper">
                    <h4>Verification</h4>
                    <div className='lab-radios radio-box-wrap' style={{ position: "relative" }}>

                        Taking bookings :

                        {/* <label for="online">Online</label> */}

                        <div className='radio-btn-wrapper'>
                            <input type="radio" id="online"
                                // checked={selectedOption === 'online'}
                                // onChange={handleOptionChange}
                                {...register("booking")}
                                // name="booking"
                                value="Online" className='ticketBooking' />

                            <label for="online">Online</label>
                        </div>



                        <div className='radio-btn-wrapper'>
                            <input type="radio" id="offline"
                                // checked={selectedOption === 'offline'}
                                // onChange={handleOptionChange}
                                {...register("booking")}
                                // name="booking"
                                value="Offline" className='ticketBooking' />

                            <label for="offline">Offline</label>
                        </div>




                        <div className='radio-btn-wrapper'>
                            <input type="radio" id="both"
                                // checked={selectedOption === 'both'}
                                // onChange={handleOptionChange}
                                {...register("booking")}
                                // name="booking"
                                value="Both" className='ticketBooking' />

                            <label for="both">Both</label>
                        </div>

                        {errors.booking?.type === "typeError" && (
                            <span className="error text-left">
                                Please select bookings option
                            </span>
                        )}
                    </div>
                    <div className="row input-block">
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required required">first name</label>
                            <div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("firstName")}
                                    placeholder='First Name'
                                />
                            </div>
                            {errors.firstName && (
                                <span className="error">
                                    {errors.firstName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Last name</label>
                            <div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("lastName")}
                                    className="form-control"
                                    placeholder='Last Name'
                                />
                            </div>
                            {errors.lastName && (
                                <span className="error">
                                    {errors.lastName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Email address</label>
                            <div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
                                <input
                                    type="email"
                                    {...register("email")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='example@aladyyn.pro'
                                />
                            </div>
                            {errors.email && (
                                <span className="error">
                                    {errors.email.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Password</label>
                            <div className={`input-wrap password ${errors.password ? 'has-error' : ''}`}>
                                <input
                                    type={passwordType}
                                    {...register("password")}
                                    className="form-control"
                                    placeholder='**********'
                                />
                                <div className="toggle-password">
                                    <i onClick={() => { setPasswordType(passwordType === "password" ? "text" : "password") }} className={`icon-eye ${passwordType === "password" ? "" : "open"}`}></i>
                                </div>
                            </div>
                            {errors.password && (
                                <span className="error">
                                    {errors.password.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Confirm Password</label>
                            <div className={`input-wrap password ${errors.re_password ? 'has-error' : ''}`}>
                                <input
                                    type={con_passwordType}
                                    {...register("re_password")}
                                    className="form-control"
                                    placeholder='************'
                                />
                                <div className="toggle-password">
                                    <i onClick={() => { setCon_passwordType(con_passwordType === "password" ? "text" : "password") }} className={`icon-eye ${con_passwordType === "password" ? "" : "open"}`}></i>
                                </div>
                            </div>
                            {errors.re_password && (
                                <span className="error">
                                    {errors.re_password.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="inputs-heading">Business Information</div>
                    <div className="row input-block margin-fix">
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Business Name</label>
                            <div className={`input-wrap ${errors.businessName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("businessName")}
                                    placeholder='Business Name'
                                    maxLength={40}
                                />
                            </div>
                            {errors.businessName && (
                                <span className="error">
                                    {errors.businessName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Primary Contact Person</label>
                            <div className={`input-wrap ${errors.primaryContactPerson ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("primaryContactPerson")}
                                    className="form-control"
                                    placeholder='Primary Person'
                                />
                            </div>
                            {errors.primaryContactPerson && (
                                <span className="error">
                                    {errors.primaryContactPerson.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Lab Registration Number</label>
                            <div className={`input-wrap ${errors.companyRegistrationNumber ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("companyRegistrationNumber")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Lab Registration Number'
                                />
                            </div>
                            {errors.companyRegistrationNumber && (
                                <span className="error">
                                    {errors.companyRegistrationNumber.message}
                                </span>
                            )}
                        </div>
                        {/* <div className="col-md-6 col-lg-4 input-wrapper">
                            <label className="input-label">VAT Number <span className='text-lowercase'>(if applicable)</span></label>
                            <div className={`input-wrap ${errors.vat ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("vat")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Vat number'
                                />
                            </div>
                            {errors.vat && (
                                <span className="error">
                                    {errors.vat.message}
                                </span>
                            )}
                        </div> */}
                        <div className="col-md-6 col-lg-4">
                            <div className="input-wrapper required">
                                <label className="input-label required">Phone Number</label>
                                <div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
                                    <input
                                        type="text"
                                        {...register("phone")}
                                        className="form-control"
                                        placeholder='Mobile'
                                        onChange={phoneVerifyHandler}
                                        disabled={phoneFieldDisabled}
                                        value={phone.value}
                                    // id="numericInput"
                                    // maxLength="10"

                                    />
                                    <div className="btn-wrap otp-text">

                                        {
                                            phoneFieldDisabled ?
                                                <div className="verify-btn">Verified</div>
                                                : ""
                                        }


                                        {!resendEnable && !showTimer && sendOTPEnable && phone.value?.length === 10 && <button
                                            onClick={sendOtpHandler}
                                            type='button'>Send OTP</button>}

                                        {
                                            showTimer ? <div>Time remaining: {seconds} seconds
                                            </div> : ""
                                        }


                                        {
                                            !showTimer && resendEnable && phone.value?.length === 10 && <button
                                                onClick={sendOtpHandler}
                                                type='button'>Resend OTP </button>
                                        }




                                    </div>
                                </div>
                                {enableInst && <div className='number-sent'>
                                    <span className='number-detail'>We have sent you a four-digit code. Please enter it in the next input to confirm the number</span>
                                </div>}
                                {errors.phone && (
                                    <span className="error">
                                        {errors.phone.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        {
                            isOtp ?
                                <div className="col-md-6 col-lg-4 input-wrapper required">
                                    <label className="input-label text-normal">Verification Code</label>
                                    <div className="input-wrap security-input">
                                        <ReactCodeInput
                                            fields={4}
                                            onComplete={verifyOtp}
                                        />
                                    </div>
                                    {/* {errors.phone && (
                                        <span className="error">
                                            {errors.phone.message}
                                        </span>
                                    )} */}
                                </div>
                                : null
                        }



                        <div className="input-wrapper img-input-fix has-input-file">
                            <label className="">*Proof of Identify</label>
                            <div className="row input-block">
                                <div className="col-lg-8 input-wrapper">
                                    <div className="img-input-wrapper">
                                        <div className="img-input">
                                            <i className="icon-plus"></i>
                                            Upload
                                            <input
                                                {...register("proofOfIdentity")}
                                                type="file"
                                                accept="image/*"
                                                ref={attachmentName}
                                                onChange={imgHandler}
                                            />
                                        </div>
                                        <span className="img-info">Upload scanned copy of passport, nationalID, driver’s license etc.</span>
                                    </div>
                                    {
                                        img !== "" ?
                                            <div className="img-thumbnail-wrapper attachment-name image">
                                                <img src={img} />
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                            {file && <ImageThumb image={file} />}
                        </div>

                    </div>
                    <div className="inputs-heading">Registered Business Address</div>
                    <div className="row input-block">
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label required">address line 1</label>
                            <div className={`input-wrap ${errors.addressLine1 ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("addressLine1")}
                                    placeholder='Address'
                                />
                                <span className="input-info">Building number and Street</span>
                            </div>
                            {errors.addressLine1 && (
                                <span className="error">
                                    {errors.addressLine1.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper">
                            <label className="input-label">address line 2</label>
                            <div className={`input-wrap ${errors.addressLine2 ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("addressLine2")}
                                    className="form-control"
                                    placeholder='Address'
                                />
                                <span className="input-info">Room/Block/Apartments</span>
                            </div>
                            {errors.addressLine2 && (
                                <span className="error">
                                    {errors.addressLine2.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">Country</label>
                            <div className={`input-wrap ${errors.countryId ? 'has-error' : ''}`}>
                                <select
                                    // {...register("countryId")}
                                    // name="countryId" 
                                    className="form-control" id="countryId"
                                    // onChange={getStateHandler}
                                    disabled
                                >
                                    <option value={""}>India</option>
                                    {/* {
                                        getCountry?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    } */}

                                </select>
                            </div>
                            {/* {errors.countryId && (
                                <span className="error">
                                    {errors.countryId.message}
                                </span>
                            )} */}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">State / Region</label>
                            <div className={`input-wrap ${errors.stateId ? 'has-error' : ''}`}>
                                <select className="form-control icon-down" {...register("stateId")} name="stateId" onClick={getCityHandler}>
                                    <option value={""}>Please select state</option>
                                    {
                                        getState?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.stateId && (
                                <span className="error">
                                    {errors.stateId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">City</label>
                            <div className={`input-wrap ${errors.cityId ? 'has-error' : ''}`}>

                                <select className="form-control icon-down" {...register("cityId", { required: true })} name="cityId">
                                    <option value={""}>Please select city</option>
                                    {
                                        getCity?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.cityId && (
                                <span className="error">
                                    {errors.cityId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label required">ZIP / Postal Code</label>
                            <div className={`input-wrap ${errors.postcode ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("postcode")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Zip Code'
                                />
                            </div>
                            {errors.postcode && (
                                <span className="error">
                                    {errors.postcode.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="row input-block">
                        <div className="col-12 input-wrapper required mb-0">
                            <label className="input-label required">Comment</label>
                            <div className={`input-wrap ${errors.comment ? 'has-error' : ''}`}>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    {...register("comment")}
                                    placeholder='Tell us more about the services you provide'
                                // placeholder='Please enter your comment'
                                />
                            </div>
                            <label className="comment-info input-label required input-info position-static">Comment should not exceed 300 character.</label>
                            {errors.comment && (
                                <span className="error">
                                    {errors.comment.message}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="form-check">
                        <input
                            type="checkbox"
                            name="selectCheckbox"
                            id="selectCheckbox"
                              {...register("privacyPolicy",{onChange:privacyPolicyHandler})}
                              className={`form-check-input ${
                                errors.privacyPolicy ? "is-invalid" : ""
                              }`}
                            // onChange={privacyPolicyHandler}
                            // className='form-check-input'
                        />
                        <label htmlFor="privacyPolicy" className="form-check-label">
                            Please accept our{" "}
                            <Link
                                target="_blank"
                                to="/terms-and-conditions"
                                className="term-conditions"
                            >
                                terms and conditions
                            </Link>
                        </label>
                      
                       {errors.privacyPolicy && <div className="invalid-feedback">
                        {errors.privacyPolicy?.message}
                        </div>}
                    </div>

                </div>





                <div className="btn-wrap">

                    <input className="btn" disabled={submitDisable} type="submit" />
                </div>
            </form>
        </div>
    )
}

export default LabForm;

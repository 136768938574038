import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import { userEditAddress } from "store/action/userEditaddressAction";
import { userUpdateAddress } from "store/action/userUpdateaddressAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import LoaderWithText from "components/Loader/LoaderWithText";
import OutsideClickHandler from "components/OutsideClickHandler";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../Category/constants/constants";

const schema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(40, "Address must be at most 20 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup.string(),
  cityId: yup.string(),
  stateId: yup.string(),
  countryId: yup.string(),
});

const EditAddress = () => {
  const searchBoxRef = useRef();
  const autoCompleteRef = useRef(null);

  let params = useParams();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const fromEditAddress = location.state ? location.state.from : null;
  console.log("from:222222223333", fromEditAddress);

  let getAddressDetails = useSelector((store) => store.userEditAddress);
  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const getCity = useSelector((state) => state.cityData);

  const [addrType, setAddrType] = useState("Home Address");
  const [countryname, setCountryname] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [Addr1, setAddr1] = useState("");
  const [Addr2, setAddr2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [isSkeleton, setIsSkeleton] = useState(false);

  const [errorsZip, setErrorsZip] = useState(false);
  const [errorsAddr1, seterrorsAddr1] = useState(false);
  const [errorsCountry, setErrorsCountry] = useState(false);
  const [errorsState, setErrorsState] = useState(false);
  const [errorsCity, setErrorsCity] = useState(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [searchLocation, setSearchLocation] = useState();
  const [showSearchedItems, setShowSearchedItems] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const locationDrop = useSelector((state) => state.location);

  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    var payload = {
      addressId: params.adid,
    };
    userEditAddress(payload, (callback) => {
      setIsSkeleton(true);
    })(dispatch);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const changeAddressType = (e, addr) => {
    setAddrType(addr);
  };

  const stateHandler = (e) => {
    setCountryname(e.target.value);
    stateList({ countryId: e.target.value })(dispatch);
  };

  useEffect(() => {
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
  }, []);

  const cityHandler = (e) => {
    setStateName(e.target.value);
    cityList({ countryId: "61c2fb83dc7c0d455ba5e68d", stateId: e.target.value })(
      dispatch
    );
  };

  const changeCity = (e) => {
    setCityName(e.target.value);
    setErrorsCity(false);
  };

  useEffect(() => {
    if (stateName) {
      cityList({ countryId: "61c2fb83dc7c0d455ba5e68d", stateId: stateName })(
        dispatch
      );
    }
  }, [stateName]);

  const updateAdress = () => {
    if (
      Addr1 === "" &&
      stateName === "" &&
      countryname === "" &&
      postalCode === "" &&
      cityName === ""
    ) {
      seterrorsAddr1(true);
      setErrorsZip(true);
      setErrorsCountry(true);
      setErrorsState(true);
      setErrorsCity(true);
    } else if (Addr1 === "") {
      seterrorsAddr1(true);
    } else if (stateName === "") {
      setErrorsState(true);
    } else if (countryname === "") {
      setErrorsCountry(true);
    } else if (postalCode === "") {
      setErrorsZip(true);
    } else if (cityName === "") {
      setErrorsCity(true);
    } else {
      seterrorsAddr1(false);
      setErrorsZip(false);
      setErrorsCountry(false);
      setErrorsState(false);
      setErrorsCity(false);

      var payload = {
        addressId: params.adid,
        addressLine1: Addr1,
        addressLine2: Addr2,
        cityId: cityName,
        stateId: stateName,
        countryId: "61c2fb83dc7c0d455ba5e68d",
        postcode: postalCode,
        addressType: addrType,
        lat: selectedLocation?.lat,
        long: selectedLocation?.lng,
        googleLocation: searchVal,
      };
      userUpdateAddress(payload, navigate, (callback) => {
        setIsFormSubmitting(true);
        // setTimeout(() => setIsFormSubmitting(false), 3000);
        setTimeout(()=>{
          setIsFormSubmitting(false)
          if (fromEditAddress === "/user/checkout") {
            navigate("/user/checkout");
          } else {
            navigate("/user/my-addresses/");
          }
        },3000)
      })(dispatch);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAddr1(getAddressDetails?.data?.addressLine1);
      setAddr2(getAddressDetails?.data?.addressLine2);
      setCountryname(getAddressDetails?.data?.countryId);
      setStateName(getAddressDetails?.data?.stateId);
      setCityName(getAddressDetails?.data?.cityId);
      setPostalCode(getAddressDetails?.data?.postcode);
      const lat = getAddressDetails?.data?.coordinates?.coordinates?.[0];
      const lng = getAddressDetails?.data?.coordinates?.coordinates?.[1];
      setSelectedLocation({ lat, lng });
      setSearchVal(getAddressDetails?.data?.googleLocation);

      if (getAddressDetails?.data?.countryId !== undefined) {
        countryList()(dispatch);
        stateList({ countryId: getAddressDetails?.data?.countryId })(dispatch);
        cityList({
          countryId: getAddressDetails?.data?.countryId,
          stateId: getAddressDetails?.data?.stateId,
        })(dispatch);
      } else {
      }
    }, 2000);
  }, [getAddressDetails]);

  const cancelClick = () => {
    if (fromEditAddress === "/user/checkout") {
      navigate("/user/checkout");
    } else {
      navigate("/user/my-addresses/");
    }
  };

  const submitHandler = () => {
    if (!isFormSubmitting) {
      updateAdress();
      // if (fromEditAddress === "/user/checkout") {
      //   navigate("/user/checkout");
      // } else {
      //   navigate("/user/my-addresses/");
      // }
    }
  };

  return (
    <section className="my-addresses add">
      <ReactPlaceholder
        type="text"
        rows={77}
        ready={isSkeleton}
        showLoadingAnimation={true}
        customPlaceholder={<LoaderWithText />}
      >
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item href="/user/dashboard">User Account</Breadcrumb.Item>
            <Breadcrumb.Item href="/user/my-addresses/">My Addresses</Breadcrumb.Item>
            <Breadcrumb.Item active>Edit Addresses</Breadcrumb.Item>
          </Breadcrumb>
          <h1>Edit Address</h1>
          <div className="address-block">
            <div className="card address-card inputs-wrapper">
              <div className="row mb-3">
                <div className="col-12">
                  <Form.Check
                    inline
                    label="Home Address"
                    name="group1"
                    type="radio"
                    className="radio-btn"
                    id="credit-input"
                    defaultChecked={
                      getAddressDetails?.data?.addressType === "Home Address"
                        ? true
                        : false
                    }
                    onChange={(e) => changeAddressType(e, "Home Address")}
                  />
                  <Form.Check
                    inline
                    label="Office Address"
                    name="group1"
                    type="radio"
                    className="radio-btn"
                    id="credit-input2"
                    defaultChecked={
                      getAddressDetails?.data?.addressType === "Office Address"
                        ? true
                        : false
                    }
                    onChange={(e) => changeAddressType(e, "Office Address")}
                  />
                  <Form.Check
                    inline
                    label="Other"
                    name="group1"
                    type="radio"
                    className="radio-btn"
                    id="credit-input3"
                    defaultChecked={
                      getAddressDetails?.data?.addressType === "Others"
                        ? true
                        : false
                    }
                    onChange={(e) => changeAddressType(e, "Other")}
                  />
                </div>
              </div>
              <div className="row input-block">
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">Address Line 1</label>
                  <div
                    className={`input-wrap ${errorsAddr1 ? "has-error" : ""}`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setAddr1(e.target.value)}
                      placeholder="Address"
                      value={Addr1}
                      maxLength={40}
                    />
                  </div>
                  {errorsAddr1 && (
                    <span className="error">Please enter address</span>
                  )}
                </div>
                <div className="col-md-6 input-wrapper">
                  <label className="input-label">Address Line 2</label>
                  <div
                    className={`input-wrap ${
                      errors.addressLine2 ? "has-error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setAddr2(e.target.value)}
                      placeholder="Address"
                      value={Addr2}
                      maxLength={40}
                    />
                  </div>
                  {errors.addressLine2 && (
                    <span className="error">{errors.addressLine2.message}</span>
                  )}
                </div>

                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">Country</label>
                  <div
                    className={`input-wrap ${
                      errors.countryId ? "has-error" : ""
                    }`}
                  >
                    <select className="form-control" id="countryId" disabled>
                      <option value={""}>India</option>
                    </select>
                  </div>
                </div>
                
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">State</label>
                  <select
                    className="form-control drop-down"
                    onChange={(e) => cityHandler(e)}
                    value={stateName}
                    style={{
                      border: errorsState === true ? "1px solid #F46E66" : "",
                    }}
                  >
                    <option value="" disabled selected>
                      Select state
                    </option>
                    {getState?.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errorsState && (
                    <span className="error">Please enter state</span>
                  )}
                </div>

                <div className="col-md-6 input-wrapper">
                  <label className="input-label">City</label>
                  <select
                    className="form-control drop-down"
                    onChange={(e) => changeCity(e)}
                    style={{
                      border: errorsCity === true ? "1px solid #F46E66" : "",
                    }}
                    value={cityName}
                  >
                    <option value="">Select city</option>
                    {getCity?.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errorsCity && (
                  <span className="error">Please enter City</span>
                )}
                </div>
                <div className="col-md-6 input-wrapper required">
                  <label className="input-label">Zip/Postal Code</label>
                  <div className={`input-wrap ${errorsZip ? "has-error" : ""}`}>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="Zip"
                      value={postalCode}
                    />
                  </div>
                  {errorsZip && (
                    <span className="error">Please enter postal code</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="btns-wrap">
            <button
              className="secondary-btn"
              type="button"
              onClick={cancelClick}
            >
              Cancel
            </button>
            <button
              className="btn"
              onClick={submitHandler}
              disabled={isFormSubmitting ? true : false}
            >
              Submit
            </button>
          </div>
        </div>
      </ReactPlaceholder>
    </section>
  );
};

export default EditAddress;

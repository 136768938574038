import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { advertiserBannerAdd } from 'store/action/advertiserBannerAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Alert } from 'components/Alert/Alert';
import { bannerListing } from 'store/action/bannerListAction';
import { getBannerDetail, editBanner } from 'store/action/bannerDetailAction';

const schema = yup.object().shape({
	// title: yup
	// 	.string(),
	// .required("Please enter title"),
});

const AddBanner = (props, i) => {


	const navigate = useNavigate();
	const dispatch = useDispatch()
	let attachmentName = useRef(null);
	const [img, setImg] = useState("");
	const [fileAttach, setFileAttach] = useState(null)
	const [isImg, setIsImg] = useState(false)


	const bannerList = useSelector(state => state.bannerShow)
	const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (props.bannerId) {
			getBannerDetail({ bannerId: props.bannerId }, callback => {
				// setTimeout(() => {
				setValue("title", callback.title)
				setImg(callback.path + callback.webImage)
				// }, 1000)
			})(dispatch)
		}
	}, [])

	const imgHandler = (e) => {
		setFileAttach(e.target.files[0])
		let src_ = URL.createObjectURL(e.target.files[0]);
		setImg(src_)
		setIsImg(false)
	}

	const onSubmit = (data) => {
		if (img !== "") {
			if (props.bannerId) {
				let payload = new FormData();
				payload.append('webImage', fileAttach);
				payload.append('bannerId', props.bannerId);
				// data.bannerId = props.bannerId
				editBanner(payload)(dispatch);
			}
			else {
				let payload = new FormData();
				payload.append('title', data.title);
				payload.append('webImage', fileAttach);
				advertiserBannerAdd(payload)(dispatch)
			}
			// return
			setTimeout(
				() => (window.location.href = "/user/advertiser-dashboard"),
				// () => (navigate("user/advertiser-dashboard")),
				4000
			);
		} else {
			setIsImg(true)
		}
	};

	const changeHandler = () => {
		props.setShowModal(false)
		// navigate({ pathname: '/user/advertiser-dashboard' })
	}

	return (
		<div className="modal-backdrop">
			{/* <Alert /> */}
			<div className="add-banner">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="inputs-wrapper">
						{/* <div className="input-wrapper required">
							<label className="input-label">Title</label>
							<div className={`input-wrap ${errors.title ? 'has-error' : ''}`}>
								<input
									type="text"
									className="form-control"
									{...register("title")}
								/>
							</div>
							{errors.title && (
								<span className="error">
									Please enter title
								</span>
							)}
						</div> */}
						<div className="input-block mb-0">
							<div className="input-wrapper required img-input-fix has-input-file">
								<label className="">Banner Image</label>
								<div className="row input-block">
									<div className="col-12 input-wrapper">
										<div className="img-input-wrapper d-block">
											{/* <div className="img-input-wrapper"> */}
											<div className="img-input w-100">
												<i className="icon-plus"></i>
												Upload
												<input
													{...register("webImage")}
													type="file"
													accept="image/*"
													ref={attachmentName}
													onChange={imgHandler}
												/>
											</div>
											<span className="img-info d-block w-100">Upload banner image (jpeg, png).</span>
										</div>
										{isImg &&
											<p className="error" id={`image_error` + i}>Please upload banner image</p>
										}
										{
											img !== "" &&
											<div>
												<div className="attachment-name image">
													<img src={img} />
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btn-wrap">
						<button className="secondary-btn" onClick={changeHandler} type='button'
						//  onClick={props.onCloseModal} 
						 >Cancel</button>
						<input className="btn" type="submit" value="save" />
					</div>
				</form>
			</div>
		</div>
	)
};

export default AddBanner;

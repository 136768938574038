import React from 'react';
import { Link } from 'react-router-dom';
import pageNotFound from 'assets/images/404.svg'

const PageNotFound = () => {
	return (
		<section className='page-not-found'>
			<div className="container">
				<div className="right-block">
					<div className="img-wrap">
						<img src={pageNotFound} alt="404" />
					</div>
				</div>
				<div className="left-block">
					<h1>Ooops...</h1>
					<h2>page not found</h2>
					<p>page is not found please go back</p>
					<div className="btn-wrap">
						<Link to='/' className='btn'>Please Go Back</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default PageNotFound

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import { userAddAddress } from "store/action/userAddaddressAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../Category/constants/constants";
import OutsideClickHandler from "components/OutsideClickHandler";

const schema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(40, "Address must be at most 20 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup
    .string()
    .min(2, "Address must be at least 2 characters")
    .max(40, "Address must be at most 20 characters"),
  cityId: yup.string(),
  stateId: yup.string(),
  countryId: yup.string(),
});

const AddAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state ? location.state.from : null;
  console.log("from:22222222", from);

  const searchBoxRef = useRef();
  const autoCompleteRef = useRef(null);

  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const getCity = useSelector((state) => state.cityData);

  const [addrType, setAddrType] = useState("Home Address");
  const [countryname, setCountryname] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [Addr1, setAddr1] = useState("");
  const [Addr2, setAddr2] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [errorsZip, setErrorsZip] = useState(false);
  const [errorZipLength, setErrorZipLength] = useState(false);
  const [errorsAddr1, seterrorsAddr1] = useState(false);
  const [errorsCountry, setErrorsCountry] = useState(false);
  const [errorsState, setErrorsState] = useState(false);
  const [errorsCity, setErrorsCity] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [postalData, setPostalData] = useState();

  const [searchLocation, setSearchLocation] = useState();
  const [showSearchedItems, setShowSearchedItems] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const locationDrop = useSelector((state) => state.location);

  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  console.log("sandeep1111222", selectedLocation, searchVal);

  let updateAddress = useSelector((store) => store.userAddAddress);
  useEffect(() => {
    countryList()(dispatch);
  }, [dispatch]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const changeAddressType = (e, addr) => {
    setAddrType(addr);
    setCountryname("");
    setStateName("");
    setCityName("");
    setAddr1("");
    setAddr2("");
    setPostalCode("");
    seterrorsAddr1(false);
    setErrorsZip(false);
    setErrorsCountry(false);
    setErrorsState(false);
    setErrorsCity(false);
  };

  const stateHandler = (e) => {
    setCountryname(e.target.value);
    stateList({ countryId: e.target.value })(dispatch);
    setErrorsCountry(false);
  };

  const cityHandler = (e) => {
    setStateName(e.target.value);
    cityList({
      countryId: "61c2fb83dc7c0d455ba5e68d",
      stateId: e.target.value,
    })(dispatch);
    setErrorsState(false);
  };

  const changeCity = (e) => {
    setCityName(e.target.value);
    setErrorsCity(false);
  };

  const addAdress = () => {
    if (postalData.length < 6) {
      setErrorZipLength(true);
      return;
    }

    if (
      Addr1 === "" &&
      stateName === "" &&
      countryname === "" &&
      postalCode === "" &&
      cityName === ""
    ) {
      seterrorsAddr1(true);
      setErrorsZip(true);
      setErrorsCountry(true);
      setErrorsState(true);
      setErrorsCity(true);
    } else if (Addr1 === "") {
      seterrorsAddr1(true);
    } else if (stateName === "") {
      setErrorsState(true);
    } else if (cityName === "") {
      setErrorsCity(true);
    } else if (postalCode === "") {
      setErrorsZip(true);
    } else {
      seterrorsAddr1(false);
      setErrorsZip(false);
      setErrorsCountry(false);
      setErrorsState(false);
      setErrorsCity(false);

      var payload = {
        addressLine1: Addr1,
        addressLine2: Addr2,
        cityId: cityName,
        stateId: stateName,
        countryId: "61c2fb83dc7c0d455ba5e68d",
        postcode: postalCode,
        addressType: addrType,
        lat: selectedLocation ? selectedLocation?.lat : null,
        long: selectedLocation ? selectedLocation?.lng : null,
        googleLocation: searchVal,
      };

      userAddAddress(payload, navigate, () => {
        setIsFormSubmitting(true);
        // setTimeout(() => {
        setIsFormSubmitting(false);
        if (from === "/user/checkout") {
          navigate("/user/checkout");
        } else {
          navigate("/user/my-addresses");
        }
        // }, 10);
      })(dispatch);
    }
  };

  const cancelClick = () => {
    navigate({ pathname: "/user/my-addresses/" });
  };

  const click = () => {
    navigate("/user/dashboard");
  };

  const submitHandler = () => {
    if (!isFormSubmitting) {
      setIsFormSubmitting(true);
      addAdress();
    }
  };

  useEffect(() => {
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
  }, [dispatch]);

  const zipCodeHandler = (e) => {
    let inputValue = e.target.value;

    if (inputValue.length === 6) {
      setErrorZipLength(false);
    }
    inputValue = inputValue.replace(/[^0-9]/g, "");
    const truncatedValue = inputValue.slice(0, 6);
    setPostalData(truncatedValue);
    setPostalCode(truncatedValue);
    setErrorsZip(false);
    e.target.value = truncatedValue;
  };

  return (
    <section className="my-addresses add">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item onClick={click}>User Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={cancelClick}>My Addresses</Breadcrumb.Item>
          <Breadcrumb.Item active>Add Addresses</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Add Address</h1>
        <div className="address-block">
          <div className="card address-card inputs-wrapper">
            <div className="row mb-3">
              <div className="col-12">
                <Form.Check
                  inline
                  label="Home Address"
                  name="group1"
                  type="radio"
                  className="radio-btn"
                  id="credit-input"
                  defaultChecked="true"
                  onChange={(e) => changeAddressType(e, "Home Address")}
                />
                <Form.Check
                  inline
                  label="Office Address"
                  name="group1"
                  type="radio"
                  className="radio-btn"
                  id="credit-input2"
                  onChange={(e) => changeAddressType(e, "Office Address")}
                />
                <Form.Check
                  inline
                  label="Other"
                  name="group1"
                  type="radio"
                  className="radio-btn"
                  id="credit-input3"
                  onChange={(e) => changeAddressType(e, "Others")}
                />
              </div>
            </div>
            <div className="row input-block">
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">Address Line 1</label>
                <div className={`input-wrap ${errorsAddr1 ? "has-error" : ""}`}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      seterrorsAddr1(false);
                      setAddr1(e.target.value);
                    }}
                    placeholder="Address"
                    maxLength={40}
                    value={Addr1}
                  />
                </div>
                {errorsAddr1 && (
                  <span className="error">Please enter address</span>
                )}
              </div>
              <div className="col-md-6 input-wrapper">
                <label className="input-label">Address Line 2</label>
                <div
                  className={`input-wrap ${
                    errors.addressLine2 ? "has-error" : ""
                  }`}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setAddr2(e.target.value)}
                    placeholder="Address"
                    maxLength={40}
                    value={Addr2}
                  />
                </div>
                {errors.addressLine2 && (
                  <span className="error">{errors.addressLine2.message}</span>
                )}
              </div>
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">Country</label>
                <div
                  className={`input-wrap ${
                    errors.countryId ? "has-error" : ""
                  }`}
                >
                  <select className="form-control" id="countryId" disabled>
                    <option value={""}>India</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">State</label>
                <select
                  className="form-control drop-down"
                  onChange={(e) => cityHandler(e)}
                  style={{
                    border: errorsState === true ? "1px solid #F46E66" : "",
                  }}
                  value={stateName}
                >
                  <option value="" disabled selected>
                    Select state
                  </option>
                  {getState?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errorsState && (
                  <span className="error">Please enter state</span>
                )}
              </div>
              <div className="col-md-6 input-wrapper">
                <label className="input-label">City</label>
                <select
                  className="form-control drop-down"
                  onChange={(e) => changeCity(e)}
                  style={{
                    border: errorsCity === true ? "1px solid #F46E66" : "",
                  }}
                  value={cityName}
                >
                  <option value="">Select city</option>
                  {getCity?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errorsCity && <span className="error">Please enter City</span>}
              </div>
              <div className="col-md-6 input-wrapper required">
                <label className="input-label">Zip/Postal Code</label>
                <div className={`input-wrap ${errorsZip ? "has-error" : ""}`}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={zipCodeHandler}
                    placeholder="Zip"
                    value={postalCode}
                  />
                </div>
                {errorsZip && (
                  <span className="error">Please enter postal code</span>
                )}
                {errorZipLength && (
                  <span className="error">
                    Please enter a 6-digit zip code
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="btns-wrap">
          <button className="secondary-btn" type="button" onClick={cancelClick}>
            Cancel
          </button>
          <button
            className="btn"
            disabled={isFormSubmitting}
            onClick={submitHandler}
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  );
};

export default AddAddress;

import * as types from '../actionType'

const initialState = []

const userAddAddress = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_ADD_ADDRESS:
            return action.payload
        default:
            return state
    }
}


export { userAddAddress }
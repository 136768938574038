import * as types from '../actionType'

const initialState = []

const users = (state = initialState, action) => {
    switch (action.type) {
        case types.BECOME_SELLER:
            return state
        default:
            return state
    }
}

export default users;
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { sellerFormAction } from 'store/action/becomeSellerFromAction';
import { useDispatch, useSelector } from 'react-redux'
import { countryList } from 'store/action/countryList';
import { stateList } from 'store/action/stateList';
import { cityList } from 'store/action/cityListAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const schema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter First Name")
        .min(2, "First name must be at least 2 characters")
        .max(20, "First name must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
    lastName: yup
        .string()
        .required("Please enter Last Name")
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
    email: yup
        .string()
        .required("Please enter your email address")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Please use the correct email"
        ),
    businessName: yup
        .string()
        .required("Please enter Business Name")
        .min(2, "Business name must be at least 2 characters")
        .max(20, "Business name must be at most 20 characters"),
    // .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Please enter valid Business name"),
    primaryContactPerson: yup
        .string()
        .required("Please enter Primary Contact Person")
        .min(2, "Primary contact person must be at least 2 characters")
        .max(20, "Primary contact person must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid primary contact person"),
    companyRegistrationNumber: yup
        .string()
        .required("Please enter Company Register Number")
        .matches(/^[0-9a-zA-Z]+$/, "Company Register Number is not valid"),
    vat: yup
        .string(),
    addressLine1: yup
        .string()
        .required("Please enter Address")
        .min(2, "Address must be at least 2 characters")
        .max(60, "Address must be at most 20 characters")
        .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
    addressLine2: yup
        .string(),
    cityId: yup
        .string(),
    stateId: yup
        .string()
        .required("Please enter state"),
    countryId: yup
        .string()
        .required("Please enter country"),
    phone: yup
        .string()
        .required("Please enter mobile number")
        // .matches(/^[0-9]*$/, "Phone number is not valid")
        .min(7)
        .max(14),
    postcode: yup
        .string()
        .required("Please enter Zip")
        .matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid")
        .min(3),
    // .max(6),
    comment: yup
        .string()
        .required("Please enter Comment")
        // .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Comment is not valid")
        .min(2)
        .max(300),
});

const Company = () => {
    const navigate = useNavigate();
    const getCountry = useSelector(state => state.countryData)
    const getState = useSelector(state => state.stateData)
    const getCity = useSelector(state => state.cityData)
    const [passwordType, setPasswordType] = useState("password");
    const [con_passwordType, setCon_passwordType] = useState("password");
    const [country, setCountry] = useState("");
    const login = useSelector(state => state.login)
    const [region, setRegion] = useState("");

    const [statename, setStateName] = useState("");
    const [countryname, setCountryname] = useState("");


    const dispatch = useDispatch()
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        countryList()(dispatch);
    }, []);


    const onSubmit = (data, e) => {
        data.type = "Company"
        sellerFormAction(data, callback => {
            setTimeout(() => navigate("/become-seller/success"), 1000)
        })(dispatch)
    };

    const getStateHandler = (e) => {
        let val = e.target.value;
        setCountryname(val)
        stateList({ countryId: val })(dispatch);
    }

    const getCityHandler = (e) => {
        cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
    }


    return (
        <div className='company-tab'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card inputs-wrapper">
                    {/* <h4>Verification</h4> */}
                    <div className="row input-block">
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required required">first name</label>
                            <div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("firstName")}
                                    placeholder='FirstName'
                                />
                            </div>
                            {errors.firstName && (
                                <span className="error">
                                    {errors.firstName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Last name</label>
                            <div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("lastName")}
                                    className="form-control"
                                    placeholder='LastName'
                                />
                            </div>
                            {errors.lastName && (
                                <span className="error">
                                    {errors.lastName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Email address</label>
                            <div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
                                <input
                                    type="email"
                                    {...register("email")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='example@aladyyn.pro'
                                />
                            </div>
                            {errors.email && (
                                <span className="error">
                                    {errors.email.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="inputs-heading">Business Information</div>
                    <div className="row input-block margin-fix">
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Business Name</label>
                            <div className={`input-wrap ${errors.businessName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("businessName")}
                                    placeholder='Business Name'
                                />
                            </div>
                            {errors.businessName && (
                                <span className="error">
                                    {errors.businessName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Primary Contact Person</label>
                            <div className={`input-wrap ${errors.primaryContactPerson ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("primaryContactPerson")}
                                    className="form-control"
                                    placeholder='Primary Person'
                                />
                            </div>
                            {errors.primaryContactPerson && (
                                <span className="error">
                                    {errors.primaryContactPerson.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label required">Company Registration Number</label>
                            <div className={`input-wrap ${errors.companyRegistrationNumber ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("companyRegistrationNumber")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Company Registration Number'
                                />
                            </div>
                            {errors.companyRegistrationNumber && (
                                <span className="error">
                                    {errors.companyRegistrationNumber.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper">
                            <label className="input-label">VAT Number <span className='text-lowercase'>(if applicable)</span></label>
                            <div className={`input-wrap ${errors.vat ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("vat")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Vat number'
                                />
                            </div>
                            {errors.vat && (
                                <span className="error">
                                    {errors.vat.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="input-wrapper required">
                                <label className="input-label required">Phone Number</label>
                                <div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
                                    <input
                                        type="number"
                                        {...register("phone")}
                                        className="form-control"
                                        placeholder='Mobile'
                                    />
                                </div>
                                {errors.phone && (
                                    <span className="error">
                                        {errors.phone.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="inputs-heading">Registered Business Address</div>
                    <div className="row input-block">
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label required">address line 1</label>
                            <div className={`input-wrap ${errors.addressLine1 ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("addressLine1")}
                                    placeholder='Address'
                                />
                                <span className="input-info">Building number and Street</span>
                            </div>
                            {errors.addressLine1 && (
                                <span className="error">
                                    {errors.addressLine1.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper">
                            <label className="input-label">address line 2</label>
                            <div className={`input-wrap ${errors.addressLine2 ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("addressLine2")}
                                    className="form-control"
                                    placeholder='Address'
                                />
                                <span className="input-info">Room/Block/Apartments</span>
                            </div>
                            {errors.addressLine2 && (
                                <span className="error">
                                    {errors.addressLine2.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">Country</label>
                            <div className={`input-wrap ${errors.countryId ? 'has-error' : ''}`}>
                                <select
                                    {...register("countryId")}
                                    name="countryId"
                                    className="form-control"
                                    id="countryId"
                                    onBlur={getStateHandler}
                                >
                                    <option value={""}>Please select country</option>
                                    {
                                        getCountry?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            {errors.countryId && (
                                <span className="error">
                                    {errors.countryId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper">
                            <label className="input-label required">State / Region</label>
                            <div className={`input-wrap ${errors.stateId ? 'has-error' : ''}`}>
                                <select className="form-control" {...register("stateId")} name="stateId" onClick={getCityHandler}>
                                    <option value={""}>Please select state</option>
                                    {
                                        getState?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.stateId && (
                                <span className="error">
                                    {errors.stateId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">City</label>
                            <div className="input-wrap">
                                <select className="form-control" {...register("cityId")} name="cityId">
                                    <option value={""}>Please select city</option>
                                    {
                                        getCity?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.cityId && (
                                <span className="error">
                                    {errors.cityId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label required">ZIP / Postal Code</label>
                            <div className={`input-wrap ${errors.postcode ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("postcode")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Zip Code'
                                />
                            </div>
                            {errors.postcode && (
                                <span className="error">
                                    {errors.postcode.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="row input-block">
                        <div className="col-12 input-wrapper required mb-0">
                            <label className="input-label required">Comment</label>
                            <div className={`input-wrap ${errors.comment ? 'has-error' : ''}`}>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    {...register("comment")}
                                    placeholder='Tell us more about the services you provide'
                                    // placeholder='Please enter your comment'
                                />
                            </div>
                            <label className="input-label required input-info position-static">Comment should not exceed 300 character.</label>
                            {errors.comment && (
                                <span className="error">
                                    {errors.comment.message}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="btn-wrap">
                    <input className="btn" type="submit" />
                </div>
            </form>
        </div>
    )
}

export default Company;

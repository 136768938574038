import * as types from '../actionType'

const initialState = []

export const billingView = (state = initialState, action) => {
    switch (action.type) {
        case types.BILLING_DATA:
            return action.payload
        default:
            return state
    }
}


// const profileEdit = (state = initialState, action) => {
//     switch (action.type) {
//         case types.EDIT_PROFILE:
//             return action.payload
//         default:
//             return state
//     }
// }

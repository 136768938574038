import * as types from '../actionType'

const initialState = []

const sellerRaiseInvoice = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_POST_RAISE_INVOICE:
            return action.payload
        default:
            return state
    }
}


export { sellerRaiseInvoice }
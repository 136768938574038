import * as types from '../actionType'

const initialState = []

const sellerServiceAdd = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_SERVICE_ADD:
            return action.payload
        default:
            return state
    }
}
const sellerServiceCategory = (state = initialState, action) => {
    switch (action.type) {
        case types.SELLER_CATEGORY_GET:
            return action.payload
        default:
            return state
    }
}
const sellerServiceSubCategory = (state = [], action) => {
    switch (action.type) {
        case types.SELLER_GET_SUBCATEGORY:
            return action.payload
        default:
            return state
    }
}
const sellerUploadMedia = (state = [], action) => {
    switch (action.type) {
        case types.SELLER_UPLOAD_MEDIA:
            return action.payload
        default:
            return state
    }
}


export { sellerServiceAdd ,sellerServiceCategory,sellerServiceSubCategory,sellerUploadMedia};
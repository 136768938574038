import * as types from '../actionType'

const initialState = []

const sellerBillingMethod = (state = initialState, action) => {
    switch (action.type) {
        case types.METHOD_BILLING:
            return action.payload
        default:
            return state
    }
}




export { sellerBillingMethod };